/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubAccessCodeList } from '../models/tub-access-code-list';
import { TubAccessCode } from '../models/tub-access-code';
import { TubCreateAccessCodeParams } from '../models/tub-create-access-code-params';
import { TubUpdateAccessCodeParams } from '../models/tub-update-access-code-params';
@Injectable({
  providedIn: 'root',
})
class AccessCodeManagementService extends __BaseService {
  static readonly AccessCodeManagementListPath = '/v2/access-codes';
  static readonly AccessCodeManagementCreatePath = '/v2/access-codes';
  static readonly AccessCodeManagementGetPath = '/v2/access-codes/{accessCode}';
  static readonly AccessCodeManagementUpdatePath = '/v2/access-codes/{accessCode}';
  static readonly AccessCodeManagementDeletePath = '/v2/access-codes/{accessCode}';
  static readonly AccessCodeManagementEnablePath = '/v2/access-codes/{accessCode}/action/enable';
  static readonly AccessCodeManagementDisablePath = '/v2/access-codes/{accessCode}/action/disable';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get all access codes for an organisation.
   *
   * Retrieves a paginated list of all access codes registered on the specified organisation. Note that this does not include access codes
   * registered on any child organisations, only the specified organisation.
   *
   * The result will include the current details for each access code, along with the ETags for each, which is a required field when
   * updating the access code in order to proactively prevent conflicting updates causing data loss as part of an optimistic concurrency
   * control strategy.
   *
   * The result will also contain a paging cursor, which can be used to retrieve the next page of results. Note that a paging cursor
   * will always be provided, even if there are no more results to retrieve.
   * @param params The `AccessCodeManagementService.AccessCodeManagementListParams` containing the following parameters:
   *
   * - `pageSize`: The maximum number of access codes to return for this page of results.
   *
   * - `organisationID`: The organisationID by which to filter access codes
   *
   * - `pagingCursor`: The cursor to use to retrieve the next page of results, if required.  Provided in a previous response.
   *
   * @return Ok
   */
  AccessCodeManagementListResponse(params: AccessCodeManagementService.AccessCodeManagementListParams): __Observable<__StrictHttpResponse<TubAccessCodeList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.organisationID != null) __params = __params.set('organisationID', params.organisationID.toString());
    if (params.pagingCursor != null) __params = __params.set('pagingCursor', params.pagingCursor.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/v2/access-codes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubAccessCodeList>;
      })
    );
  }
  /**
   * Get all access codes for an organisation.
   *
   * Retrieves a paginated list of all access codes registered on the specified organisation. Note that this does not include access codes
   * registered on any child organisations, only the specified organisation.
   *
   * The result will include the current details for each access code, along with the ETags for each, which is a required field when
   * updating the access code in order to proactively prevent conflicting updates causing data loss as part of an optimistic concurrency
   * control strategy.
   *
   * The result will also contain a paging cursor, which can be used to retrieve the next page of results. Note that a paging cursor
   * will always be provided, even if there are no more results to retrieve.
   * @param params The `AccessCodeManagementService.AccessCodeManagementListParams` containing the following parameters:
   *
   * - `pageSize`: The maximum number of access codes to return for this page of results.
   *
   * - `organisationID`: The organisationID by which to filter access codes
   *
   * - `pagingCursor`: The cursor to use to retrieve the next page of results, if required.  Provided in a previous response.
   *
   * @return Ok
   */
  AccessCodeManagementList(params: AccessCodeManagementService.AccessCodeManagementListParams): __Observable<TubAccessCodeList> {
    return this.AccessCodeManagementListResponse(params).pipe(
      __map(_r => _r.body as TubAccessCodeList)
    );
  }

  /**
   * Create a new access code.
   *
   * Creates a new access code according to the provided parameters.
   *
   * Newly created access codes are always initially enabled by default.
   * @param body The JSON body containing the parameters for the access code to create.  Please see the Model section for details.
   * @return Ok
   */
  AccessCodeManagementCreateResponse(body: TubCreateAccessCodeParams): __Observable<__StrictHttpResponse<TubAccessCode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/v2/access-codes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubAccessCode>;
      })
    );
  }
  /**
   * Create a new access code.
   *
   * Creates a new access code according to the provided parameters.
   *
   * Newly created access codes are always initially enabled by default.
   * @param body The JSON body containing the parameters for the access code to create.  Please see the Model section for details.
   * @return Ok
   */
  AccessCodeManagementCreate(body: TubCreateAccessCodeParams): __Observable<TubAccessCode> {
    return this.AccessCodeManagementCreateResponse(body).pipe(
      __map(_r => _r.body as TubAccessCode)
    );
  }

  /**
   * Get a specific access code.
   *
   * Retrieve details for the specified access code.
   *
   * The response headers will include an ETag, which is a required field when updating the access code in order to proactively prevent
   * conflicting updates causing data loss as part of an optimistic concurrency control strategy.
   * @param accessCode the access code to retrieve
   * @return Ok
   */
  AccessCodeManagementGetResponse(accessCode: string): __Observable<__StrictHttpResponse<TubAccessCode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/v2/access-codes/${encodeURIComponent(String(accessCode))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubAccessCode>;
      })
    );
  }
  /**
   * Get a specific access code.
   *
   * Retrieve details for the specified access code.
   *
   * The response headers will include an ETag, which is a required field when updating the access code in order to proactively prevent
   * conflicting updates causing data loss as part of an optimistic concurrency control strategy.
   * @param accessCode the access code to retrieve
   * @return Ok
   */
  AccessCodeManagementGet(accessCode: string): __Observable<TubAccessCode> {
    return this.AccessCodeManagementGetResponse(accessCode).pipe(
      __map(_r => _r.body as TubAccessCode)
    );
  }

  /**
   * Update an access code.
   *
   * Updates the specified access code.
   *
   * This method only supports updating the `redemptions.max` field, as the only mutable field. It must be non-zero.
   *
   * To change the `enabled` state of an access code, use the `enable` and `disable` action endpoints.
   * @param params The `AccessCodeManagementService.AccessCodeManagementUpdateParams` containing the following parameters:
   *
   * - `body`: The JSON body containing the parameters for the access code to update.  Please see the Example Value section for details.
   *
   * - `accessCode`: the access code to update
   *
   * - `ETag`: arbitrary string received in the response headers when performing a GET, required to ensure integrity
   */
  AccessCodeManagementUpdateResponse(params: AccessCodeManagementService.AccessCodeManagementUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;

    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/v2/access-codes/${encodeURIComponent(String(params.accessCode))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update an access code.
   *
   * Updates the specified access code.
   *
   * This method only supports updating the `redemptions.max` field, as the only mutable field. It must be non-zero.
   *
   * To change the `enabled` state of an access code, use the `enable` and `disable` action endpoints.
   * @param params The `AccessCodeManagementService.AccessCodeManagementUpdateParams` containing the following parameters:
   *
   * - `body`: The JSON body containing the parameters for the access code to update.  Please see the Example Value section for details.
   *
   * - `accessCode`: the access code to update
   *
   * - `ETag`: arbitrary string received in the response headers when performing a GET, required to ensure integrity
   */
  AccessCodeManagementUpdate(params: AccessCodeManagementService.AccessCodeManagementUpdateParams): __Observable<null> {
    return this.AccessCodeManagementUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Delete an access code.
   *
   * Deletes the specified access code.
   *
   * The ETag header is required to be provided in order to proactively prevent conflicting updates causing data loss as part of an
   * optimistic concurrency control strategy.  It can be obtained as a response header, by performing a GET on the access code first.
   *
   * Note that an access code can only be deleted when it has zero current redemptions.
   * @param params The `AccessCodeManagementService.AccessCodeManagementDeleteParams` containing the following parameters:
   *
   * - `accessCode`: Access code firestore ID.
   *
   * - `ETag`: arbitrary string received alongside the data when performing a get, used to ensure integrity
   */
  AccessCodeManagementDeleteResponse(params: AccessCodeManagementService.AccessCodeManagementDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/v2/access-codes/${encodeURIComponent(String(params.accessCode))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete an access code.
   *
   * Deletes the specified access code.
   *
   * The ETag header is required to be provided in order to proactively prevent conflicting updates causing data loss as part of an
   * optimistic concurrency control strategy.  It can be obtained as a response header, by performing a GET on the access code first.
   *
   * Note that an access code can only be deleted when it has zero current redemptions.
   * @param params The `AccessCodeManagementService.AccessCodeManagementDeleteParams` containing the following parameters:
   *
   * - `accessCode`: Access code firestore ID.
   *
   * - `ETag`: arbitrary string received alongside the data when performing a get, used to ensure integrity
   */
  AccessCodeManagementDelete(params: AccessCodeManagementService.AccessCodeManagementDeleteParams): __Observable<null> {
    return this.AccessCodeManagementDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Enable an access code.
   *
   * Enables the specified access code.
   *
   * The ETag header is required to be provided in order to proactively prevent conflicting updates causing data loss as part of an
   * optimistic concurrency control strategy.  It can be obtained as a response header, by performing a GET on the access code first.
   * @param params The `AccessCodeManagementService.AccessCodeManagementEnableParams` containing the following parameters:
   *
   * - `accessCode`: the access code to enable
   *
   * - `ETag`: arbitrary string received in the response headers when performing a GET, required to ensure integrity
   */
  AccessCodeManagementEnableResponse(params: AccessCodeManagementService.AccessCodeManagementEnableParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/v2/access-codes/${encodeURIComponent(String(params.accessCode))}/action/enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Enable an access code.
   *
   * Enables the specified access code.
   *
   * The ETag header is required to be provided in order to proactively prevent conflicting updates causing data loss as part of an
   * optimistic concurrency control strategy.  It can be obtained as a response header, by performing a GET on the access code first.
   * @param params The `AccessCodeManagementService.AccessCodeManagementEnableParams` containing the following parameters:
   *
   * - `accessCode`: the access code to enable
   *
   * - `ETag`: arbitrary string received in the response headers when performing a GET, required to ensure integrity
   */
  AccessCodeManagementEnable(params: AccessCodeManagementService.AccessCodeManagementEnableParams): __Observable<null> {
    return this.AccessCodeManagementEnableResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Disable an access code.
   *
   * Disables the specified access code.
   *
   * The ETag header is required to be provided in order to proactively prevent conflicting updates causing data loss as part of an
   * optimistic concurrency control strategy.  It can be obtained as a response header, by performing a GET on the access code first.
   * @param params The `AccessCodeManagementService.AccessCodeManagementDisableParams` containing the following parameters:
   *
   * - `accessCode`: the access code to disable
   *
   * - `ETag`: arbitrary string received in the response headers when performing a GET, required to ensure integrity
   */
  AccessCodeManagementDisableResponse(params: AccessCodeManagementService.AccessCodeManagementDisableParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/v2/access-codes/${encodeURIComponent(String(params.accessCode))}/action/disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Disable an access code.
   *
   * Disables the specified access code.
   *
   * The ETag header is required to be provided in order to proactively prevent conflicting updates causing data loss as part of an
   * optimistic concurrency control strategy.  It can be obtained as a response header, by performing a GET on the access code first.
   * @param params The `AccessCodeManagementService.AccessCodeManagementDisableParams` containing the following parameters:
   *
   * - `accessCode`: the access code to disable
   *
   * - `ETag`: arbitrary string received in the response headers when performing a GET, required to ensure integrity
   */
  AccessCodeManagementDisable(params: AccessCodeManagementService.AccessCodeManagementDisableParams): __Observable<null> {
    return this.AccessCodeManagementDisableResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module AccessCodeManagementService {

  /**
   * Parameters for AccessCodeManagementList
   */
  export interface AccessCodeManagementListParams {

    /**
     * The maximum number of access codes to return for this page of results.
     */
    pageSize: number;

    /**
     * The organisationID by which to filter access codes
     */
    organisationID: string;

    /**
     * The cursor to use to retrieve the next page of results, if required.  Provided in a previous response.
     */
    pagingCursor?: string;
  }

  /**
   * Parameters for AccessCodeManagementUpdate
   */
  export interface AccessCodeManagementUpdateParams {

    /**
     * The JSON body containing the parameters for the access code to update.  Please see the Example Value section for details.
     */
    body: TubUpdateAccessCodeParams;

    /**
     * the access code to update
     */
    accessCode: string;

    /**
     * arbitrary string received in the response headers when performing a GET, required to ensure integrity
     */
    ETag: string;
  }

  /**
   * Parameters for AccessCodeManagementDelete
   */
  export interface AccessCodeManagementDeleteParams {

    /**
     * Access code firestore ID.
     */
    accessCode: string;

    /**
     * arbitrary string received alongside the data when performing a get, used to ensure integrity
     */
    ETag: string;
  }

  /**
   * Parameters for AccessCodeManagementEnable
   */
  export interface AccessCodeManagementEnableParams {

    /**
     * the access code to enable
     */
    accessCode: string;

    /**
     * arbitrary string received in the response headers when performing a GET, required to ensure integrity
     */
    ETag: string;
  }

  /**
   * Parameters for AccessCodeManagementDisable
   */
  export interface AccessCodeManagementDisableParams {

    /**
     * the access code to disable
     */
    accessCode: string;

    /**
     * arbitrary string received in the response headers when performing a GET, required to ensure integrity
     */
    ETag: string;
  }
}

export { AccessCodeManagementService }
