import { PropertyAuthorisation } from '@thrivesoft/gocore-web';

export class AuthLogoutActionModel {

  static actionLogout: string = 'ACTION_LOGOUT';

  static toProto(): PropertyAuthorisation {
    return new PropertyAuthorisation({
      message: AuthLogoutActionModel.actionLogout,
    });
  }
}