import { ResponseError } from '@thrivesoft/gocore-web';

export interface GoErrorData {
  commandName: string;
  data: {
    reporter?: string;
    core: string;
    raw: ResponseError | Error;
  }
}
