import { Component } from '@angular/core';

@Component({
  selector: 'app-firebase-reset-password',
  templateUrl: './firebase-reset-password.component.html',
  styleUrls: ['./firebase-reset-password.component.scss']
})
export class FirebaseResetPasswordComponent {

  constructor() { }

}
