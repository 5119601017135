<div>
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
        <span>
            <img id="thrive-logo" src="/assets/images/thrive-logo-with-title.png">
        </span>
    </mat-toolbar>
    <mat-divider></mat-divider>
</div>
<div id="doc-container">
    <div *ngIf="state == legalDocsState.Loading">Loading, please wait</div>
    <div [innerHTML]="legalHtml" *ngIf="state == legalDocsState.Loaded"></div>
    <div *ngIf="state == legalDocsState.Error">{{errorMessage}} </div>
</div>


