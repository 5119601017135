import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import { NotificationState } from '@shared/models/notification-state.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationStateService {

  public notifiedUserRequests: BehaviorSubject<NotificationState[]> = new BehaviorSubject<NotificationState[]>([]);
  public notifiedUserMessages: BehaviorSubject<NotificationState[]> = new BehaviorSubject<NotificationState[]>([]);

  constructor(private angularFirestore: AngularFirestore) {}

  /**
   * Mark the specified Chat Request notification as read.
   * @param notificationId identifies the notification to mark as read
   */
  public async markChatRequestNotificationsAsRead(notificationId: string): Promise<void> {
    try {
      await this.angularFirestore.collection('notifications')
        .doc(notificationId).update({ read: firebase.firestore.FieldValue.serverTimestamp() });
    } catch (err) {
      console.error('Could not mark notification as read', { err, notificationId });
    }
  }

  public async markAllChatRequestNotificationsAsRead(notificationIds: string[]): Promise<void> {
    await Promise.all(notificationIds.map(async notificationId => {
      this.notifiedUserRequests.pipe(map(request => request.filter(n => n.id !== notificationId)));
      await this.markChatRequestNotificationsAsRead(notificationId);
    }));
  }

  /**
   * Mark the specified Message notification as read.
   * @param chatSessionId identifies the message to mark as read
   */
  public markChatMsgNotificationsAsRead(chatSessionId: string): void {
    this.notifiedUserMessages.pipe(take(1)).subscribe(notifications => {
      const chat = notifications.find(c => c.id === chatSessionId);
      if (chat) {
        chat.unread = 0;
        chat.userNotified = false;
        this.notifiedUserMessages.next(notifications);
      }
    });
  }

  public setUserNotified(
    key: string,
    alreadyNotified: NotificationState[]
  ): NotificationState[] {
    alreadyNotified.find((c) => c.id === key).userNotified = true;
    return alreadyNotified;
  }

  public setAllUserNotified(
    alreadyNotified: NotificationState[]
  ): NotificationState[] {
    for (const notification of alreadyNotified) {
      if (notification.unread > 0) {
        notification.userNotified = true;
      }
    }
    return alreadyNotified;
  }
}
