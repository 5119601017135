import { Injectable } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private featureFlags: BehaviorSubject<{ [key: string]: boolean }> = new BehaviorSubject({});
  public featureFlags$: Observable<{ [key: string]: boolean }> = this.featureFlags.asObservable();

  constructor(private remoteConfig: AngularFireRemoteConfig) {}

  public async initialise() {
    try {
      await this.remoteConfig.ensureInitialized();

      const config = await this.remoteConfig.fetchAndActivate().then(() => this.remoteConfig.getAll());
      const featureFlags = this.convertConfigToBooleans(config);

      this.featureFlags.next(featureFlags);

      console.log('[FEATURE FLAGS] Initialised:', featureFlags);
    } catch (error) {
      console.error('[FEATURE FLAGS] Error initialising feature flags:', error);
    }
  }

  public async isGoogleDriveEnabled(): Promise<boolean> {
    return await this.remoteConfig.getBoolean('isGoogleDriveEnabled').then((value) => {
      return value;
    });
  }

  private convertConfigToBooleans(config: { [key: string]: any }): { [key: string]: boolean } {
    const featureFlags: { [key: string]: boolean } = {};

    for (const [key, value] of Object.entries(config)) {
      featureFlags[key] = value.asBoolean();
    }

    return featureFlags;
  }
}
