import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginStepperComponent } from './login-stepper/login-stepper.component';
import { FirebaseActionsStepperComponent } from './firebase-actions-stepper/firebase-actions-stepper.component';
import { LogOutComponent } from './log-out/log-out.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginStepperComponent
  },
  {
    path: 'action',
    component: FirebaseActionsStepperComponent
  },
  {
    path: 'logout',
    component: LogOutComponent
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class AuthenticationRoutingModule {}
