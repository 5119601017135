import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FirebaseActionsService {

  constructor() { }

  // This has to stay in the order defined in the HTML
  private promptHeaders = [
    {
      header: 'Reset Password',
      subHeader: 'Please enter a new password'
    },
    {
      header: 'Recover Email',
      subHeader: 'Your email has been recovered'
    },
    {
      header: 'Verify Email',
      subHeader: 'Your email address has been verified'
    },
    {
      header: 'Success',
      subHeader: 'Password has successfully been reset'
    }
  ];

  public getPromptHeaders(): { header: string, subHeader: string }[] {
    return this.promptHeaders;
  }
}
