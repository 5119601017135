// go-chat-duplication

import firebase from 'firebase/compat';
import firestore = firebase.firestore;

export class GoDashboardNotification {
  public icon: string;
  public link: string;
  public title: string;
  public message: string;
  public timestamp: firestore.Timestamp;
  public readAtTime: firestore.Timestamp;
  public id: string;

  constructor(icon, link, title, message, timestamp, readAtTime, id) {
    this.icon = icon;
    this.link = link;
    this.title = title;
    this.message = message;
    this.timestamp = timestamp;
    this.readAtTime = readAtTime;
    this.id = id;
  }
}
