import { DOCUMENT } from '@angular/common';
import { ElementRef, Inject, Injectable, Renderer2, ViewChild } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private cssElement: HTMLLinkElement;
  private cssFile: string;
  private themeCSSID: string = 'themeCSS';
  
  @ViewChild('themeCSS') themeElement: ElementRef;
  
  constructor(
    @Inject(DOCUMENT) private document: Document
  ) { }

  setTheme(theme: string, renderer: Renderer2) {
    this.cssFile = `${theme}.css`;

    // If theme CSS file already in DOM, attempt to remove it
    this.removeExistingThemeStyle(renderer);
   
    // Create a link element via Angular's renderer to avoid SSR troubles
    this.cssElement = renderer.createElement('link') as HTMLLinkElement;

    // Set type of the link item and path to the css file
    renderer.setProperty(this.cssElement, 'rel', 'stylesheet');
    renderer.setProperty(this.cssElement, 'href', this.cssFile);
    renderer.setProperty(this.cssElement, 'id', this.themeCSSID);
    
    // Add the style to the head section
    renderer.appendChild(this.document.head, this.cssElement);
  }

  removeExistingThemeStyle(renderer: Renderer2) {
    const themeElement = this.themeElement?.nativeElement as HTMLElement;
    if (themeElement) {
      renderer.removeChild(this.document.head, themeElement);
    }
  }
}