import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from '@layout/page-not-found/page-not-found.component';
import { UnauthorisedComponent } from '@layout/unauthorised/unauthorised.component';
import { HomeComponent } from '@layout/home/home.component';
import { LegalDocsComponent } from './modules/legaldocs/legal-docs/legal-docs.component';

const routes: Routes = [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home'
      },
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'admin',
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: 'therapist',
        loadChildren: () => import('./modules/therapist/therapist.module').then(m => m.TherapistModule)
      },
      {
        path: 'authentication',
        loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)
      },
      {
        path: 'transactional',
        loadChildren: () => import('./modules/transactional/transactional.module').then(m => m.TransactionalModule)
      },
      {
        path: 'account',
        loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
      },
      {
        path: 'callback',
        loadChildren: () => import('./modules/callback/callback.module').then(m => m.CallbackModule)
      },
      {
        path: 'legal/:doc',
        component: LegalDocsComponent
      },
      {
        path: 'unauthorised',
        component: UnauthorisedComponent
      },
      {
        path: '**',
        component: PageNotFoundComponent
      }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
}) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
}
