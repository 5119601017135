import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

import { BehaviorSubject, fromEvent, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  public isOnline$ = new BehaviorSubject<boolean>(true);
  private isOnline = true;

  constructor(private snackBar: MatSnackBar) {
    fromEvent(window, 'online').subscribe(() => {
      this.isOnline = true;
      this.isOnline$.next(true);
      this.showMessage('Connection restored!', null, 'top');
    });
    fromEvent(window, 'offline').subscribe(() => {
      this.isOnline = false;
      this.isOnline$.next(false);
      this.showMessage('Connection lost', 12000, 'top');
    });
  }

  public showMessage(message: string, duration?: number, positionV: MatSnackBarVerticalPosition = 'bottom'): void {
    if (!this.isOnline) {
      this.snackBar.open(
        'Connection lost', 'OK', { verticalPosition: 'top' }
      );
    } else {
      this.snackBar.open(
        message, '', { duration: duration || 5000, verticalPosition: positionV }
      );
    }
  }

  public showMessageAndReload(message: string): void {
      this.snackBar.open(
        message, 'Reload', {
          verticalPosition: 'bottom',
          duration: 0
        }
      );

      this.snackBar._openedSnackBarRef.onAction().pipe(take(1)).subscribe(() => {
          window.location.reload();
      });
  }

  public showMessageAndClose(message: string): void {
    this.snackBar.open(
      message, 'Close', {
        verticalPosition: 'bottom',
        duration: 0
      }
    );

    this.snackBar._openedSnackBarRef.onAction().pipe(take(1)).subscribe(() => {
        this.snackBar.dismiss();
    });
}
}
