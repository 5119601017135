import { PubNubActiveUser } from '@thrivesoft/gocore-web';

export class ChatUserModel {
    constructor(
        public uuid: string,
        public role: string,
    ) { }

    static fromProto(proto: PubNubActiveUser) {
        return new ChatUserModel(
            proto.UUID,
            proto.Role
        );
    }

    static toProto(model: ChatUserModel): PubNubActiveUser {
        return new PubNubActiveUser({
            UUID: model.uuid,
            Role: model.role
        });
    }

    static list(list: Array<PubNubActiveUser>): Array<ChatUserModel> {
        return list.map((c) => ChatUserModel.fromProto(c));
    }
}