export enum MomentType {
  Chat = 'chat',
  Call = 'call',
  Note = 'note',
  File = 'file',
  Event = 'event',
  GoogleFile = 'google-file',
  Discharge = 'discharge',
  CgiScore = 'cgi-score'
}
