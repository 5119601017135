<form fxLayout="column" [formGroup]="oneTimePasswordFormGroup" fxLayoutGap="12px">
  <div fxLayout="row" fxLayoutAlign="center" fxFlexFill>
    <a [href]="googleAuthLink">
      <!--Use result of qrData from *ngIf otherwise passing (qrData | async) into [qrdata] will cause data will be null-->
      <div id="qr-container">
        <qrcode *ngIf="(qrData | async) as data" [qrdata]="data" [width]="190" [errorCorrectionLevel]="'M'" allowEmptyString="true"></qrcode>
      </div>
    </a>
  </div>
  <div id="secret">{{secret}}</div>
  <mat-form-field appearance="outline" class="full-width-input">
    <mat-label>Confirmation Code</mat-label>
    <input #token type="text" matInput formControlName="oneTimePasswordFormControl">
    <mat-hint>
      Enter the 6 digit code from your authenticator app
    </mat-hint>
  </mat-form-field>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <button type="button" mat-button (click)="backButtonClicked()">Back</button>
    <button mat-raised-button color="primary" (click)="submitMfaSetup(token.value)"
            [disabled]="token.value.length !== QR_CODE_LENGTH">
      Next
    </button>
  </div>
</form>
