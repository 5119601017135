import { ErrorCategory, ResponseError } from '@thrivesoft/gocore-web';

export class CoreResponseModel {
    constructor(
        public message: string,
        public localizationKey: string,
        public category: ErrorCategory,
        public errorCode: number,
        public description: string
    ) { }

    static fromProto(proto: ResponseError): CoreResponseModel {
        return new CoreResponseModel(
            proto.Message,
            proto.LocalizationKey,
            proto.category,
            proto.ErrorCode,
            proto.Description
        );
    }

    public toString(): string {
        let result = `${this.errorCode.toString()}: ${this.message}`;
        if (this.description.trim()!== '') {
          result += ` - ${this.description}`;
        }
        return result;
      }
}