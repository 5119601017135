import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { MessageService } from '@shared/services/message.service';
import { TubErrorReportingService } from '@shared/services/tub-error-reporting.service';
import { FirebaseAuthErrors } from '@app/modules/authentication/login-stepper/firebase-auth-errors';

@Injectable()
export class DashboardErrorHandler implements ErrorHandler {
  constructor(private messageService: MessageService,
              private tubErrorReportingService: TubErrorReportingService,
              ) {}

  handleError(error: Error | HttpErrorResponse): void {
    // Firebase login errors handled in login stepper
    if (!this.checkNotFirebaseAuth(error)) {
      this.messageService.showMessage('Error: Sorry, something went wrong - please try again.');
      console.error(error);
      this.tubErrorReportingService.send('Uncaught exception', error);
    }
  }

  private checkNotFirebaseAuth(error: Error): boolean {
    for (const authErr of Object.keys(FirebaseAuthErrors)) {
      if (error && error.message.includes(FirebaseAuthErrors[authErr])) {
        return true;
      }
    }

    return false;
  }
}
