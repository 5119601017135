import { Injectable } from '@angular/core';

import { firstValueFrom } from 'rxjs';

import { IAMSOpenIDService } from '@backend-client/services/iamsopen-id.service';
import { TubOpenidProvider } from '@backend-client/models/tub-openid-provider';

@Injectable({
  providedIn: 'root'
})

export class LoginEmailPasswordService {
  public EXPIRY_TIME = new Date(new Date().getTime() + 5 * 60000);
  public VALID_NONCE_CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
  public NONCE_STATE_LENGTH = 10;
  public error: string;

  constructor(private iamsService: IAMSOpenIDService) { }

  public async getProviders(): Promise<TubOpenidProvider[]> {
    try {
      const providers = await firstValueFrom(this.iamsService.IamsOpenIDGetProviders());

      if (providers) {
        return providers;
      }
    } catch (error) {
      console.error('Error fetching OpenID providers:', error);
    }
  }

  public generateRandomNonceState() {
    let nonce = '';

    for (let i = 0; i < this.NONCE_STATE_LENGTH; i++) {
      nonce += this.VALID_NONCE_CHARACTERS.charAt(Math.floor(Math.random() * this.VALID_NONCE_CHARACTERS.length));
    }
    return nonce;
  }
}
