import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment';

/**
 * Responsible for storing the session cookie retrieved during login for use in any requests bound for TUB
 * @see ApiInterceptor
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationCookieService {
  public sessionCookieToken$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  
  public updateSessionCookie(token: string) {
    this.sessionCookieToken$.next(token);
    if (!environment.production) {
      if (token) {
        localStorage.setItem('dev-session', token);
      } else {
        localStorage.removeItem('dev-session');
      }
    }
  }

  public getSessionCookie() {
    return this.sessionCookieToken$.getValue();
  }

  constructor() {
    if (!environment.production) {
      this.sessionCookieToken$.next(localStorage.getItem('dev-session'));
    }
  }
}
