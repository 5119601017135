import { PubNubChannelUser } from '@thrivesoft/gocore-web';

export class ChatChannelUserModel {
    constructor(
        public name: string,
        public therapist: boolean
    ) { }

    static fromProto(proto: PubNubChannelUser) {
        return new ChatChannelUserModel(
            proto.name.trim(),
            proto.therapist
        );
    }

    static toProto(model: ChatChannelUserModel): PubNubChannelUser {
        return new PubNubChannelUser({
           name: model.name.trim(),
           therapist: model.therapist
        });
    }

    static list(list: Array<PubNubChannelUser>): Array<ChatChannelUserModel> {
        return list.map((c) => ChatChannelUserModel.fromProto(c));
    }
}