import { Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { AuthenticationService } from '@authentication/authentication.service';
import { MessageService } from '@shared/services/message.service';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { GoogleDriveService } from '@app/modules/shared/services/google-drive/google-drive.service';

@Component({
  selector: 'app-logout-confirmation-dialog',
  templateUrl: './logout-confirmation-dialog.component.html',
  styleUrls: ['./logout-confirmation-dialog.component.scss']
})
export class LogoutConfirmationDialogComponent {

  @ViewChild('dialog') dialog: DialogComponent;

  constructor(private dialogRef: MatDialogRef<LogoutConfirmationDialogComponent>,
              private authenticationService: AuthenticationService,
              private googleDriveService: GoogleDriveService,
              private messageService: MessageService) { }

  public async logOutButtonClicked(): Promise<void> {
    try {
      await this.dialog.showAsyncProgressForFunction(async () => {
        this.googleDriveService.revokeAccessTokenConsent();
        await this.authenticationService.logOut();
      });
      this.dialogRef.close();
    } catch (err) {
      console.log(err);
      this.dialogRef.close();
      this.messageService.showMessage('Error: Could not log out. Please try again.');
    }
  }

  public cancelButtonClicked(): void {
    this.dialogRef.close();
  }

}
