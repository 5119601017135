import { Injectable } from '@angular/core';

import { firstValueFrom, Subject } from 'rxjs';

import { PatientTimelineService, TherapistService } from '@backend-client/services';
import { EnvironmentService } from '../environment.service';
import { MessageService } from '../message.service';
import { GoogleDriveApiError } from '../../models/google-drive-api-error.model';
import { GoogleDrivePermissionLevel } from './google-drive-permissions.enum';

@Injectable({
  providedIn: 'root'
})

export class GoogleDriveUploadService {
  public UPLOAD_SCOPES = this.environmentService.getEnvironment().google.uploadScope;
  public fileUploadComplete$ = new Subject<void>();

  public set patientId(id: string) {
    this._patientId = id;
    this.getPatientFolderId();
  }
  public get patientId(): string {
    return this._patientId;
  }

  private _patientId: string;
  private patientFolderId: string;

  private get apiKey(): string {
    return this.environmentService.getEnvironment().google.apiKey;
  }
  private get appId(): string {
    return this.environmentService.getEnvironment().google.appId;
  }

  constructor(
    private environmentService: EnvironmentService,
    private messageService: MessageService,
    private therapistService: TherapistService,
    private patientTimelineService: PatientTimelineService
  ) {}

  public createPicker(accessToken: string): void {
    const docsView = new google.picker.DocsView(google.picker.ViewId.DOCS)
      .setIncludeFolders(true)
      .setMode(google.picker.DocsViewMode.LIST);

    const picker = new google.picker.PickerBuilder()
      .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
      .enableFeature(google.picker.Feature.MINE_ONLY)
      .setDeveloperKey(this.apiKey)
      .setAppId(this.appId)
      .setOAuthToken(accessToken)
      .addView(docsView)
      .addView(new google.picker.DocsUploadView())
      .setCallback((data: google.picker.ResponseObject) => this.pickerCallback(data))
      .build();

    picker.setVisible(true);
  }

  private async pickerCallback(data: google.picker.ResponseObject): Promise<void> {
    if (data.action === google.picker.Action.PICKED) {
      const document = data[google.picker.Response.DOCUMENTS];

      for (const doc of document) {
        await this.getGoogleDriveFileDetails(doc[google.picker.Document.ID]);
      }
    } else if (data.action === google.picker.Action.CANCEL) {
      await this.downgradeDrivePermissions();
    }
  }

  private async getGoogleDriveFileDetails(fileId: string): Promise<void> {
    await gapi.client.drive.files.get({
      fileId: fileId,
      fields: '*',
      supportsAllDrives: true,
      supportsTeamDrives: true,
    }).then((res: gapi.client.Response<gapi.client.drive.File>) => {
      this.updateGoogleDriveFile(fileId, res);
    }).catch((error: GoogleDriveApiError) => {
      this.messageService.showMessage(`${error.result.error.code}: ${error.result.error.message}`); console.error(error);
    });
  }

  private updateGoogleDriveFile(fileId: string, response: gapi.client.Response<gapi.client.drive.File>): void {
    const contentRestriction = {
      'readOnly': true,
      'reason': 'File ready to be uploaded via Dashboard',
    };

    // Sends selected file to Shared Drive Folder
    gapi.client.drive.files.update({
      fileId: fileId,
      supportsAllDrives: true,
      supportsTeamDrives: true,
      addParents: this.patientFolderId,
      removeParents: response.result.parents[0],
      resource: {
        contentRestrictions: [contentRestriction],
        copyRequiresWriterPermission: true
      },
    }).then(async (res: gapi.client.Response<gapi.client.drive.File>) => {
      await this.createGoogleFileMoment(res);
    }).catch((error: GoogleDriveApiError) => {
      this.messageService.showMessage(`${error.result.error.code}: ${error.result.error.message}`); console.error(error);
    });
  }

  private async createGoogleFileMoment(response: gapi.client.Response<gapi.client.drive.File>): Promise<void> {
    await firstValueFrom(this.patientTimelineService.PatientsMomentsCreatePatientDriveMoment({
      patientId: this._patientId,
      momentBody: {
        googleDriveFileId: response.result.id,
        googleDriveFolderId: response.result.driveId,
        fileName: response.result.name,
        contentType: response.result.mimeType
      }
    })).then(async () => {
      await this.downgradeDrivePermissions();
      this.fileUploadComplete$.next();
    }).catch((error: GoogleDriveApiError) => {
      this.messageService.showMessage(`${error.result.error.code}: ${error.result.error.message}`);
      console.error('File has been uploaded successfully. Error encountered creating moment for patient. ' + error);
    });
  }

  private async getPatientFolderId(): Promise<void> {
    this.patientFolderId = await firstValueFrom(this.patientTimelineService.PatientsMomentsGetPatientFolderForGoogleDrive(this._patientId));
  }

  private async downgradeDrivePermissions(): Promise<void> {
    return await firstValueFrom(this.therapistService.TherapistEnsureGoogleDriveAccountHasPermission(GoogleDrivePermissionLevel.Viewer));
  }
}
