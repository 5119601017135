import { PubNubChatResponse } from '@thrivesoft/gocore-web';
import { ChatActiveChannelModel } from './chat-active-channel-model';
import { ChatChannelModel } from './chat-channel-model';
import { ChatMessageModel } from './chat-message-model';

export class ChatResponseModel {
    constructor(
        public activeUsers: Array<ChatActiveChannelModel> = [],
        public channels: Array<ChatChannelModel> = [],
        public messages: Array<ChatMessageModel> = [],
        public activeTherapists: Array<string> = [],
        public editString: ChatMessageModel = null,
        public activeChannel: string = '',
        public connected: boolean = false,
    ) { }

    static fromProto(proto: PubNubChatResponse) {
        return new ChatResponseModel(
            ChatActiveChannelModel.list(proto.activeUsers.Channels),
            ChatChannelModel.convertToChannelList(proto.channels.channels),
            ChatMessageModel.messages(proto.messages.messages),
            proto.activeTherapists.Therapists ?? [],
            null,
            proto.activeChannel,
            proto.connected.connected,
        );
    }
}