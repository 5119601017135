/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubClientConfig } from '../models/tub-client-config';
@Injectable({
  providedIn: 'root',
})
class ClientConfigService extends __BaseService {
  static readonly ClientConfigGetClientConfigJsonPath = '/client-config/json';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * get public client configuration object
   *
   * Retrieves all of the information required to initialise the various client side SDKs used by the app
   * Allows clients to be driven entirely by a simple call to this endpoint to configure their entire environment
   * @return Ok
   */
  ClientConfigGetClientConfigJsonResponse(): __Observable<__StrictHttpResponse<TubClientConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/client-config/json`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubClientConfig>;
      })
    );
  }
  /**
   * get public client configuration object
   *
   * Retrieves all of the information required to initialise the various client side SDKs used by the app
   * Allows clients to be driven entirely by a simple call to this endpoint to configure their entire environment
   * @return Ok
   */
  ClientConfigGetClientConfigJson(): __Observable<TubClientConfig> {
    return this.ClientConfigGetClientConfigJsonResponse().pipe(
      __map(_r => _r.body as TubClientConfig)
    );
  }
}

module ClientConfigService {
}

export { ClientConfigService }
