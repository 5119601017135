// The order of properties is the order in which they will be displayed in the HTMl
// Altering the order will break the authentication flow
export enum LoginStep {
  signIn,
  oneTimePassword,
  usbAuthentication,
  choosePassword,
  addAuthenticationPrompt,
  twoFactorAuthentication,
  installAuthenticator,
  scanQrCode,
  success,
  accountRecovery
}
