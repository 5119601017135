import { PubNubMsgData } from '@thrivesoft/gocore-web';
import { ChatReplyModel } from './chat-reply-model';

/**
 * TODO: The Meta object is currently used to dump state and should be removed once GoCore eliminates this bad habit
 */
export type MessageMeta = {
  fields?: {
    [key: string]: any;
  };
  uuid?: string;
  readOnly?: boolean;
  owner?: string;
  reply?: ChatReplyModel;
  sent?: boolean;

  [key: string]: any; // GoCore can add anything to this meta object so let's be safe and allow anything
};

export class ChatMessageModel {
  constructor(
    public timeId: bigint,
    public text: string,
    public originalId: bigint,
    public uuid: string,
    public edited: boolean,
    public deleted: boolean,
    public liked: { [key: string]: string },
    public meta: MessageMeta,
  ) {}

  static fromProto(proto: PubNubMsgData) {
    return new ChatMessageModel(
      proto.TimeID,
      proto.Text[0], // Only first array entry is the actual message text :(
      proto.OriginalId,
      proto.Uuid,
      proto.Edited,
      proto.Deleted,
      proto.Liked,
      proto.Meta,
    );
  }

  static messages(messages: Array<PubNubMsgData>): Array<ChatMessageModel> {
    // Optional chaining needed because sometimes messages are null
    return messages?.map(c => ChatMessageModel.fromProto(c));
  }

  public getDisplayTime() {
    const correctedTime = Number(this.timeId / BigInt(10000));
    return new Date(correctedTime);
  }
}
