export enum AuthState {
    warmup,
    waiting,
    unauthorised,
    authorised,
    logout,
    expired,
    processingCredentials,
    processingToken,
    error,
    signingUp,
    signedUp
}