import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MediaObserver } from '@ngbracket/ngx-layout';

import { takeUntil } from 'rxjs/operators';

import { DialogService } from '@shared/components/dialog/dialog.service';
import { FeedbackFormComponent } from './feedback-form/feedback-form.component';
import { RouterResolveStatusService } from '@shared/services/router-resolve-status.service';
import { MainNavService } from './main-nav.service';
import { ServerStatusService } from '@shared/services/server-status.service';
import { OnDestroyObservable } from '@classes/on-destroy-observable';
import { AuthenticationService } from '@authentication/authentication.service';
import { LogoutConfirmationDialogComponent } from './logout-confirmation-dialog/logout-confirmation-dialog.component';
import { MainNavLinkCollection } from './main-nav-links';
import { EnvironmentService } from '@app/modules/shared/services/environment.service';
import { FeatureFlagService } from '@shared/services/feature-flag.service';
import { OfficeHoursService } from '@shared/services/office-hours/office-hours.service';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: [ './main-nav.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class MainNavComponent extends OnDestroyObservable implements OnInit {

  @Input('matNavContainerStyleOverride') matSidenavContentStyleOverride: { [index: string]: string } = {};
  @ViewChildren('adminLink') adminLinks: QueryList<ElementRef>;

  public serverStatusMessage: string;
  public linkCollections: MainNavLinkCollection[] = [];
  public title: string;
  public isAustralia: boolean = false;

  constructor(private dialogService: DialogService,
              public mediaObserver: MediaObserver,
              public routerResolveStatus: RouterResolveStatusService,
              public mainNavService: MainNavService,
              public featureFlagService: FeatureFlagService,
              private serverStatus: ServerStatusService,
              private authenticationService: AuthenticationService,
              private environmentService: EnvironmentService,
              public officeHoursService: OfficeHoursService
  ) {
    super();
  }

  ngOnInit(): void {

    this.title = this.environmentService.getEnvironment().dashboardTitle ?? 'Thrive';

    this.authenticationService.getUserScopes$().pipe(takeUntil(this.ngOnDestroy$)).subscribe(async () => {
      this.linkCollections = await this.mainNavService.getMainNavLinks();
    });

    this.serverStatus.isServerOnline.subscribe(status => {
      if (this.serverStatus.isGoingOffline) {
        // create a new Date object, using the offline time
        const offlineTime = new Date(status.offlineTime);
        this.serverStatusMessage = 'The Thrive server will go offline at ' + offlineTime;
      } else if (!this.serverStatus.isOnline) {
        this.serverStatusMessage = 'The Thrive server is currently offline for maintenance';
      } else {
        this.serverStatusMessage = '';
      }
    });

    this.isAustralia = this.environmentService.isAustralia();
  }

  public feedbackButtonClicked(): void {
    this.dialogService.openDialog(FeedbackFormComponent);
  }

  public async signOutButtonClicked(): Promise<void> {
    this.dialogService.openDialog(LogoutConfirmationDialogComponent);
  }
}
