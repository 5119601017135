import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { OfficeHoursStatus } from '@shared/services/office-hours/office-hours-status';
import { RemoteConfigService } from '@backend-client/services/remote-config.service';
import { TubRegionCoreHours } from '@backend-client/models/tub-region-core-hours';

@Injectable({
  providedIn: 'root',
})
export class OfficeHoursService implements OnDestroy {
  private schedule: TubRegionCoreHours;
  private officeHours$ = new BehaviorSubject<OfficeHoursStatus>({
    isOfficeHours: false,
    startTime: { hours: 0, minutes: 0 },
    endTime: { hours: 0, minutes: 0 },
  });

  private readonly ONE_MINUTE_IN_MILLISECONDS = 60000;

  private unsubscribe$ = new Subject<void>();
  private isInitialised = false;

  constructor(private remoteConfigService: RemoteConfigService) { }

  /**
   * Method to initialise and periodically refresh the schedule.
   * This is called from the AppOfficeHours component which is only displayed for therapist accounts
   */
  public initialiseSchedule(): void {
    if (this.isInitialised) {
      return;
    }
    // Set up periodic fetching of the schedule
    this.setupFetchSchedule();

    // Initial fetch
    this.refreshSchedule();

    this.isInitialised = true;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private fetchSchedule(): Observable<TubRegionCoreHours> {
    return this.remoteConfigService.RemoteConfigGetRegionCoreHours();
  }

  private checkTime(): void {
    if (!this.schedule) {
      return;
    }

    const now = moment().tz(this.schedule.timeZone);

    const start = moment.tz(this.schedule.timeZone)
      .hours(this.schedule.startTime.hours)
      .minutes(this.schedule.startTime.minutes)
      .seconds(0)
      .milliseconds(0);

    const end = moment.tz(this.schedule.timeZone)
      .hours(this.schedule.endTime.hours)
      .minutes(this.schedule.endTime.minutes)
      .seconds(0)
      .milliseconds(0);

    const isOfficeHours = now.isBetween(start, end);

    this.officeHours$.next({
      isOfficeHours,
      startTime: this.schedule.startTime,
      endTime: this.schedule.endTime,
    });
  }

  public getOfficeHours$(): Observable<OfficeHoursStatus> {
    return this.officeHours$.asObservable();
  }

  // Method to refresh the schedule immediately
  public refreshSchedule(): void {
    this.fetchSchedule().subscribe(schedule => {
      this.schedule = schedule;
      this.checkTime();
    });
  }

  private setupFetchSchedule(): void {
    interval(this.ONE_MINUTE_IN_MILLISECONDS).pipe(
      switchMap(() => this.fetchSchedule()),
      takeUntil(this.unsubscribe$)
    ).subscribe(schedule => {
      this.schedule = schedule;
      this.checkTime();
    });
  }
}
