import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

/* Environment */
import { environment } from '@environments/environment';

/* Protobuf imports */
import {
    PropertyApiKeys, PropertyAuthorisation, Response, ResponseCodes, Message, PropertyLocalConfig
} from '@thrivesoft/gocore-web';

/* Services */
import { AuthenticationCookieService } from '@backend-client/authentication-cookie.service';
import { GoCoreChatService } from './chat/gocore-chat.service';

/* Helpers */
import { GoCoreObserverRepository } from './gocore-observer-repository';
import { GoCoreMediator } from '@thrivesoft/gocore-web';

/* Models */
import { BootState, PropertyConfigModel } from './model';
import { DialogService } from '../../components/dialog/dialog.service';
import { GoUnrecoverableErrorDialogComponent } from '@app/modules/therapist/go-chat/go-errors';
import { AuthenticationService } from '@app/modules/authentication/authentication.service';
import { AuthLogoutActionModel } from './model/auth-logout-action-model';
import { AuthLoginActionModel } from './model/auth-login-action-model';
import { TubErrorReportingService } from '@shared/services/tub-error-reporting.service';

/* Version */
import packageInfo from 'package.json';

@Injectable({
    providedIn: 'root'
})

export class GoCoreService {

    // State Management
    private _state: BootState;
    private _observerRepository: GoCoreObserverRepository;
    private _gocore: GoCoreMediator;
    private _completed: Function;
    private _sessionCookie: string = '';
    private isGoCoreInitialised = false;

    constructor(
        private sessionCookieService: AuthenticationCookieService,
        private authenticationService: AuthenticationService,
        private goCoreChatService: GoCoreChatService,
        private dialogService: DialogService,
        private tubErrorReportingService: TubErrorReportingService
    ) {
        this._gocore = new GoCoreMediator();

        // GoCore needs to be aware of Dashboard user logging out so it can tidy up subscriptions
        this.authenticationService.hasLoggedOut$.subscribe(() => {
          if (this.isGoCoreInitialised) {
            this.logOutFromGoCore();
          }
        });
    }

  public async initialise(): Promise<boolean> {
    return new Promise(resolve => {
      try {
        this._completed = () => resolve(true);
        this.setBootState(BootState.initCore);
        this.isGoCoreInitialised = true;
      } catch (err) {
        console.error(err);
        resolve(false);
      }
    });
  }

    private sendCommand<T extends Message>(command: T, callback?: (r: Response) => void) {
        this._gocore.sendCommand(command, callback);
    }

    private setBootState(newState: BootState) {

        this._state = newState;

        switch (newState) {
            case BootState.initCore:
                this.initCore();
                break;
            case BootState.initRepos:
                this.initRepos();
                break;
            case BootState.initConfig:
                this.initConfig();
                break;
            case BootState.initKeys:
                this.initAPIKeys();
                break;
            case BootState.initAuth:
                this.initAuth();
                break;
            case BootState.complete:
                this._completed();
                break;
            default:
        }
    }

    private async initCore(): Promise<void> {
        await this._gocore.initialise(
            environment.gocore.sourceRootPath,
            environment.gocore.networkPath,
            environment.gocore.buildType,
            () => this.setBootState(BootState.initRepos),
            this.onGoCoreException.bind(this)
        );
    }

    public getGoCoreWebVersion(): string {
      return packageInfo.dependencies['@thrivesoft/gocore-web'];
    }

    private async initConfig(): Promise<void> {
        if (this._state === BootState.initConfig) {
            this._observerRepository.observeProperty(GoCoreObserverRepository.opConfig, (response: Response) => {
                if (response.code === ResponseCodes.BEGIN) {
                    const config = PropertyConfigModel.toProto();
                    this._observerRepository.updateProperty(GoCoreObserverRepository.opConfig, config);
                } else if (response.code === ResponseCodes.UPDATE) {
                    const config = PropertyConfigModel.fromProto(PropertyLocalConfig.fromBinary(response.body));
                    if (config.config != null) {
                        localStorage.setItem('localConfig', config.config ?? '');
                    }
                    if (this._state === BootState.initConfig) {
                        this.setBootState(BootState.initKeys);
                    }
                }
            });
        }
    }

    private onGoCoreException(){
        this.tubErrorReportingService.send('GOCORE_RUNTIME_EXCEPTION');
        this.dialogService.openDialog(GoUnrecoverableErrorDialogComponent);
    }

    private async initRepos() {
        this._observerRepository = new GoCoreObserverRepository(this.sendCommand.bind(this));
        const currentScopes = await firstValueFrom(this.authenticationService.getUserScopes$());
        // Checking to make sure we are not on the callback page - we don't want Auth box appearing
        if (currentScopes[0] === null && !window.location.pathname.includes('callback')) {
            await this.authenticationService.setUserScopes();
        }
        this._observerRepository.observeProperty(GoCoreObserverRepository.opUserProfile, (response: Response) => {
            if (this._state === BootState.awaitUserProfile && response.code === ResponseCodes.UPDATE) {
                this.goCoreChatService.initialise(this._gocore, this._observerRepository);
                this.setBootState(BootState.complete);
            }
        });

        this.setBootState(BootState.initConfig);
    }

    private initAPIKeys(): void {
        this._observerRepository.observeProperty(GoCoreObserverRepository.opAPIKeys, this.onApiKeysObserverResponse.bind(this));
    }

    private onApiKeysObserverResponse(response: Response) {
        if (response.code === ResponseCodes.BEGIN) {
            const keys = {
                Firebase: environment.gocore.Firebase,
                MixpanelProjectID: environment.gocore.mixpanelProjectID,
                PubNubPubKey: environment.gocore.pubNubPubKey,
                PubNubSubKey: environment.gocore.pubNubSubKey,
                TubUrl: environment.gocore.networkPath
            };

            const apiKeys = new PropertyApiKeys(keys);
            this._observerRepository.updateProperty('APIKEYS', apiKeys);

        } else if (response.code === ResponseCodes.UPDATE) {
            if (this._state === BootState.initKeys) {
                this.setBootState(BootState.initAuth);
            }
        }
    }

    /**
     * AUTH LOGIC
     */

    private initAuth(): void {
        this._observerRepository.observeProperty(GoCoreObserverRepository.opAuth, this.onAuthObserverResponse.bind(this));
    }

    private logOutFromGoCore(){
        this._observerRepository.updateProperty(GoCoreObserverRepository.opAuth, AuthLogoutActionModel.toProto());
    }

    private onAuthObserverResponse(response: Response): void {
        const authProp = PropertyAuthorisation.fromBinary(response.body);

        if (response.code === ResponseCodes.BEGIN) {
            this.sessionCookieService.sessionCookieToken$.subscribe(data => {
                if (data === null || '') {
                  return; // we need to wait for valid login response
                }

                this._sessionCookie = data;
                this._observerRepository.updateProperty(GoCoreObserverRepository.opAuth, AuthLoginActionModel.toProto(this._sessionCookie));
            });
        } else if (response.code === ResponseCodes.UPDATE) {
            if (authProp.message === 'ACTION_AUTHORISED') {
                this.setBootState(BootState.awaitUserProfile);
            }
            if (authProp.message === 'ACTION_CREDENTIALS') {
              this.sessionCookieService.sessionCookieToken$.subscribe(data => {
                if (data === null || '') {
                  return; // we need to wait for valid login response
                }

                this._sessionCookie = data;
                this._observerRepository.updateProperty(GoCoreObserverRepository.opAuth, AuthLoginActionModel.toProto(this._sessionCookie));
              });
            }
        }
    }
}
