import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ChatsService } from '@backend-client/services/chats.service';
import { OutgoingMessage } from './classes/outgoing-message';
import { OutgoingMessageBus } from './classes/outgoing-message-bus';

@Injectable({
  providedIn: 'root'
})
export class OutgoingChatMessageService {

  private outgoingMessageIdCounter = 0;

  private readonly messageQueue: OutgoingMessageBus = new OutgoingMessageBus();

  constructor(private chatsService: ChatsService) {
  }

  /**
   * Enqueue a message for sending
   * @param chatSessionId id of the chat session into which this message should be sent
   * @param chatMessageKey key to use for this message on the server side (as generated by push())
   * @param messageText body of the message to send
   * @see firebase.database.Reference.push() for generating the chatMessageKey
   */
  public sendMessage(chatSessionId: string, chatMessageKey: string, messageText: string) {
    // create the message to send
    const newMessage: OutgoingMessage = new OutgoingMessage();
    newMessage.outgoingMessageId = this.outgoingMessageIdCounter++;
    newMessage.status = 'sending';
    newMessage.chatSessionId = chatSessionId;
    newMessage.chatMessageKey = chatMessageKey;
    newMessage.messageText = messageText;
    newMessage.clientTime = Date.now();

    // add the message to the queue
    this.messageQueue.queueMessage(newMessage);

    // perform the request to the send message endpoint
    this.performMessageSend(newMessage);
  }

  /**
   * Perform the underlying message send operation given the specified message
   * @param message object to send
   */
  private performMessageSend(message: OutgoingMessage) {
    // perform the request to the send message endpoint
    this.chatsService.ChatsSendChatMessage({
      chatSessionId: message.chatSessionId,
      chatMessageKey: message.chatMessageKey,
      messageBody: {
        message: message.messageText,
        clientTime: message.clientTime
      }
    }).subscribe({
      next: () => this.messageQueue.dequeueChatMessage(message),
      error: (err) => {
        // If error status is 403, then the user no longer has permission to access the chat.
        // This usually happens if the patient has ended the chat, so the chatSession is no longer available.
        if (err.status === 403) {
          this.messageQueue.dequeueChatMessage(message);
        } else {
          this.messageQueue.markMessageAsFailed(message);
        }
      }
    });
  }

  /**
   * Get an observable showing all queued messages, in ascending chronological order, for a given session.
   * @param chatSessionId id of chat session for which to retrieve queued messages for display
   */
  public getQueuedMessages$(chatSessionId: string) {
    return this.messageQueue.outgoingMessages$.pipe(map(messages => {
      return messages
        .filter(message => message.chatSessionId === chatSessionId)
        .sort((a, b) => a.outgoingMessageId - b.outgoingMessageId);
    }));
  }

  /**
   * Retry a failed message
   * @param key string key of the failed message, as it currently stands (this will change as a result of retrying)
   */
  public retryMessage(key: string) {
    const failedMessage = this.messageQueue.retrieveMessage(key);
    if (failedMessage == null) {
      // silently ignore
      console.warn('Attempted to retry sending a message which is not present in the message queue.', key);
      return;
    }
    if (failedMessage.status !== 'failed') {
      // silently ignore
      console.warn('Attempted to retry sending a message which has not failed.', failedMessage);
      return;
    }

    // adjust the message, ready for retrying
    this.messageQueue.markMessageForRetry(failedMessage);

    // perform the request to the send message endpoint
    this.performMessageSend(failedMessage);
  }

  public getFailedMessages$(): Observable<OutgoingMessage[]> {
    return this.messageQueue.outgoingMessages$.pipe(
      map(outgoingMessage => {
        return outgoingMessage.filter(message => message.status === 'failed');
      })
    );
  }

  public getChatSessionFailedMessages$(chatSessionId: string): Observable<OutgoingMessage[]> {
    return this.getFailedMessages$().pipe(
      map(outgoingMessage => {
        return outgoingMessage.filter(message => message.chatSessionId === chatSessionId);
      })
    );
  }

  /**
   * Remove all queued messages with a particular chatSessionId
   * @param chatSessionId The chatSessionId of the chatSession to remove all queued messages from
   */
  public dequeueAllChatMessages(chatSessionId: string): void {
    this.messageQueue.dequeueAllChatMessages(chatSessionId);
  }
}
