<h2 *ngIf="title" mat-dialog-title>
  <span>{{ title }}</span>
</h2>

<mat-dialog-content [class.resizable]="resizable" fxLayout="column" fxLayoutGap="6px">
  <mat-progress-bar [ngStyle]="{'visibility': isLoading ? 'visible' : 'hidden'}" mode="buffer"></mat-progress-bar>
  <ng-content></ng-content>
</mat-dialog-content>

<mat-dialog-actions align="end" fxLayoutGap="6px">
  <button *ngIf="cancelButtonText" mat-button [disabled]="isLoading" (click)="cancelClicked()">
    {{ cancelButtonText }}
  </button>

  <div *ngIf="confirmActionLoading" class="mdc-button cursor-default">
    <mat-spinner diameter="15" [matTooltip]="confirmActionLoadingTooltip"></mat-spinner>
  </div>

  <button *ngIf="confirmButtonText && !confirmActionLoading" mat-button [color]="confirmButtonColor"
          [disabled]="!isConfirmButtonEnabled || isLoading" (click)="confirmClicked()">
    {{ confirmButtonText }}
  </button>
</mat-dialog-actions>
