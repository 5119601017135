<div class="text-block">
  <p>
    To use the Thrive Dashboard, you will need to add Two Factor Authentication to your account. This
    involves downloading an app to your phone that you will use to confirm your identity every time you log
    in. </p>
  <p>
    Do you already have an Authenticator installed on your phone? </p>
</div>
<div fxLayout="row" fxLayoutAlign="space-around">
  <button type="button" mat-raised-button (click)="hasGotAuthenticator.emit(false)">No</button>
  <button type="button" mat-raised-button color="primary" (click)="hasGotAuthenticator.emit(true)">Yes</button>
</div>
