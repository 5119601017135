import { PubNubUserData } from '@thrivesoft/gocore-web';

export class ChatClientUserModel {
    constructor(
        public id: string,
        public therapist: boolean,
        public email?: string,
        public name?: string
    ) { }

    static fromProto(proto: PubNubUserData) {
        return new ChatClientUserModel(
            proto.id,
            proto.therapist,
            proto.email,
            proto.name,
        );
    }

    static toProto(model: ChatClientUserModel): PubNubUserData {
        return new PubNubUserData({
            id: model.id,
            therapist: model.therapist,
            email: model.email,
            name: model.name,
        });
    }
}