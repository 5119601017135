<app-authentication-container #prompt>
  <mat-horizontal-stepper #stepper (selectionChange)="stepChange($event)">
    <mat-step>
      <div *ngIf="error" class="error-message" fxLayout="column" fxLayoutAlign="center start">
        {{ error }}
      </div>
      <app-login-email-password (hasForgotPassword)="stepper.selectedIndex = page.accountRecovery"
                                (credentials)="login($event)" #loginForm />
    </mat-step>
    <mat-step>
      <!-- stepper.reset() is used so that the validation UI of the EnterAuthenticatorComponent form is cleared when
      navigating away from that step. This is the only way in which the validation can be cleared on a form in a stepper
      when moving between steps. -->
      <app-enter-authenticator #enterAuthenticationCodeComponent
                               (authenticationCode)="authenticateMfa($event)"
                               (back)="stepper.reset()"
                               [idToken]="cachedIdToken"
                               [mfaMethods]="mfaMethods"></app-enter-authenticator>
    </mat-step>
    <mat-step>
      <app-usbauthenticator></app-usbauthenticator>
    </mat-step>
    <mat-step>
      <app-setup-password (password)="createUser($event)"></app-setup-password>
    </mat-step>
    <mat-step>
      <app-add-authenticator-prompt
        (shouldAddAuthenticator)="mfaCheck($event)"></app-add-authenticator-prompt>
    </mat-step>
    <mat-step>
      <div class="step-container">
        <app-has-got-authenticator (hasGotAuthenticator)="checkForAuthenticator($event)"></app-has-got-authenticator>
      </div>
    </mat-step>
    <mat-step>
      <app-install-authenticator (installedAuthenticator)="stepper.next()"></app-install-authenticator>
    </mat-step>
    <mat-step>
      <app-qr-code #qrCode [email]="email" (mfaSetupSubmitted)="addMfa($event)"
                   (back)="stepper.selectedIndex = page.twoFactorAuthentication"></app-qr-code>
    </mat-step>
    <mat-step>
      <app-success [continueUrl]="continueUrl"></app-success>
    </mat-step>
    <mat-step>
      <app-reset-password (backToStart)="resetStepper()"
                          (forgotPassword)="resetPassword($event)"></app-reset-password>
    </mat-step>
  </mat-horizontal-stepper>
</app-authentication-container>


