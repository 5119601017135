<app-dialog #dialog
  (cancel)="cancelButtonClicked()"
  (confirm)="confirmButtonClicked(feedback.value)"
  cancelButtonText="Cancel"
  confirmButtonText="Confirm"
  title="Feedback">
  <form fxLayout="column" >
    <mat-form-field fxFlexFill>
      <mat-label>Enter any feedback here</mat-label>
      <textarea #feedback matInput rows="5" placeholder="I think that..."></textarea>
    </mat-form-field>
  </form>
</app-dialog>
