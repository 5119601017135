/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubOpenidProvider } from '../models/tub-openid-provider';
import { CustomTokenResponse } from '../models/custom-token-response';
@Injectable({
  providedIn: 'root',
})
class IAMSOpenIDService extends __BaseService {
  static readonly IamsOpenIDGetProvidersPath = '/iams/openid/providers';
  static readonly IamsOpenIDGetLoginURLPath = '/iams/openid/loginURL';
  static readonly IamsOpenIDLoginCallbackPath = '/iams/openid/loginCallback';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Return the open id providers that are currently available to the user
   *
   * Return the open id providers that are currently available to the user
   * @return Ok
   */
  IamsOpenIDGetProvidersResponse(): __Observable<__StrictHttpResponse<Array<TubOpenidProvider>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/iams/openid/providers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubOpenidProvider>>;
      })
    );
  }
  /**
   * Return the open id providers that are currently available to the user
   *
   * Return the open id providers that are currently available to the user
   * @return Ok
   */
  IamsOpenIDGetProviders(): __Observable<Array<TubOpenidProvider>> {
    return this.IamsOpenIDGetProvidersResponse().pipe(
      __map(_r => _r.body as Array<TubOpenidProvider>)
    );
  }

  /**
   * Return the open id providers that are currently available to the user
   *
   * Return the login url for the chosen OpenID provider
   * @param clientID
   * @return Ok
   */
  IamsOpenIDGetLoginURLResponse(clientID: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (clientID != null) __params = __params.set('clientID', clientID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/iams/openid/loginURL`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Return the open id providers that are currently available to the user
   *
   * Return the login url for the chosen OpenID provider
   * @param clientID
   * @return Ok
   */
  IamsOpenIDGetLoginURL(clientID: string): __Observable<string> {
    return this.IamsOpenIDGetLoginURLResponse(clientID).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Return the open id providers that are currently available to the user
   *
   * Perform a login with the credentials returned from open id provider
   * @param params The `IAMSOpenIDService.IamsOpenIDLoginCallbackParams` containing the following parameters:
   *
   * - `state`:
   *
   * - `code`:
   *
   * - `clientID`:
   *
   * @return Ok
   */
  IamsOpenIDLoginCallbackResponse(params: IAMSOpenIDService.IamsOpenIDLoginCallbackParams): __Observable<__StrictHttpResponse<CustomTokenResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.state != null) __params = __params.set('state', params.state.toString());
    if (params.code != null) __params = __params.set('code', params.code.toString());
    if (params.clientID != null) __params = __params.set('clientID', params.clientID.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/iams/openid/loginCallback`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomTokenResponse>;
      })
    );
  }
  /**
   * Return the open id providers that are currently available to the user
   *
   * Perform a login with the credentials returned from open id provider
   * @param params The `IAMSOpenIDService.IamsOpenIDLoginCallbackParams` containing the following parameters:
   *
   * - `state`:
   *
   * - `code`:
   *
   * - `clientID`:
   *
   * @return Ok
   */
  IamsOpenIDLoginCallback(params: IAMSOpenIDService.IamsOpenIDLoginCallbackParams): __Observable<CustomTokenResponse> {
    return this.IamsOpenIDLoginCallbackResponse(params).pipe(
      __map(_r => _r.body as CustomTokenResponse)
    );
  }
}

module IAMSOpenIDService {

  /**
   * Parameters for IamsOpenIDLoginCallback
   */
  export interface IamsOpenIDLoginCallbackParams {
    state: string;
    code: string;
    clientID: string;
  }
}

export { IAMSOpenIDService }
