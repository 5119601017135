import { HttpErrorResponse } from '@angular/common/http';

export class ApiHttpErrorResponse extends HttpErrorResponse {
  /**
   * This error string is the first line of any error response body.  It serves to disambiguate when an endpoint can, for example,
   * return multiple different possible 400 errors and the client should be able to distinguish between which flavour of 400 error
   * occurred in order to retry with different parameters, or report the issue back to the user for them to retry.
   */
  public tubErrorToken: string;

  /**
   * Implements [Symbol.hasInstance] so that instanceof can return properly without copy constructors needing to come into play.
   * Essentially: if an object is an instanceof HttpErrorResponse and it has a non-null tubErrorToken, it IS an ApiHttpErrorResponse
   */
  static [Symbol.hasInstance](obj: ApiHttpErrorResponse) {
    return obj instanceof HttpErrorResponse && obj.tubErrorToken != null;
  }
}
