import { PubNubChannelUsers } from '@thrivesoft/gocore-web';
import { ChatChannelUserModel } from './chat-channel-user-model';

export class ChatUserNamesModel {
    constructor(
        public users: Map<string, ChatChannelUserModel>
    ) { }

    static fromProto(proto: PubNubChannelUsers) {
        const users = new Map<string, ChatChannelUserModel>();
        
        Object.entries(proto.Users).map( a => {
            users.set(a[0], ChatChannelUserModel.fromProto(a[1]));
        });

        return new ChatUserNamesModel(
            users
        );
    }
}