// go-chat-duplication
import { Component, OnInit } from '@angular/core';

import { map, take, takeUntil } from 'rxjs/operators';

import { OnDestroyObservable } from '@classes/on-destroy-observable';
import {
  GoDatabaseNotificationType,
  GoNotificationsService,
  GoNotificationStateService,
  GoAudioService,
} from '@shared/services/go-notifications';
import { GoNotificationState } from '@shared/models/go-notification-state.model';
import { MessageService } from '@app/modules/shared/services/message.service';
import { GoActiveChatSessionsService } from '@app/modules/therapist/go-chat/go-active-chat-sessions.service';
import { AssignedPatientsService } from '@app/modules/therapist/assigned-patients/assigned-patients.service';

@Component({
  selector: 'app-go-primary-notification-button',
  templateUrl: './go-primary-notification-button.component.html',
  styleUrls: ['./go-primary-notification-button.component.scss'],
})
export class GoPrimaryNotificationButtonComponent extends OnDestroyObservable implements OnInit {
  public notifications: GoDatabaseNotificationType[] = [];

  constructor(
    private notificationsService: GoNotificationsService,
    private notificationStateService: GoNotificationStateService,
    private assignedPatientsService: AssignedPatientsService,
    private goActiveChatSessionsService: GoActiveChatSessionsService,
    private audioService: GoAudioService,
    private messageService: MessageService,
  ) {
    super();
  }

  ngOnInit() {
    const notificationObservable = this.notificationsService
      .getNotificationObservable()
      .pipe(takeUntil(this.ngOnDestroy$));

    notificationObservable
      .pipe(
        map(notifications => {
          return notifications.filter(notifications => notifications.data['pubNub'] === 'true');
        }),
      )
      .subscribe(databaseNotifications => {
        this.notifications = databaseNotifications;
        if (localStorage.getItem('soundNotificationsEnabled') === 'false' ? false : true) {
          const unreadChatRequests = this.notifications.filter(
            notification =>
              (notification.data.type === 'chatRequest' || notification.data.type === 'chatUpgraded') &&
              notification.read === null,
          );
          const chatUpgraded = unreadChatRequests.filter(notification => notification.data.type === 'chatUpgraded');
          let alreadyNotified = [];
          this.notificationStateService.notifiedUserRequests.pipe(take(1)).subscribe(n => (alreadyNotified = n));

          if (chatUpgraded.length > 0) {
            for (const chat of chatUpgraded) {
              const userNotifiedOfUpgrade = alreadyNotified.filter(n => n.id === chat.id)[0];

              if (!userNotifiedOfUpgrade || !userNotifiedOfUpgrade.userNotified) {
                this.goActiveChatSessionsService.clearCache();
                this.assignedPatientsService.dataStateValid$.next(false);
              }
            }
          }

          if (unreadChatRequests.length > 0) {
            let unreadRequestArray = this.unreadRequests(unreadChatRequests, alreadyNotified);

            if (unreadRequestArray.length > 0 && alreadyNotified.length === 0) {
              this.audioService.playAudioChatRequest();
              unreadRequestArray = this.notificationStateService.setAllUserNotified(unreadRequestArray);
            } else {
              for (const unreadRequest of unreadRequestArray) {
                const notified = alreadyNotified.filter(n => n.id === unreadRequest.id);

                if (notified.length === 0 && unreadRequest.unread === 1 && !unreadRequest.userNotified) {
                  this.audioService.playAudioChatRequest();
                  unreadRequestArray = this.notificationStateService.setUserNotified(
                    unreadRequest.id,
                    unreadRequestArray,
                  );
                }
              }
            }

            this.notificationStateService.notifiedUserRequests.next(unreadRequestArray);
          }
        }
      });
  }

  public get unreadNotificationsCount(): number {
    return this.notifications.filter(notification => notification.read === null).length;
  }

  public async markAllChatRequestNotificationsAsRead(): Promise<void> {
    const unreadNotifications = this.notifications.filter(notification => notification.read === null);
    const unreadNotificationIds = unreadNotifications.map(notification => notification.id);
    try {
      await this.notificationStateService.markAllChatRequestNotificationsAsRead(unreadNotificationIds);
    } catch (err) {
      this.messageService.showMessage('Error: One or more notifications could not be cleared');
    }
  }

  private unreadRequests(
    unreadChatRequests: GoDatabaseNotificationType[],
    alreadyNotified: GoNotificationState[],
  ): GoNotificationState[] {
    const addToNotified: Array<GoNotificationState> = [];
    let notified = null;

    for (const unreadRequest of unreadChatRequests) {
      if (alreadyNotified) {
        notified = alreadyNotified.filter(userNotified => userNotified.id === unreadRequest.id)[0];
      }

      const unread = {
        id: unreadRequest.id,
        unread: 1,
        userNotified: notified?.userNotified || false,
      } as GoNotificationState;

      addToNotified.push(unread);
    }

    return addToNotified;
  }
}
