import { PubNubChannel, PubNubChannelUser, protoInt64 } from '@thrivesoft/gocore-web';

export class ChatChannelModel {
    constructor(
        public name: string,
        public time: bigint,
        public messageCount: number,
        public participants: Map<string, PubNubChannelUser>,
        public activeParticipants: Array<PubNubChannelUser>
    ) { }

    static fromProto(proto: PubNubChannel) {
        return new ChatChannelModel(
            proto.Name,
            protoInt64.parse(proto.time),
            proto.MessageCount,
            new Map (Object.entries(proto.Participants)),
            proto.ActiveParticipants
        );
    }

    static list(list: Array<PubNubChannel>): Array<ChatChannelModel> {
        return list.map((c) => ChatChannelModel.fromProto(c));
    }

    static convertToChannelList(channels: {[key: string]: PubNubChannel}): Array<ChatChannelModel> {
        return Object.values(channels).map(ChatChannelModel.fromProto) ?? [];
    }
      
}