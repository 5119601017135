import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { FirebaseActionsService } from './firebase-actions.service';
import { FirebaseAction } from './firebase-action.enum';
import { MessageService } from '@shared/services/message.service';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-firebase-actions',
  templateUrl: './firebase-actions-stepper.component.html',
  styleUrls: ['./firebase-actions-stepper.component.scss']
})
export class FirebaseActionsStepperComponent implements OnInit {

  @ViewChild('prompt', { static: true }) prompt;
  @ViewChild('stepper', { static: true }) stepper;

  private promptHeaders: { header: string, subHeader: string}[];

  private actionCode: string;
  public continueUrl: string;

  constructor(private firebaseActionsService: FirebaseActionsService,
              private activatedRoute: ActivatedRoute,
              private angularFireAuth: AngularFireAuth,
              private messageService: MessageService,
              private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.promptHeaders = this.firebaseActionsService.getPromptHeaders();

    const mode = this.activatedRoute.snapshot.queryParamMap.get('mode');
    this.actionCode = this.activatedRoute.snapshot.queryParamMap.get('oobCode');
    this.continueUrl = this.activatedRoute.snapshot.queryParamMap.get('continueUrl');

    this.prompt.updateHeader(this.promptHeaders[FirebaseAction[mode]]);
    this.stepper.selectedIndex = FirebaseAction[mode];
  }

  public async resetPassword(password) {
    try {
      this.prompt.isLoading = true;
      const email = await this.angularFireAuth.verifyPasswordResetCode(this.actionCode);
      await this.angularFireAuth.confirmPasswordReset(this.actionCode, password);
      try {
        // Login using new credentials
        const idToken = await this.authenticationService.attemptLoginWithEmailAndPassword(email, password);
        await this.authenticationService.attemptLogin(idToken);
      } catch (err) {
        console.error('Could not automatically log user in');
      }
      this.stepper.selectedIndex = 3;
    } catch (err) {
      // TODO: Invalid or expired action code. Ask user to try to reset the password again.
      this.messageService.showMessage('Error: URL is invalid or expired, please try again');
      console.error(err);
    }
    this.prompt.isLoading = false;
  }

  public stepChange(event: StepperSelectionEvent): void {
    const newHeader = this.promptHeaders[event.selectedIndex];
    this.prompt.stepChange(event, newHeader);
  }

}
