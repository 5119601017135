import { OutgoingMessage } from './outgoing-message';
import { BehaviorSubject } from 'rxjs';

export class OutgoingMessageBus {
  private outgoingMessages: OutgoingMessage[] = [];
  public readonly outgoingMessages$: BehaviorSubject<OutgoingMessage[]> = new BehaviorSubject<OutgoingMessage[]>(this.outgoingMessages);

  /**
   * Add a single message to the queue
   * @param message new message instruction to add
   */
  public queueMessage(message: OutgoingMessage) {
    this.outgoingMessages.push(message);
    this.outgoingMessages$.next(this.outgoingMessages);
  }

  /**
   * Dequeue a single specific message, once it has been successfully sent
   * @param message completed message to remove from the queue
   */
  public dequeueChatMessage(message: OutgoingMessage): void {
    this.outgoingMessages.splice(this.outgoingMessages.indexOf(message), 1);
    this.outgoingMessages$.next(this.outgoingMessages);
  }

  /**
   * Remove all queued messages with a particular chatSessionId
   * @param chatSessionId The chatSessionId of the chatSession to remove all queued messages from
   */
  public dequeueAllChatMessages(chatSessionId: string): void {
    this.outgoingMessages.slice(0).forEach(outgoingMessage => {
      if (outgoingMessage.chatSessionId === chatSessionId) {
        this.dequeueChatMessage(outgoingMessage);
      }
    });
  }

  /**
   * Mark message as failed
   * @param message object to mark as failed
   */
  public markMessageAsFailed(message: OutgoingMessage) {
    message.status = 'failed';
    this.outgoingMessages$.next(this.outgoingMessages);
  }

  /**
   * Retrieve an outgoing message by key
   * @param key chatMessageKey of the message to retrieve
   */
  public retrieveMessage(key: string): OutgoingMessage {
    return this.outgoingMessages.find(message => message.chatMessageKey === key);
  }

  /**
   * Marks a message for 'retry' which means adjusting the key and marking as 'sending'.
   * @param message object to mark for retrying
   */
  public markMessageForRetry(message: OutgoingMessage) {
    // we append a hyphen to ensure that the message is retired with a different key, but do not alter the ordering of the messages
    message.chatMessageKey += '-';
    message.status = 'sending';
    this.outgoingMessages$.next(this.outgoingMessages);
  }

}
