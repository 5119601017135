declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  const __environment;
}
export const environment = __environment;

console.assert( __environment, 'environment not found - ensure that environment file is assigning the configuration to global `__environment`' );
console.assert( __environment.production !== undefined, 'environment must specify production flag');
console.assert( __environment.firebase !== undefined, 'environment must define firebase configuration');
console.assert( __environment.thriveUnifiedBackend !== undefined, 'environment must specify thriveUnifiedBackend url');
