import { MomentType } from './moment-type';

export enum MomentLinkConnectionType {
  None,
  StartLink,
  EndLink
}

export abstract class Moment {
  public abstract readonly typeName: MomentType;

  public get displayLinkConnection(): MomentLinkConnectionType { return MomentLinkConnectionType.None; }

  protected constructor(public id: string, public timestamp: Date, public author: string) { }
}
