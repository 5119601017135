/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubRegionCoreHours } from '../models/tub-region-core-hours';
@Injectable({
  providedIn: 'root',
})
class RemoteConfigService extends __BaseService {
  static readonly RemoteConfigGetValuePath = '/remote-config/value/{key}';
  static readonly RemoteConfigGetConfigPath = '/remote-config/config/{scheme}';
  static readonly RemoteConfigGetRegionCoreHoursPath = '/remote-config/core-hours';
  static readonly RemoteConfigPostRegionCoreHoursPath = '/remote-config/core-hours';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a value from remote config
   * @param key the key of the item in the remote config document
   * @return Ok
   */
  RemoteConfigGetValueResponse(key: string): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/remote-config/value/${encodeURIComponent(String(key))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Get a value from remote config
   * @param key the key of the item in the remote config document
   * @return Ok
   */
  RemoteConfigGetValue(key: string): __Observable<{}> {
    return this.RemoteConfigGetValueResponse(key).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Get and entire config. Useful for precache
   * @param scheme the scheme to get the full config for
   * @return Ok
   */
  RemoteConfigGetConfigResponse(scheme: string): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/remote-config/config/${encodeURIComponent(String(scheme))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Get and entire config. Useful for precache
   * @param scheme the scheme to get the full config for
   * @return Ok
   */
  RemoteConfigGetConfig(scheme: string): __Observable<{}> {
    return this.RemoteConfigGetConfigResponse(scheme).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Get the core office hours of a server's region
   * @return Ok
   */
  RemoteConfigGetRegionCoreHoursResponse(): __Observable<__StrictHttpResponse<TubRegionCoreHours>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/remote-config/core-hours`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubRegionCoreHours>;
      })
    );
  }
  /**
   * Get the core office hours of a server's region
   * @return Ok
   */
  RemoteConfigGetRegionCoreHours(): __Observable<TubRegionCoreHours> {
    return this.RemoteConfigGetRegionCoreHoursResponse().pipe(
      __map(_r => _r.body as TubRegionCoreHours)
    );
  }

  /**
   * Submit the core office hours of a server's region
   * @param coreHours the scheme to get the full config for
   */
  RemoteConfigPostRegionCoreHoursResponse(coreHours: TubRegionCoreHours): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = coreHours;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/remote-config/core-hours`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Submit the core office hours of a server's region
   * @param coreHours the scheme to get the full config for
   */
  RemoteConfigPostRegionCoreHours(coreHours: TubRegionCoreHours): __Observable<null> {
    return this.RemoteConfigPostRegionCoreHoursResponse(coreHours).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module RemoteConfigService {
}

export { RemoteConfigService }
