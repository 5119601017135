/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BatchSyncResponse } from '../models/batch-sync-response';
import { BatchSyncParameters } from '../models/batch-sync-parameters';
import { BatchTimestampsResponse } from '../models/batch-timestamps-response';
import { TubMoment } from '../models/tub-moment';
import { MomentTypeBase } from '../models/moment-type-base';
import { TubAggregatedMoments } from '../models/tub-aggregated-moments';
import { ProgressMoment } from '../models/progress-moment';
import { DashboardTubMoment } from '../models/dashboard-tub-moment';
import { TubPolicyDocument } from '../models/tub-policy-document';
import { TubCreatePatientNoteRequestBody } from '../models/tub-create-patient-note-request-body';
import { TubMomentSignedUrl } from '../models/tub-moment-signed-url';
import { FsNote } from '../models/fs-note';
import { FsGoogleDriveFileMoment } from '../models/fs-google-drive-file-moment';
import { TubCgiCreationRequestBody } from '../models/tub-cgi-creation-request-body';
import { TubCreateMomentDescriptionRequestBody } from '../models/tub-create-moment-description-request-body';
import { TubMomentDescriptionResponseDto } from '../models/tub-moment-description-response-dto';
import { TubMomentDescriptionAuditData } from '../models/tub-moment-description-audit-data';
import { TubPatientRiskAssessment } from '@backend-client/models/tub-patient-risk-assessment';
@Injectable({
  providedIn: 'root',
})
class PatientTimelineService extends __BaseService {
  static readonly MomentsBatchSyncMomentsPath = '/moments/batch-sync';
  static readonly MomentsBatchGetMomentTimestampsPath = '/moments/batch-timestamps';
  static readonly MomentsGetMomentTimestampsPath = '/moments/timestamps';
  static readonly MomentsSyncMomentsPath = '/moments/sync';
  static readonly MomentsAddMomentPath = '/moments/add';
  static readonly MomentsAddCbtJournalMomentPath = '/moments/addJournal';
  static readonly MomentsGetLatestPositiveReadingPath = '/moments/latest-positive';
  static readonly MomentsGetLatestRiskFactorsPath = '/moments/latest-risk-factor';
  static readonly MomentsGetLatestResilienceQuizResultsPath = '/moments/latest-resilience';
  static readonly MomentsGetLatestQuizResultsPath = '/moments/latest-quiz-results';
  static readonly MomentsGetLatestGADorPHQPath = '/moments/latest-gad-or-phq';
  static readonly MomentsGetLatestUnhealthyCBTPath = '/moments/latest-unhealthy';
  static readonly MomentsGetLatestCoreBeliefEntriesPath = '/moments/latest-core-belief-entries';
  static readonly MomentsGetLatestBeingObjectiveEntriesPath = '/moments/latest-being-objective-entries';
  static readonly MomentsGetLatestAutomaticThoughtsEntriesPath = '/moments/latest-automatic-thoughts-entries';
  static readonly MomentsGetLatestHotThoughtExerciseEntryPath = '/moments/latest-hot-thought-exercise-entry';
  static readonly MomentsGetLatestCbtEvidenceEntryPath = '/moments/latest-cbt-evidence-entry';
  static readonly MomentsGetLatestCBTPath = '/moments/latest-cbt';
  static readonly MomentsGetLatestDailyCheckupPath = '/moments/latest-checkup';
  static readonly MomentsGetActivityCountersPath = '/moments/activity-counters';
  static readonly MomentsGetActivityTotalsPath = '/moments/activity-totals';
  static readonly MomentsGetLatestCBTScreeningPath = '/moments/latest-cbt-screening';
  static readonly MomentsGetLatestRelaxationLevelsPath = '/moments/latest-relaxation-levels';
  static readonly MomentsGetProgressMomentsPath = '/moments/progress-moments';
  static readonly MomentsGetHotThoughtMomentsPath = '/moments/hot-thought-moments';
  static readonly PatientsMomentsGetPatientMomentsPath = '/patients/{patientId}/moments';
  static readonly PatientsMomentsGetPatientFileUploadPolicyDocumentPath = '/patients/{patientId}/moments/file-upload-policy';
  static readonly PatientsMomentsCreatePatientNotePath = '/patients/{patientId}/moments/note';
  static readonly PatientsMomentsGetPatientFileContentDownloadUrlPath = '/patients/{patientId}/moments/{momentId}/content';
  static readonly PatientsMomentsDeleteNoteMomentPath = '/patients/{patientId}/moments/notes/{momentId}';
  static readonly PatientsMomentsGetNotePath = '/patients/{patientId}/moments/notes/{momentId}';
  static readonly PatientsMomentsCreatePatientDriveMomentPath = '/patients/{patientId}/moments/drive';
  static readonly PatientsMomentsCreatePatientCgiMomentPath = '/patients/{patientId}/moments/cgi';
  static readonly PatientsMomentsGetPatientDriveMomentPath = '/patients/{patientId}/moments/drive/{momentId}';
  static readonly PatientsMomentsSubmitRiskAssessmentPath = '/patients/{patientId}/moments/risk-assessments';
  static readonly PatientsMomentsUpdateDriveMomentDescriptionPath = '/patients/{patientId}/moments/{momentId}/description';
  static readonly PatientsMomentsGetDriveMomentDescriptionPath = '/patients/{patientId}/moments/{momentId}/description';
  static readonly PatientsMomentsGetDriveMomentDescriptionAuditPath = '/patients/{patientId}/moments/{momentId}/description/audit';
  static readonly PatientsMomentsGetPatientFolderForGoogleDrivePath = '/patients/{patientId}/moments/google-drive-patient-folder';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Batch sync a collection of moments of different eventTypes
   * @param syncOperations representing the parameters of singular calls to the sync endpoint
   * @return Ok
   */
  MomentsBatchSyncMomentsResponse(syncOperations: Array<BatchSyncParameters>): __Observable<__StrictHttpResponse<Array<BatchSyncResponse>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = syncOperations;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/moments/batch-sync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BatchSyncResponse>>;
      })
    );
  }
  /**
   * Batch sync a collection of moments of different eventTypes
   * @param syncOperations representing the parameters of singular calls to the sync endpoint
   * @return Ok
   */
  MomentsBatchSyncMoments(syncOperations: Array<BatchSyncParameters>): __Observable<Array<BatchSyncResponse>> {
    return this.MomentsBatchSyncMomentsResponse(syncOperations).pipe(
      __map(_r => _r.body as Array<BatchSyncResponse>)
    );
  }

  /**
   * get a list of timestamps for the requested types
   *
   * Get a list of timestamps for the requested types
   * @param eventType The types of event for which the query should be run.
   * @return Ok
   */
  MomentsBatchGetMomentTimestampsResponse(eventType: Array<string>): __Observable<__StrictHttpResponse<Array<BatchTimestampsResponse>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (eventType || []).forEach(val => {if (val != null) __params = __params.append('eventType', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/moments/batch-timestamps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BatchTimestampsResponse>>;
      })
    );
  }
  /**
   * get a list of timestamps for the requested types
   *
   * Get a list of timestamps for the requested types
   * @param eventType The types of event for which the query should be run.
   * @return Ok
   */
  MomentsBatchGetMomentTimestamps(eventType: Array<string>): __Observable<Array<BatchTimestampsResponse>> {
    return this.MomentsBatchGetMomentTimestampsResponse(eventType).pipe(
      __map(_r => _r.body as Array<BatchTimestampsResponse>)
    );
  }

  /**
   * get a list of timestamps for the requested type
   *
   * This will allow us to emulate the fuzznet way of doing things. Get a list of timestamps for the requested type
   * @param eventType The type of event for which the query should be run.
   * @return Ok
   */
  MomentsGetMomentTimestampsResponse(eventType: string): __Observable<__StrictHttpResponse<Array<number>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (eventType != null) __params = __params.set('eventType', eventType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/moments/timestamps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<number>>;
      })
    );
  }
  /**
   * get a list of timestamps for the requested type
   *
   * This will allow us to emulate the fuzznet way of doing things. Get a list of timestamps for the requested type
   * @param eventType The type of event for which the query should be run.
   * @return Ok
   */
  MomentsGetMomentTimestamps(eventType: string): __Observable<Array<number>> {
    return this.MomentsGetMomentTimestampsResponse(eventType).pipe(
      __map(_r => _r.body as Array<number>)
    );
  }

  /**
   * Emulate the fuzznet way of doing things by syncing a collection of moments
   * @param params The `PatientTimelineService.MomentsSyncMomentsParams` containing the following parameters:
   *
   * - `startFrom`: The timestamp at which to start retrieving moments from the patient record.
   *
   * - `events`: the event objects sent from the client
   *
   * - `eventType`: the type of event we want to sync
   *
   * - `endAt`: Limit the query to a specific time period. Note that `endAt` must be a time earlier than `startFrom`.
   *
   * @return Ok
   */
  MomentsSyncMomentsResponse(params: PatientTimelineService.MomentsSyncMomentsParams): __Observable<__StrictHttpResponse<Array<TubMoment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startFrom != null) __params = __params.set('startFrom', params.startFrom.toString());
    __body = params.events;
    if (params.eventType != null) __params = __params.set('eventType', params.eventType.toString());
    if (params.endAt != null) __params = __params.set('endAt', params.endAt.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/moments/sync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubMoment>>;
      })
    );
  }
  /**
   * Emulate the fuzznet way of doing things by syncing a collection of moments
   * @param params The `PatientTimelineService.MomentsSyncMomentsParams` containing the following parameters:
   *
   * - `startFrom`: The timestamp at which to start retrieving moments from the patient record.
   *
   * - `events`: the event objects sent from the client
   *
   * - `eventType`: the type of event we want to sync
   *
   * - `endAt`: Limit the query to a specific time period. Note that `endAt` must be a time earlier than `startFrom`.
   *
   * @return Ok
   */
  MomentsSyncMoments(params: PatientTimelineService.MomentsSyncMomentsParams): __Observable<Array<TubMoment>> {
    return this.MomentsSyncMomentsResponse(params).pipe(
      __map(_r => _r.body as Array<TubMoment>)
    );
  }

  /**
   * Add a new moment
   * @param params The `PatientTimelineService.MomentsAddMomentParams` containing the following parameters:
   *
   * - `moment`: the moment to save to the database
   *
   * - `eventType`: the type of event
   *
   * @return The id of the newly created moment
   */
  MomentsAddMomentResponse(params: PatientTimelineService.MomentsAddMomentParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.moment;
    if (params.eventType != null) __params = __params.set('eventType', params.eventType.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/moments/add`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Add a new moment
   * @param params The `PatientTimelineService.MomentsAddMomentParams` containing the following parameters:
   *
   * - `moment`: the moment to save to the database
   *
   * - `eventType`: the type of event
   *
   * @return The id of the newly created moment
   */
  MomentsAddMoment(params: PatientTimelineService.MomentsAddMomentParams): __Observable<string> {
    return this.MomentsAddMomentResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * This endpoint is no longer required. Please instead call `POST /moments/add`
   * @param params The `PatientTimelineService.MomentsAddCbtJournalMomentParams` containing the following parameters:
   *
   * - `moment`: the moment to save to the database
   *
   * - `eventType`: the type of event
   */
  MomentsAddCbtJournalMomentResponse(params: PatientTimelineService.MomentsAddCbtJournalMomentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.moment;
    if (params.eventType != null) __params = __params.set('eventType', params.eventType.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/moments/addJournal`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * This endpoint is no longer required. Please instead call `POST /moments/add`
   * @param params The `PatientTimelineService.MomentsAddCbtJournalMomentParams` containing the following parameters:
   *
   * - `moment`: the moment to save to the database
   *
   * - `eventType`: the type of event
   */
  MomentsAddCbtJournalMoment(params: PatientTimelineService.MomentsAddCbtJournalMomentParams): __Observable<null> {
    return this.MomentsAddCbtJournalMomentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Request just the most recent positive screening for anxiety or depression
   * @param mergeReadings Anxiety and Depression reading can be recorded as separate quizzes. Do we want to get a merged entry with both on it
   * @return Ok
   */
  MomentsGetLatestPositiveReadingResponse(mergeReadings: boolean): __Observable<__StrictHttpResponse<TubMoment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (mergeReadings != null) __params = __params.set('mergeReadings', mergeReadings.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/moments/latest-positive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubMoment>;
      })
    );
  }
  /**
   * Request just the most recent positive screening for anxiety or depression
   * @param mergeReadings Anxiety and Depression reading can be recorded as separate quizzes. Do we want to get a merged entry with both on it
   * @return Ok
   */
  MomentsGetLatestPositiveReading(mergeReadings: boolean): __Observable<TubMoment> {
    return this.MomentsGetLatestPositiveReadingResponse(mergeReadings).pipe(
      __map(_r => _r.body as TubMoment)
    );
  }

  /**
   * Request just the most recent risk factors
   * @return Ok
   */
  MomentsGetLatestRiskFactorsResponse(): __Observable<__StrictHttpResponse<TubMoment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/moments/latest-risk-factor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubMoment>;
      })
    );
  }
  /**
   * Request just the most recent risk factors
   * @return Ok
   */
  MomentsGetLatestRiskFactors(): __Observable<TubMoment> {
    return this.MomentsGetLatestRiskFactorsResponse().pipe(
      __map(_r => _r.body as TubMoment)
    );
  }

  /**
   * Request just the most recent resilience quiz results
   * @return Ok
   */
  MomentsGetLatestResilienceQuizResultsResponse(): __Observable<__StrictHttpResponse<TubMoment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/moments/latest-resilience`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubMoment>;
      })
    );
  }
  /**
   * Request just the most recent resilience quiz results
   * @return Ok
   */
  MomentsGetLatestResilienceQuizResults(): __Observable<TubMoment> {
    return this.MomentsGetLatestResilienceQuizResultsResponse().pipe(
      __map(_r => _r.body as TubMoment)
    );
  }

  /**
   * Request just the most recent quiz results of any type
   * @return Ok
   */
  MomentsGetLatestQuizResultsResponse(): __Observable<__StrictHttpResponse<TubMoment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/moments/latest-quiz-results`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubMoment>;
      })
    );
  }
  /**
   * Request just the most recent quiz results of any type
   * @return Ok
   */
  MomentsGetLatestQuizResults(): __Observable<TubMoment> {
    return this.MomentsGetLatestQuizResultsResponse().pipe(
      __map(_r => _r.body as TubMoment)
    );
  }

  /**
   * Request just the most recent anxiety/depression quiz
   * @return Ok
   */
  MomentsGetLatestGADorPHQResponse(): __Observable<__StrictHttpResponse<TubMoment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/moments/latest-gad-or-phq`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubMoment>;
      })
    );
  }
  /**
   * Request just the most recent anxiety/depression quiz
   * @return Ok
   */
  MomentsGetLatestGADorPHQ(): __Observable<TubMoment> {
    return this.MomentsGetLatestGADorPHQResponse().pipe(
      __map(_r => _r.body as TubMoment)
    );
  }

  /**
   * Request just the most recent unhealthy CBT entry
   * @return Ok
   */
  MomentsGetLatestUnhealthyCBTResponse(): __Observable<__StrictHttpResponse<TubMoment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/moments/latest-unhealthy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubMoment>;
      })
    );
  }
  /**
   * Request just the most recent unhealthy CBT entry
   * @return Ok
   */
  MomentsGetLatestUnhealthyCBT(): __Observable<TubMoment> {
    return this.MomentsGetLatestUnhealthyCBTResponse().pipe(
      __map(_r => _r.body as TubMoment)
    );
  }

  /**
   * Request moments for the core beliefs exercise
   * @return Ok
   */
  MomentsGetLatestCoreBeliefEntriesResponse(): __Observable<__StrictHttpResponse<Array<TubMoment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/moments/latest-core-belief-entries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubMoment>>;
      })
    );
  }
  /**
   * Request moments for the core beliefs exercise
   * @return Ok
   */
  MomentsGetLatestCoreBeliefEntries(): __Observable<Array<TubMoment>> {
    return this.MomentsGetLatestCoreBeliefEntriesResponse().pipe(
      __map(_r => _r.body as Array<TubMoment>)
    );
  }

  /**
   * Request moments for the core beliefs exercise
   * @return Ok
   */
  MomentsGetLatestBeingObjectiveEntriesResponse(): __Observable<__StrictHttpResponse<Array<TubMoment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/moments/latest-being-objective-entries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubMoment>>;
      })
    );
  }
  /**
   * Request moments for the core beliefs exercise
   * @return Ok
   */
  MomentsGetLatestBeingObjectiveEntries(): __Observable<Array<TubMoment>> {
    return this.MomentsGetLatestBeingObjectiveEntriesResponse().pipe(
      __map(_r => _r.body as Array<TubMoment>)
    );
  }

  /**
   * Request moments for the automatic thoughts exercise
   * @return Ok
   */
  MomentsGetLatestAutomaticThoughtsEntriesResponse(): __Observable<__StrictHttpResponse<Array<TubMoment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/moments/latest-automatic-thoughts-entries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubMoment>>;
      })
    );
  }
  /**
   * Request moments for the automatic thoughts exercise
   * @return Ok
   */
  MomentsGetLatestAutomaticThoughtsEntries(): __Observable<Array<TubMoment>> {
    return this.MomentsGetLatestAutomaticThoughtsEntriesResponse().pipe(
      __map(_r => _r.body as Array<TubMoment>)
    );
  }

  /**
   * Request moment for the hot thoughts exercise
   * @return Ok
   */
  MomentsGetLatestHotThoughtExerciseEntryResponse(): __Observable<__StrictHttpResponse<TubMoment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/moments/latest-hot-thought-exercise-entry`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubMoment>;
      })
    );
  }
  /**
   * Request moment for the hot thoughts exercise
   * @return Ok
   */
  MomentsGetLatestHotThoughtExerciseEntry(): __Observable<TubMoment> {
    return this.MomentsGetLatestHotThoughtExerciseEntryResponse().pipe(
      __map(_r => _r.body as TubMoment)
    );
  }

  /**
   * Request moment for the cbt evidence exercise
   * @return Ok
   */
  MomentsGetLatestCbtEvidenceEntryResponse(): __Observable<__StrictHttpResponse<TubMoment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/moments/latest-cbt-evidence-entry`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubMoment>;
      })
    );
  }
  /**
   * Request moment for the cbt evidence exercise
   * @return Ok
   */
  MomentsGetLatestCbtEvidenceEntry(): __Observable<TubMoment> {
    return this.MomentsGetLatestCbtEvidenceEntryResponse().pipe(
      __map(_r => _r.body as TubMoment)
    );
  }

  /**
   * Request just the most recent CBT entry
   * @return Ok
   */
  MomentsGetLatestCBTResponse(): __Observable<__StrictHttpResponse<TubMoment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/moments/latest-cbt`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubMoment>;
      })
    );
  }
  /**
   * Request just the most recent CBT entry
   * @return Ok
   */
  MomentsGetLatestCBT(): __Observable<TubMoment> {
    return this.MomentsGetLatestCBTResponse().pipe(
      __map(_r => _r.body as TubMoment)
    );
  }

  /**
   * Request just the most recent Daily Checkup entry
   * @return Ok
   */
  MomentsGetLatestDailyCheckupResponse(): __Observable<__StrictHttpResponse<TubMoment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/moments/latest-checkup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubMoment>;
      })
    );
  }
  /**
   * Request just the most recent Daily Checkup entry
   * @return Ok
   */
  MomentsGetLatestDailyCheckup(): __Observable<TubMoment> {
    return this.MomentsGetLatestDailyCheckupResponse().pipe(
      __map(_r => _r.body as TubMoment)
    );
  }

  /**
   * Request the number of times an array of sessions have been completed
   * @param params The `PatientTimelineService.MomentsGetActivityCountersParams` containing the following parameters:
   *
   * - `startTime`: unix timestamp in milliseconds of the oldest time we want to include in the query. end time is time now
   *
   * - `activityIds`: a string array of activity IDs to count the sessions for
   *
   * @return Ok
   */
  MomentsGetActivityCountersResponse(params: PatientTimelineService.MomentsGetActivityCountersParams): __Observable<__StrictHttpResponse<Array<number>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startTime != null) __params = __params.set('startTime', params.startTime.toString());
    (params.activityIds || []).forEach(val => {if (val != null) __params = __params.append('activityIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/moments/activity-counters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<number>>;
      })
    );
  }
  /**
   * Request the number of times an array of sessions have been completed
   * @param params The `PatientTimelineService.MomentsGetActivityCountersParams` containing the following parameters:
   *
   * - `startTime`: unix timestamp in milliseconds of the oldest time we want to include in the query. end time is time now
   *
   * - `activityIds`: a string array of activity IDs to count the sessions for
   *
   * @return Ok
   */
  MomentsGetActivityCounters(params: PatientTimelineService.MomentsGetActivityCountersParams): __Observable<Array<number>> {
    return this.MomentsGetActivityCountersResponse(params).pipe(
      __map(_r => _r.body as Array<number>)
    );
  }

  /**
   * Request the number of times user has completed a session in their history
   * @param activityIds a string array of activity IDs to count the sessions for. If empty, then all sessions will be returned
   * @return Ok
   */
  MomentsGetActivityTotalsResponse(activityIds: Array<string>): __Observable<__StrictHttpResponse<TubAggregatedMoments>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (activityIds || []).forEach(val => {if (val != null) __params = __params.append('activityIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/moments/activity-totals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubAggregatedMoments>;
      })
    );
  }
  /**
   * Request the number of times user has completed a session in their history
   * @param activityIds a string array of activity IDs to count the sessions for. If empty, then all sessions will be returned
   * @return Ok
   */
  MomentsGetActivityTotals(activityIds: Array<string>): __Observable<TubAggregatedMoments> {
    return this.MomentsGetActivityTotalsResponse(activityIds).pipe(
      __map(_r => _r.body as TubAggregatedMoments)
    );
  }

  /**
   * Return the latest CBT Programme screening
   * @return Ok
   */
  MomentsGetLatestCBTScreeningResponse(): __Observable<__StrictHttpResponse<TubMoment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/moments/latest-cbt-screening`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubMoment>;
      })
    );
  }
  /**
   * Return the latest CBT Programme screening
   * @return Ok
   */
  MomentsGetLatestCBTScreening(): __Observable<TubMoment> {
    return this.MomentsGetLatestCBTScreeningResponse().pipe(
      __map(_r => _r.body as TubMoment)
    );
  }

  /**
   * Return the latest relaxation levels
   * @param count
   * @return Ok
   */
  MomentsGetLatestRelaxationLevelsResponse(count: number): __Observable<__StrictHttpResponse<Array<TubMoment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (count != null) __params = __params.set('count', count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/moments/latest-relaxation-levels`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubMoment>>;
      })
    );
  }
  /**
   * Return the latest relaxation levels
   * @param count
   * @return Ok
   */
  MomentsGetLatestRelaxationLevels(count: number): __Observable<Array<TubMoment>> {
    return this.MomentsGetLatestRelaxationLevelsResponse(count).pipe(
      __map(_r => _r.body as Array<TubMoment>)
    );
  }

  /**
   * Return the moments relevant to the progress view in the mobile app
   * @param params The `PatientTimelineService.MomentsGetProgressMomentsParams` containing the following parameters:
   *
   * - `to`: the most recent date to include in the query
   *
   * - `from`: the oldest date to start our query
   *
   * - `limit`: optional number of documents to request
   *
   * - `lastDoc`: optional document reference for paging
   *
   * @return Ok
   */
  MomentsGetProgressMomentsResponse(params: PatientTimelineService.MomentsGetProgressMomentsParams): __Observable<__StrictHttpResponse<ProgressMoment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.to != null) __params = __params.set('to', params.to.toString());
    if (params.from != null) __params = __params.set('from', params.from.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.lastDoc != null) __params = __params.set('lastDoc', params.lastDoc.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/moments/progress-moments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProgressMoment>;
      })
    );
  }
  /**
   * Return the moments relevant to the progress view in the mobile app
   * @param params The `PatientTimelineService.MomentsGetProgressMomentsParams` containing the following parameters:
   *
   * - `to`: the most recent date to include in the query
   *
   * - `from`: the oldest date to start our query
   *
   * - `limit`: optional number of documents to request
   *
   * - `lastDoc`: optional document reference for paging
   *
   * @return Ok
   */
  MomentsGetProgressMoments(params: PatientTimelineService.MomentsGetProgressMomentsParams): __Observable<ProgressMoment> {
    return this.MomentsGetProgressMomentsResponse(params).pipe(
      __map(_r => _r.body as ProgressMoment)
    );
  }

  /**
   * Return the moments relevant to the progress view in the mobile app, filtered to just CBT moments with hot thoughts
   * @param params The `PatientTimelineService.MomentsGetHotThoughtMomentsParams` containing the following parameters:
   *
   * - `to`: the most recent date to include in the query
   *
   * - `from`: the oldest date to start our query
   *
   * - `limit`: optional number of documents to request
   *
   * - `lastDoc`: optional document reference for paging
   *
   * @return Ok
   */
  MomentsGetHotThoughtMomentsResponse(params: PatientTimelineService.MomentsGetHotThoughtMomentsParams): __Observable<__StrictHttpResponse<ProgressMoment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.to != null) __params = __params.set('to', params.to.toString());
    if (params.from != null) __params = __params.set('from', params.from.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.lastDoc != null) __params = __params.set('lastDoc', params.lastDoc.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/moments/hot-thought-moments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProgressMoment>;
      })
    );
  }
  /**
   * Return the moments relevant to the progress view in the mobile app, filtered to just CBT moments with hot thoughts
   * @param params The `PatientTimelineService.MomentsGetHotThoughtMomentsParams` containing the following parameters:
   *
   * - `to`: the most recent date to include in the query
   *
   * - `from`: the oldest date to start our query
   *
   * - `limit`: optional number of documents to request
   *
   * - `lastDoc`: optional document reference for paging
   *
   * @return Ok
   */
  MomentsGetHotThoughtMoments(params: PatientTimelineService.MomentsGetHotThoughtMomentsParams): __Observable<ProgressMoment> {
    return this.MomentsGetHotThoughtMomentsResponse(params).pipe(
      __map(_r => _r.body as ProgressMoment)
    );
  }

  /**
   * Retrieve moments for the specified patient, with filter options on moment type and start date.
   * Supports a paginationCursor to enable 'auto-load on scroll' style pagination.
   * @param params The `PatientTimelineService.PatientsMomentsGetPatientMomentsParams` containing the following parameters:
   *
   * - `types`: array of all required types, maximum length of 10 types permitted
   *
   * - `startFrom`: UTC timestamp of the latest moment to fetch
   *
   * - `patientId`: identifies the patient for whom to retrieve moments
   *
   * - `limit`: Maximum number of moments to return
   *
   * - `paginationCursor`: momentId to use as the start of the current page (or more pertinently, the end of the last page)
   *
   * @return Ok
   */
  PatientsMomentsGetPatientMomentsResponse(params: PatientTimelineService.PatientsMomentsGetPatientMomentsParams): __Observable<__StrictHttpResponse<Array<DashboardTubMoment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.types || []).forEach(val => {if (val != null) __params = __params.append('types', val.toString())});
    if (params.startFrom != null) __params = __params.set('startFrom', params.startFrom.toString());

    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.paginationCursor != null) __params = __params.set('paginationCursor', params.paginationCursor.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patients/${encodeURIComponent(String(params.patientId))}/moments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DashboardTubMoment>>;
      })
    );
  }
  /**
   * Retrieve moments for the specified patient, with filter options on moment type and start date.
   * Supports a paginationCursor to enable 'auto-load on scroll' style pagination.
   * @param params The `PatientTimelineService.PatientsMomentsGetPatientMomentsParams` containing the following parameters:
   *
   * - `types`: array of all required types, maximum length of 10 types permitted
   *
   * - `startFrom`: UTC timestamp of the latest moment to fetch
   *
   * - `patientId`: identifies the patient for whom to retrieve moments
   *
   * - `limit`: Maximum number of moments to return
   *
   * - `paginationCursor`: momentId to use as the start of the current page (or more pertinently, the end of the last page)
   *
   * @return Ok
   */
  PatientsMomentsGetPatientMoments(params: PatientTimelineService.PatientsMomentsGetPatientMomentsParams): __Observable<Array<DashboardTubMoment>> {
    return this.PatientsMomentsGetPatientMomentsResponse(params).pipe(
      __map(_r => _r.body as Array<DashboardTubMoment>)
    );
  }

  /**
   * get signed policy for uploading a file-based moment
   *
   * Acquire a Google Storage Signed Policy Document for uploading file content to the storage bucket.
   * This will provide the URL to which to POST the data, together with other fields which should be included provide
   * authentication and metadata to create the moment automatically once the file has been received by Google Cloud
   * Storage.
   * @param params The `PatientTimelineService.PatientsMomentsGetPatientFileUploadPolicyDocumentParams` containing the following parameters:
   *
   * - `type`: the type of moment to create ('call', or 'file')
   *
   * - `patientId`: identifies the patient record to which this file should be uploaded
   *
   * - `fileName`: the original name of the file as uploaded
   *
   * @return Ok
   */
  PatientsMomentsGetPatientFileUploadPolicyDocumentResponse(params: PatientTimelineService.PatientsMomentsGetPatientFileUploadPolicyDocumentParams): __Observable<__StrictHttpResponse<TubPolicyDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.type != null) __params = __params.set('type', params.type.toString());

    if (params.fileName != null) __params = __params.set('fileName', params.fileName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patients/${encodeURIComponent(String(params.patientId))}/moments/file-upload-policy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubPolicyDocument>;
      })
    );
  }
  /**
   * get signed policy for uploading a file-based moment
   *
   * Acquire a Google Storage Signed Policy Document for uploading file content to the storage bucket.
   * This will provide the URL to which to POST the data, together with other fields which should be included provide
   * authentication and metadata to create the moment automatically once the file has been received by Google Cloud
   * Storage.
   * @param params The `PatientTimelineService.PatientsMomentsGetPatientFileUploadPolicyDocumentParams` containing the following parameters:
   *
   * - `type`: the type of moment to create ('call', or 'file')
   *
   * - `patientId`: identifies the patient record to which this file should be uploaded
   *
   * - `fileName`: the original name of the file as uploaded
   *
   * @return Ok
   */
  PatientsMomentsGetPatientFileUploadPolicyDocument(params: PatientTimelineService.PatientsMomentsGetPatientFileUploadPolicyDocumentParams): __Observable<TubPolicyDocument> {
    return this.PatientsMomentsGetPatientFileUploadPolicyDocumentResponse(params).pipe(
      __map(_r => _r.body as TubPolicyDocument)
    );
  }

  /**
   * Creates a moment of type note for a specific user
   *
   * Creates a moment of type note for a specific user
   * @param params The `PatientTimelineService.PatientsMomentsCreatePatientNoteParams` containing the following parameters:
   *
   * - `patientId`: Firestore ID of patient to create note for.
   *
   * - `note`: short note text...? (TODO: remove this in a future build)
   *
   * - `createPatientNoteRequestBody`: request body containing note text
   */
  PatientsMomentsCreatePatientNoteResponse(params: PatientTimelineService.PatientsMomentsCreatePatientNoteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.note != null) __params = __params.set('note', params.note.toString());
    __body = params.createPatientNoteRequestBody;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/patients/${encodeURIComponent(String(params.patientId))}/moments/note`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Creates a moment of type note for a specific user
   *
   * Creates a moment of type note for a specific user
   * @param params The `PatientTimelineService.PatientsMomentsCreatePatientNoteParams` containing the following parameters:
   *
   * - `patientId`: Firestore ID of patient to create note for.
   *
   * - `note`: short note text...? (TODO: remove this in a future build)
   *
   * - `createPatientNoteRequestBody`: request body containing note text
   */
  PatientsMomentsCreatePatientNote(params: PatientTimelineService.PatientsMomentsCreatePatientNoteParams): __Observable<null> {
    return this.PatientsMomentsCreatePatientNoteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * download file content for moment
   *
   * Given a momentId for a particular patient to which the authenticated therapist has access, retrieve a signed URL
   * from Google Cloud Storage for retrieve the file content associated with that specified moment.
   * @param params The `PatientTimelineService.PatientsMomentsGetPatientFileContentDownloadUrlParams` containing the following parameters:
   *
   * - `patientId`: identifies the patient from whose record to download the moments associated file content
   *
   * - `momentId`: identified the moment for which to download the associated file content
   *
   * @return Ok
   */
  PatientsMomentsGetPatientFileContentDownloadUrlResponse(params: PatientTimelineService.PatientsMomentsGetPatientFileContentDownloadUrlParams): __Observable<__StrictHttpResponse<TubMomentSignedUrl>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patients/${encodeURIComponent(String(params.patientId))}/moments/${encodeURIComponent(String(params.momentId))}/content`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubMomentSignedUrl>;
      })
    );
  }
  /**
   * download file content for moment
   *
   * Given a momentId for a particular patient to which the authenticated therapist has access, retrieve a signed URL
   * from Google Cloud Storage for retrieve the file content associated with that specified moment.
   * @param params The `PatientTimelineService.PatientsMomentsGetPatientFileContentDownloadUrlParams` containing the following parameters:
   *
   * - `patientId`: identifies the patient from whose record to download the moments associated file content
   *
   * - `momentId`: identified the moment for which to download the associated file content
   *
   * @return Ok
   */
  PatientsMomentsGetPatientFileContentDownloadUrl(params: PatientTimelineService.PatientsMomentsGetPatientFileContentDownloadUrlParams): __Observable<TubMomentSignedUrl> {
    return this.PatientsMomentsGetPatientFileContentDownloadUrlResponse(params).pipe(
      __map(_r => _r.body as TubMomentSignedUrl)
    );
  }

  /**
   * Delete a Note Moment for a given patient.  This requires the administrator to have access to the patient whose note they wish to delete.
   * @param params The `PatientTimelineService.PatientsMomentsDeleteNoteMomentParams` containing the following parameters:
   *
   * - `patientId`: id of the patient for whom to delete a note
   *
   * - `momentId`: id of the moment that contains the note
   */
  PatientsMomentsDeleteNoteMomentResponse(params: PatientTimelineService.PatientsMomentsDeleteNoteMomentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/patients/${encodeURIComponent(String(params.patientId))}/moments/notes/${encodeURIComponent(String(params.momentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Note Moment for a given patient.  This requires the administrator to have access to the patient whose note they wish to delete.
   * @param params The `PatientTimelineService.PatientsMomentsDeleteNoteMomentParams` containing the following parameters:
   *
   * - `patientId`: id of the patient for whom to delete a note
   *
   * - `momentId`: id of the moment that contains the note
   */
  PatientsMomentsDeleteNoteMoment(params: PatientTimelineService.PatientsMomentsDeleteNoteMomentParams): __Observable<null> {
    return this.PatientsMomentsDeleteNoteMomentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Retrieve a Note Moment for a given patient.  This requires the administrator to have access to the patient whose note they wish to retrieve.
   * @param params The `PatientTimelineService.PatientsMomentsGetNoteParams` containing the following parameters:
   *
   * - `patientId`: id of the patient for whom to retrieve a note
   *
   * - `momentId`: id of the moment that contains the note
   *
   * @return Ok
   */
  PatientsMomentsGetNoteResponse(params: PatientTimelineService.PatientsMomentsGetNoteParams): __Observable<__StrictHttpResponse<FsNote>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patients/${encodeURIComponent(String(params.patientId))}/moments/notes/${encodeURIComponent(String(params.momentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FsNote>;
      })
    );
  }
  /**
   * Retrieve a Note Moment for a given patient.  This requires the administrator to have access to the patient whose note they wish to retrieve.
   * @param params The `PatientTimelineService.PatientsMomentsGetNoteParams` containing the following parameters:
   *
   * - `patientId`: id of the patient for whom to retrieve a note
   *
   * - `momentId`: id of the moment that contains the note
   *
   * @return Ok
   */
  PatientsMomentsGetNote(params: PatientTimelineService.PatientsMomentsGetNoteParams): __Observable<FsNote> {
    return this.PatientsMomentsGetNoteResponse(params).pipe(
      __map(_r => _r.body as FsNote)
    );
  }

  /**
   * create new Google Drive file moment
   *
   * Create a new moment in the patient's timeline linking to a Google Drive file in the shared folder for this patient.
   * @param params The `PatientTimelineService.PatientsMomentsCreatePatientDriveMomentParams` containing the following parameters:
   *
   * - `patientId`: id of the patient for whom to create a moment
   *
   * - `momentBody`: details of the moment to create
   */
  PatientsMomentsCreatePatientDriveMomentResponse(params: PatientTimelineService.PatientsMomentsCreatePatientDriveMomentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.momentBody;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/patients/${encodeURIComponent(String(params.patientId))}/moments/drive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * create new Google Drive file moment
   *
   * Create a new moment in the patient's timeline linking to a Google Drive file in the shared folder for this patient.
   * @param params The `PatientTimelineService.PatientsMomentsCreatePatientDriveMomentParams` containing the following parameters:
   *
   * - `patientId`: id of the patient for whom to create a moment
   *
   * - `momentBody`: details of the moment to create
   */
  PatientsMomentsCreatePatientDriveMoment(params: PatientTimelineService.PatientsMomentsCreatePatientDriveMomentParams): __Observable<null> {
    return this.PatientsMomentsCreatePatientDriveMomentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Submit a CGI score for a patient.
   *
   * Submit a Clinical Global Impression (CGI) score for a patient. This requires the therapist to have access to the patient's record.
   * @param params The `PatientTimelineService.PatientsMomentsCreatePatientCgiMomentParams` containing the following parameters:
   *
   * - `patientId`: The ID of the patient for whom the CGI score is being submitted.
   *
   * - `cgiBody`: The body of the request containing the CGI severity and improvement scores.
   */
  PatientsMomentsCreatePatientCgiMomentResponse(params: PatientTimelineService.PatientsMomentsCreatePatientCgiMomentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.cgiBody;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/patients/${encodeURIComponent(String(params.patientId))}/moments/cgi`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Submit a CGI score for a patient.
   *
   * Submit a Clinical Global Impression (CGI) score for a patient. This requires the therapist to have access to the patient's record.
   * @param params The `PatientTimelineService.PatientsMomentsCreatePatientCgiMomentParams` containing the following parameters:
   *
   * - `patientId`: The ID of the patient for whom the CGI score is being submitted.
   *
   * - `cgiBody`: The body of the request containing the CGI severity and improvement scores.
   */
  PatientsMomentsCreatePatientCgiMoment(params: PatientTimelineService.PatientsMomentsCreatePatientCgiMomentParams): __Observable<null> {
    return this.PatientsMomentsCreatePatientCgiMomentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * get Google Drive file moment
   *
   * Get a moment in the patient's timeline linking to a Google Drive file in the shared folder for this patient.
   * @param params The `PatientTimelineService.PatientsMomentsGetPatientDriveMomentParams` containing the following parameters:
   *
   * - `patientId`: id of the patient for whom to retrieve a moment
   *
   * - `momentId`: id of the moment to retrieve
   *
   * @return Ok
   */
  PatientsMomentsGetPatientDriveMomentResponse(params: PatientTimelineService.PatientsMomentsGetPatientDriveMomentParams): __Observable<__StrictHttpResponse<FsGoogleDriveFileMoment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patients/${encodeURIComponent(String(params.patientId))}/moments/drive/${encodeURIComponent(String(params.momentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FsGoogleDriveFileMoment>;
      })
    );
  }
  /**
   * get Google Drive file moment
   *
   * Get a moment in the patient's timeline linking to a Google Drive file in the shared folder for this patient.
   * @param params The `PatientTimelineService.PatientsMomentsGetPatientDriveMomentParams` containing the following parameters:
   *
   * - `patientId`: id of the patient for whom to retrieve a moment
   *
   * - `momentId`: id of the moment to retrieve
   *
   * @return Ok
   */
  PatientsMomentsGetPatientDriveMoment(params: PatientTimelineService.PatientsMomentsGetPatientDriveMomentParams): __Observable<FsGoogleDriveFileMoment> {
    return this.PatientsMomentsGetPatientDriveMomentResponse(params).pipe(
      __map(_r => _r.body as FsGoogleDriveFileMoment)
    );
  }

  /**
   * Submit a risk assessment.
   *
   * Submit a risk assessment for a patient by either the patient or an authenticated therapist.
   * @param params The `PatientTimelineService.PatientsMomentsSubmitRiskAssessmentParams` containing the following parameters:
   *
   * - `riskAssessment`: the risk assessment to submit
   *
   * - `patientId`: id of the patient for whom a risk assessment is being submitted, or "active-patient" to submit for the authenticated user
   */
  PatientsMomentsSubmitRiskAssessmentResponse(params: PatientTimelineService.PatientsMomentsSubmitRiskAssessmentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.riskAssessment;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/patients/${encodeURIComponent(String(params.patientId))}/moments/risk-assessments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Submit a risk assessment.
   *
   * Submit a risk assessment for a patient by either the patient or an authenticated therapist.
   * @param params The `PatientTimelineService.PatientsMomentsSubmitRiskAssessmentParams` containing the following parameters:
   *
   * - `riskAssessment`: the risk assessment to submit
   *
   * - `patientId`: id of the patient for whom a risk assessment is being submitted, or "active-patient" to submit for the authenticated user
   */
  PatientsMomentsSubmitRiskAssessment(params: PatientTimelineService.PatientsMomentsSubmitRiskAssessmentParams): __Observable<null> {
    return this.PatientsMomentsSubmitRiskAssessmentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update the description of a Google Drive moment
   *
   * Update the description of a drive moment for a given patient.  This requires the therapist to have access to the patient and for the etag to match the etag retrieved from GET /patients/:patientId/moments/:momentId/description
   * @param params The `PatientTimelineService.PatientsMomentsUpdateDriveMomentDescriptionParams` containing the following parameters:
   *
   * - `patientId`: id of the patient for whom to update a moment
   *
   * - `momentId`: id of the moment to add change the description of
   *
   * - `descriptionDTO`: the new description for the moment
   */
  PatientsMomentsUpdateDriveMomentDescriptionResponse(params: PatientTimelineService.PatientsMomentsUpdateDriveMomentDescriptionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.descriptionDTO;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/patients/${encodeURIComponent(String(params.patientId))}/moments/${encodeURIComponent(String(params.momentId))}/description`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update the description of a Google Drive moment
   *
   * Update the description of a drive moment for a given patient.  This requires the therapist to have access to the patient and for the etag to match the etag retrieved from GET /patients/:patientId/moments/:momentId/description
   * @param params The `PatientTimelineService.PatientsMomentsUpdateDriveMomentDescriptionParams` containing the following parameters:
   *
   * - `patientId`: id of the patient for whom to update a moment
   *
   * - `momentId`: id of the moment to add change the description of
   *
   * - `descriptionDTO`: the new description for the moment
   */
  PatientsMomentsUpdateDriveMomentDescription(params: PatientTimelineService.PatientsMomentsUpdateDriveMomentDescriptionParams): __Observable<null> {
    return this.PatientsMomentsUpdateDriveMomentDescriptionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * get the description of a Google Drive moment
   *
   * Retrieve a MomentDescription's description text for a given moment.  This requires the therapist to have access to the patient whose moment they wish to retrieve.
   * @param params The `PatientTimelineService.PatientsMomentsGetDriveMomentDescriptionParams` containing the following parameters:
   *
   * - `patientId`: id of the patient for whom to retrieve a moment
   *
   * - `momentId`: id of the moment to retrieve
   *
   * @return Ok
   */
  PatientsMomentsGetDriveMomentDescriptionResponse(params: PatientTimelineService.PatientsMomentsGetDriveMomentDescriptionParams): __Observable<__StrictHttpResponse<TubMomentDescriptionResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patients/${encodeURIComponent(String(params.patientId))}/moments/${encodeURIComponent(String(params.momentId))}/description`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubMomentDescriptionResponseDto>;
      })
    );
  }
  /**
   * get the description of a Google Drive moment
   *
   * Retrieve a MomentDescription's description text for a given moment.  This requires the therapist to have access to the patient whose moment they wish to retrieve.
   * @param params The `PatientTimelineService.PatientsMomentsGetDriveMomentDescriptionParams` containing the following parameters:
   *
   * - `patientId`: id of the patient for whom to retrieve a moment
   *
   * - `momentId`: id of the moment to retrieve
   *
   * @return Ok
   */
  PatientsMomentsGetDriveMomentDescription(params: PatientTimelineService.PatientsMomentsGetDriveMomentDescriptionParams): __Observable<TubMomentDescriptionResponseDto> {
    return this.PatientsMomentsGetDriveMomentDescriptionResponse(params).pipe(
      __map(_r => _r.body as TubMomentDescriptionResponseDto)
    );
  }

  /**
   * get the audit data for a Google Drive moment description
   *
   * Get the most recent Audit data for a given moment description.  This requires the therapist to have access to the patient to whom the moment belongs.
   * @param params The `PatientTimelineService.PatientsMomentsGetDriveMomentDescriptionAuditParams` containing the following parameters:
   *
   * - `patientId`: id of the patient for whom to retrieve a moment
   *
   * - `momentId`: id of the moment to retrieve
   *
   * @return Ok
   */
  PatientsMomentsGetDriveMomentDescriptionAuditResponse(params: PatientTimelineService.PatientsMomentsGetDriveMomentDescriptionAuditParams): __Observable<__StrictHttpResponse<TubMomentDescriptionAuditData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patients/${encodeURIComponent(String(params.patientId))}/moments/${encodeURIComponent(String(params.momentId))}/description/audit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubMomentDescriptionAuditData>;
      })
    );
  }
  /**
   * get the audit data for a Google Drive moment description
   *
   * Get the most recent Audit data for a given moment description.  This requires the therapist to have access to the patient to whom the moment belongs.
   * @param params The `PatientTimelineService.PatientsMomentsGetDriveMomentDescriptionAuditParams` containing the following parameters:
   *
   * - `patientId`: id of the patient for whom to retrieve a moment
   *
   * - `momentId`: id of the moment to retrieve
   *
   * @return Ok
   */
  PatientsMomentsGetDriveMomentDescriptionAudit(params: PatientTimelineService.PatientsMomentsGetDriveMomentDescriptionAuditParams): __Observable<TubMomentDescriptionAuditData> {
    return this.PatientsMomentsGetDriveMomentDescriptionAuditResponse(params).pipe(
      __map(_r => _r.body as TubMomentDescriptionAuditData)
    );
  }

  /**
   * get patients Google Drive folder ID
   *
   * Gets folder ID for patient within the Shared Google Drive Folder.
   * This will provide a folder for all the patients data to be uploaded to.
   * A new folder will be created if one does not exist.
   * @param patientId the patientID to get the folder ID for
   * @return Ok
   */
  PatientsMomentsGetPatientFolderForGoogleDriveResponse(patientId: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patients/${encodeURIComponent(String(patientId))}/moments/google-drive-patient-folder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * get patients Google Drive folder ID
   *
   * Gets folder ID for patient within the Shared Google Drive Folder.
   * This will provide a folder for all the patients data to be uploaded to.
   * A new folder will be created if one does not exist.
   * @param patientId the patientID to get the folder ID for
   * @return Ok
   */
  PatientsMomentsGetPatientFolderForGoogleDrive(patientId: string): __Observable<string> {
    return this.PatientsMomentsGetPatientFolderForGoogleDriveResponse(patientId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module PatientTimelineService {

  /**
   * Parameters for MomentsSyncMoments
   */
  export interface MomentsSyncMomentsParams {

    /**
     * The timestamp at which to start retrieving moments from the patient record.
     */
    startFrom: number;

    /**
     * the event objects sent from the client
     */
    events: Array<MomentTypeBase>;

    /**
     * the type of event we want to sync
     */
    eventType: string;

    /**
     * Limit the query to a specific time period. Note that `endAt` must be a time earlier than `startFrom`.
     */
    endAt?: number;
  }

  /**
   * Parameters for MomentsAddMoment
   */
  export interface MomentsAddMomentParams {

    /**
     * the moment to save to the database
     */
    moment: MomentTypeBase;

    /**
     * the type of event
     */
    eventType: string;
  }

  /**
   * Parameters for MomentsAddCbtJournalMoment
   */
  export interface MomentsAddCbtJournalMomentParams {

    /**
     * the moment to save to the database
     */
    moment: MomentTypeBase;

    /**
     * the type of event
     */
    eventType: string;
  }

  /**
   * Parameters for MomentsGetActivityCounters
   */
  export interface MomentsGetActivityCountersParams {

    /**
     * unix timestamp in milliseconds of the oldest time we want to include in the query. end time is time now
     */
    startTime: number;

    /**
     * a string array of activity IDs to count the sessions for
     */
    activityIds: Array<string>;
  }

  /**
   * Parameters for MomentsGetProgressMoments
   */
  export interface MomentsGetProgressMomentsParams {

    /**
     * the most recent date to include in the query
     */
    to: number;

    /**
     * the oldest date to start our query
     */
    from: number;

    /**
     * optional number of documents to request
     */
    limit?: number;

    /**
     * optional document reference for paging
     */
    lastDoc?: string;
  }

  /**
   * Parameters for MomentsGetHotThoughtMoments
   */
  export interface MomentsGetHotThoughtMomentsParams {

    /**
     * the most recent date to include in the query
     */
    to: number;

    /**
     * the oldest date to start our query
     */
    from: number;

    /**
     * optional number of documents to request
     */
    limit?: number;

    /**
     * optional document reference for paging
     */
    lastDoc?: string;
  }

  /**
   * Parameters for PatientsMomentsGetPatientMoments
   */
  export interface PatientsMomentsGetPatientMomentsParams {

    /**
     * array of all required types, maximum length of 10 types permitted
     */
    types: Array<'chat' | 'call' | 'note' | 'file' | 'event' | 'discharge' | 'google-file' | 'cgi-score'>;

    /**
     * UTC timestamp of the latest moment to fetch
     */
    startFrom: number;

    /**
     * identifies the patient for whom to retrieve moments
     */
    patientId: string;

    /**
     * Maximum number of moments to return
     */
    limit: number;

    /**
     * momentId to use as the start of the current page (or more pertinently, the end of the last page)
     */
    paginationCursor?: string;
  }

  /**
   * Parameters for PatientsMomentsGetPatientFileUploadPolicyDocument
   */
  export interface PatientsMomentsGetPatientFileUploadPolicyDocumentParams {

    /**
     * the type of moment to create ('call', or 'file')
     */
    type: string;

    /**
     * identifies the patient record to which this file should be uploaded
     */
    patientId: string;

    /**
     * the original name of the file as uploaded
     */
    fileName: string;
  }

  /**
   * Parameters for PatientsMomentsCreatePatientNote
   */
  export interface PatientsMomentsCreatePatientNoteParams {

    /**
     * Firestore ID of patient to create note for.
     */
    patientId: string;

    /**
     * short note text...? (TODO: remove this in a future build)
     */
    note?: string;

    /**
     * request body containing note text
     */
    createPatientNoteRequestBody?: TubCreatePatientNoteRequestBody;
  }

  /**
   * Parameters for PatientsMomentsGetPatientFileContentDownloadUrl
   */
  export interface PatientsMomentsGetPatientFileContentDownloadUrlParams {

    /**
     * identifies the patient from whose record to download the moments associated file content
     */
    patientId: string;

    /**
     * identified the moment for which to download the associated file content
     */
    momentId: string;
  }

  /**
   * Parameters for PatientsMomentsDeleteNoteMoment
   */
  export interface PatientsMomentsDeleteNoteMomentParams {

    /**
     * id of the patient for whom to delete a note
     */
    patientId: string;

    /**
     * id of the moment that contains the note
     */
    momentId: string;
  }

  /**
   * Parameters for PatientsMomentsGetNote
   */
  export interface PatientsMomentsGetNoteParams {

    /**
     * id of the patient for whom to retrieve a note
     */
    patientId: string;

    /**
     * id of the moment that contains the note
     */
    momentId: string;
  }

  /**
   * Parameters for PatientsMomentsCreatePatientDriveMoment
   */
  export interface PatientsMomentsCreatePatientDriveMomentParams {

    /**
     * id of the patient for whom to create a moment
     */
    patientId: string;

    /**
     * details of the moment to create
     */
    momentBody: FsGoogleDriveFileMoment;
  }

  /**
   * Parameters for PatientsMomentsCreatePatientCgiMoment
   */
  export interface PatientsMomentsCreatePatientCgiMomentParams {

    /**
     * The ID of the patient for whom the CGI score is being submitted.
     */
    patientId: string;

    /**
     * The body of the request containing the CGI severity and improvement scores.
     */
    cgiBody: TubCgiCreationRequestBody;
  }

  /**
   * Parameters for PatientsMomentsGetPatientDriveMoment
   */
  export interface PatientsMomentsGetPatientDriveMomentParams {

    /**
     * id of the patient for whom to retrieve a moment
     */
    patientId: string;

    /**
     * id of the moment to retrieve
     */
    momentId: string;
  }

  /**
   * Parameters for PatientsMomentsSubmitRiskAssessment
   */
  export interface PatientsMomentsSubmitRiskAssessmentParams {

    /**
     * the risk assessment to submit
     */
    riskAssessment: TubPatientRiskAssessment;

    /**
     * id of the patient for whom a risk assessment is being submitted, or "active-patient" to submit for the authenticated user
     */
    patientId: string;
  }

  /**
   * Parameters for PatientsMomentsUpdateDriveMomentDescription
   */
  export interface PatientsMomentsUpdateDriveMomentDescriptionParams {

    /**
     * id of the patient for whom to update a moment
     */
    patientId: string;

    /**
     * id of the moment to add change the description of
     */
    momentId: string;

    /**
     * the new description for the moment
     */
    descriptionDTO: TubCreateMomentDescriptionRequestBody;
  }

  /**
   * Parameters for PatientsMomentsGetDriveMomentDescription
   */
  export interface PatientsMomentsGetDriveMomentDescriptionParams {

    /**
     * id of the patient for whom to retrieve a moment
     */
    patientId: string;

    /**
     * id of the moment to retrieve
     */
    momentId: string;
  }

  /**
   * Parameters for PatientsMomentsGetDriveMomentDescriptionAudit
   */
  export interface PatientsMomentsGetDriveMomentDescriptionAuditParams {

    /**
     * id of the patient for whom to retrieve a moment
     */
    patientId: string;

    /**
     * id of the moment to retrieve
     */
    momentId: string;
  }
}

export { PatientTimelineService }
