/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubUserOrganisation } from '../models/tub-user-organisation';
import { UserList } from '../models/user-list';
import { TubCreateWithAccessCodeParameters } from '../models/tub-create-with-access-code-parameters';
import { TubAnalystEscalationResponse } from '../models/tub-analyst-escalation-response';
import { TubAnalystEscalationOptions } from '../models/tub-analyst-escalation-options';
import { TubAnalystDowngradeOptions } from '../models/tub-analyst-downgrade-options';
import { TubTherapistEscalationOptions } from '../models/tub-therapist-escalation-options';
import { TubApplyAccessCodeParameters } from '../models/tub-apply-access-code-parameters';
@Injectable({
  providedIn: 'root',
})
class IAMSUserManagementService extends __BaseService {
  static readonly IamsUserManagementGetUserGroupsPath = '/iams/manage/users/{userId}/groups';
  static readonly IamsUserManagementSetUserGroupsPath = '/iams/manage/users/{userId}/groups';
  static readonly IamsUserManagementGetUserOrganisationsPath = '/iams/manage/users/organisations';
  static readonly IamsUserManagementGetSpecificUserOrganisationsPath = '/iams/manage/users/{userId}/organisations';
  static readonly IamsUserManagementGetUsersPath = '/iams/manage/users';
  static readonly IamsUserManagementCreateWithAccessCodePath = '/iams/manage/users/createWithAccessCode';
  static readonly IamsUserManagementEscalateUserToAnalystPath = '/iams/manage/escalate-to-analyst';
  static readonly IamsUserManagementDowngradeUserFromAnalystPath = '/iams/manage/downgrade-from-analyst';
  static readonly IamsUserManagementEscalateUserToTherapistPath = '/iams/manage/escalate-to-therapist';
  static readonly IamsUserManagementApplyAccessCodePath = '/iams/manage/users/{userId}/access-code';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * summary get the groups of a user
   *
   * Get the supplied user groups
   * @param userId user to operate on
   * @return Ok
   */
  IamsUserManagementGetUserGroupsResponse(userId: string): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/iams/manage/users/${encodeURIComponent(String(userId))}/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * summary get the groups of a user
   *
   * Get the supplied user groups
   * @param userId user to operate on
   * @return Ok
   */
  IamsUserManagementGetUserGroups(userId: string): __Observable<Array<string>> {
    return this.IamsUserManagementGetUserGroupsResponse(userId).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * set groups on user
   *
   * Set the supplied user groups on the user
   * @param params The `IAMSUserManagementService.IamsUserManagementSetUserGroupsParams` containing the following parameters:
   *
   * - `userId`: user to perform operation on
   *
   * - `groups`: groups to apply
   *
   * - `ETag`: eTag of last update time returned from get Function
   */
  IamsUserManagementSetUserGroupsResponse(params: IAMSUserManagementService.IamsUserManagementSetUserGroupsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.groups;
    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/iams/manage/users/${encodeURIComponent(String(params.userId))}/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * set groups on user
   *
   * Set the supplied user groups on the user
   * @param params The `IAMSUserManagementService.IamsUserManagementSetUserGroupsParams` containing the following parameters:
   *
   * - `userId`: user to perform operation on
   *
   * - `groups`: groups to apply
   *
   * - `ETag`: eTag of last update time returned from get Function
   */
  IamsUserManagementSetUserGroups(params: IAMSUserManagementService.IamsUserManagementSetUserGroupsParams): __Observable<null> {
    return this.IamsUserManagementSetUserGroupsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * summary returns all the organisations for the current user
   *
   * Get the list of organisations valid for this user
   * @return Ok
   */
  IamsUserManagementGetUserOrganisationsResponse(): __Observable<__StrictHttpResponse<Array<TubUserOrganisation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/iams/manage/users/organisations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubUserOrganisation>>;
      })
    );
  }
  /**
   * summary returns all the organisations for the current user
   *
   * Get the list of organisations valid for this user
   * @return Ok
   */
  IamsUserManagementGetUserOrganisations(): __Observable<Array<TubUserOrganisation>> {
    return this.IamsUserManagementGetUserOrganisationsResponse().pipe(
      __map(_r => _r.body as Array<TubUserOrganisation>)
    );
  }

  /**
   * summary returns all the organisations for the passed user
   *
   * Get the list of organisations valid for specific user
   * @param userId
   * @return Ok
   */
  IamsUserManagementGetSpecificUserOrganisationsResponse(userId: string): __Observable<__StrictHttpResponse<Array<TubUserOrganisation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/iams/manage/users/${encodeURIComponent(String(userId))}/organisations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubUserOrganisation>>;
      })
    );
  }
  /**
   * summary returns all the organisations for the passed user
   *
   * Get the list of organisations valid for specific user
   * @param userId
   * @return Ok
   */
  IamsUserManagementGetSpecificUserOrganisations(userId: string): __Observable<Array<TubUserOrganisation>> {
    return this.IamsUserManagementGetSpecificUserOrganisationsResponse(userId).pipe(
      __map(_r => _r.body as Array<TubUserOrganisation>)
    );
  }

  /**
   * Retrieve users based on the supplied query
   *
   * TODO: Unit test
   *
   * Returns a query of users based on the supplied parameters.
   * Parameter emailAddress will override all other parameters and return the single unique user for that email.
   * Parameter limit if not supplied will default to 10.
   * The query performed is group[0] && accessCode || group[1] && accessCode || etc
   *
   * ***Note:** Due to how the query is performed it can return more or less entries than the specified limit.*
   * @param params The `IAMSUserManagementService.IamsUserManagementGetUsersParams` containing the following parameters:
   *
   * - `limit`: number of users to fetch
   *
   * - `group`: array of group permissions to filter by.
   *
   * - `emailAddress`: user email address to retrieve by.
   *
   * - `documentCursors`: previously returned documentCursors for pagination.
   *
   * - `accessCode`: accessCode to filter by.
   *
   * @return Ok
   */
  IamsUserManagementGetUsersResponse(params: IAMSUserManagementService.IamsUserManagementGetUsersParams): __Observable<__StrictHttpResponse<UserList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    (params.group || []).forEach(val => {if (val != null) __params = __params.append('group', val.toString())});
    if (params.emailAddress != null) __params = __params.set('emailAddress', params.emailAddress.toString());
    (params.documentCursors || []).forEach(val => {if (val != null) __params = __params.append('documentCursors', val.toString())});
    if (params.accessCode != null) __params = __params.set('accessCode', params.accessCode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/iams/manage/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserList>;
      })
    );
  }
  /**
   * Retrieve users based on the supplied query
   *
   * TODO: Unit test
   *
   * Returns a query of users based on the supplied parameters.
   * Parameter emailAddress will override all other parameters and return the single unique user for that email.
   * Parameter limit if not supplied will default to 10.
   * The query performed is group[0] && accessCode || group[1] && accessCode || etc
   *
   * ***Note:** Due to how the query is performed it can return more or less entries than the specified limit.*
   * @param params The `IAMSUserManagementService.IamsUserManagementGetUsersParams` containing the following parameters:
   *
   * - `limit`: number of users to fetch
   *
   * - `group`: array of group permissions to filter by.
   *
   * - `emailAddress`: user email address to retrieve by.
   *
   * - `documentCursors`: previously returned documentCursors for pagination.
   *
   * - `accessCode`: accessCode to filter by.
   *
   * @return Ok
   */
  IamsUserManagementGetUsers(params: IAMSUserManagementService.IamsUserManagementGetUsersParams): __Observable<UserList> {
    return this.IamsUserManagementGetUsersResponse(params).pipe(
      __map(_r => _r.body as UserList)
    );
  }

  /**
   * creates a user
   *
   * Creates a user and records their access code choice, language choice, and any other applicable data, ready for them
   * to log in and initialise their account for the first time.
   * @param params The `IAMSUserManagementService.IamsUserManagementCreateWithAccessCodeParams` containing the following parameters:
   *
   * - `createUserParameters`: the parameters to create the user with
   *
   * - `multiRegion`: whether the user is being created in a multi-region environment
   *
   * @return Ok
   */
  IamsUserManagementCreateWithAccessCodeResponse(params: IAMSUserManagementService.IamsUserManagementCreateWithAccessCodeParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.createUserParameters;
    if (params.multiRegion != null) __params = __params.set('multiRegion', params.multiRegion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/iams/manage/users/createWithAccessCode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * creates a user
   *
   * Creates a user and records their access code choice, language choice, and any other applicable data, ready for them
   * to log in and initialise their account for the first time.
   * @param params The `IAMSUserManagementService.IamsUserManagementCreateWithAccessCodeParams` containing the following parameters:
   *
   * - `createUserParameters`: the parameters to create the user with
   *
   * - `multiRegion`: whether the user is being created in a multi-region environment
   *
   * @return Ok
   */
  IamsUserManagementCreateWithAccessCode(params: IAMSUserManagementService.IamsUserManagementCreateWithAccessCodeParams): __Observable<string> {
    return this.IamsUserManagementCreateWithAccessCodeResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * escalates a user to an analyst
   *
   * Escalates a user to an analyst, with the access codes given setting what Domo dashboards they have access to.
   * @param options the options for the escalation, e.g access code list, email address
   * @return User was updated or created
   */
  IamsUserManagementEscalateUserToAnalystResponse(options: TubAnalystEscalationOptions): __Observable<__StrictHttpResponse<TubAnalystEscalationResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = options;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/iams/manage/escalate-to-analyst`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubAnalystEscalationResponse>;
      })
    );
  }
  /**
   * escalates a user to an analyst
   *
   * Escalates a user to an analyst, with the access codes given setting what Domo dashboards they have access to.
   * @param options the options for the escalation, e.g access code list, email address
   * @return User was updated or created
   */
  IamsUserManagementEscalateUserToAnalyst(options: TubAnalystEscalationOptions): __Observable<TubAnalystEscalationResponse> {
    return this.IamsUserManagementEscalateUserToAnalystResponse(options).pipe(
      __map(_r => _r.body as TubAnalystEscalationResponse)
    );
  }

  /**
   * downgrades a user from an analyst to a regular user
   *
   * Downgrades a user from an analyst, removing their access codes and removing them from the analyst group,
   * removing their access from Domo dashboards.
   * @param options the options for the downgrade, e.g email address
   */
  IamsUserManagementDowngradeUserFromAnalystResponse(options: TubAnalystDowngradeOptions): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = options;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/iams/manage/downgrade-from-analyst`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * downgrades a user from an analyst to a regular user
   *
   * Downgrades a user from an analyst, removing their access codes and removing them from the analyst group,
   * removing their access from Domo dashboards.
   * @param options the options for the downgrade, e.g email address
   */
  IamsUserManagementDowngradeUserFromAnalyst(options: TubAnalystDowngradeOptions): __Observable<null> {
    return this.IamsUserManagementDowngradeUserFromAnalystResponse(options).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * promotes given user to therapist account
   *
   * Promotes a user to a therapist account.
   * This grants the required usergroups to the user, and creates a therapist record for their account.
   * @param options contains the details required to perform promotion
   */
  IamsUserManagementEscalateUserToTherapistResponse(options: TubTherapistEscalationOptions): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = options;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/iams/manage/escalate-to-therapist`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * promotes given user to therapist account
   *
   * Promotes a user to a therapist account.
   * This grants the required usergroups to the user, and creates a therapist record for their account.
   * @param options contains the details required to perform promotion
   */
  IamsUserManagementEscalateUserToTherapist(options: TubTherapistEscalationOptions): __Observable<null> {
    return this.IamsUserManagementEscalateUserToTherapistResponse(options).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * apply an access code to a user
   *
   * Apply the given access code to the given user, updating the redemption counters and respecting limits.
   * It is also possible to force the transfer, even if the target access code has run out of redemptions.
   * @param params The `IAMSUserManagementService.IamsUserManagementApplyAccessCodeParams` containing the following parameters:
   *
   * - `userId`: the user to apply the access code to
   *
   * - `parameters`: contains the accessCode string and the force boolean
   */
  IamsUserManagementApplyAccessCodeResponse(params: IAMSUserManagementService.IamsUserManagementApplyAccessCodeParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.parameters;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/iams/manage/users/${encodeURIComponent(String(params.userId))}/access-code`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * apply an access code to a user
   *
   * Apply the given access code to the given user, updating the redemption counters and respecting limits.
   * It is also possible to force the transfer, even if the target access code has run out of redemptions.
   * @param params The `IAMSUserManagementService.IamsUserManagementApplyAccessCodeParams` containing the following parameters:
   *
   * - `userId`: the user to apply the access code to
   *
   * - `parameters`: contains the accessCode string and the force boolean
   */
  IamsUserManagementApplyAccessCode(params: IAMSUserManagementService.IamsUserManagementApplyAccessCodeParams): __Observable<null> {
    return this.IamsUserManagementApplyAccessCodeResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module IAMSUserManagementService {

  /**
   * Parameters for IamsUserManagementSetUserGroups
   */
  export interface IamsUserManagementSetUserGroupsParams {

    /**
     * user to perform operation on
     */
    userId: string;

    /**
     * groups to apply
     */
    groups: Array<string>;

    /**
     * eTag of last update time returned from get Function
     */
    ETag: string;
  }

  /**
   * Parameters for IamsUserManagementGetUsers
   */
  export interface IamsUserManagementGetUsersParams {

    /**
     * number of users to fetch
     */
    limit?: number;

    /**
     * array of group permissions to filter by.
     */
    group?: Array<string>;

    /**
     * user email address to retrieve by.
     */
    emailAddress?: string;

    /**
     * previously returned documentCursors for pagination.
     */
    documentCursors?: Array<string>;

    /**
     * accessCode to filter by.
     */
    accessCode?: string;
  }

  /**
   * Parameters for IamsUserManagementCreateWithAccessCode
   */
  export interface IamsUserManagementCreateWithAccessCodeParams {

    /**
     * the parameters to create the user with
     */
    createUserParameters: TubCreateWithAccessCodeParameters;

    /**
     * whether the user is being created in a multi-region environment
     */
    multiRegion?: boolean;
  }

  /**
   * Parameters for IamsUserManagementApplyAccessCode
   */
  export interface IamsUserManagementApplyAccessCodeParams {

    /**
     * the user to apply the access code to
     */
    userId: string;

    /**
     * contains the accessCode string and the force boolean
     */
    parameters: TubApplyAccessCodeParameters;
  }
}

export { IAMSUserManagementService }
