import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { BehaviorSubject, combineLatest, firstValueFrom, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, switchMap, take, takeUntil } from 'rxjs/operators';

import { AudioService, NotificationStateService } from '@app/modules/shared/services/notifications';
import { ActiveChatSessionsService } from '@app/modules/therapist/chat/active-chat-sessions.service';
import { ChatNotificationCacheService } from '@app/modules/therapist/chat/chat-notification-cache.service';
import { RealtimeDatabaseChatNotificationCacheUser } from '@app/modules/therapist/chat/classes/realtime-database-chat-notification-cache';
import { NotificationState } from '@app/modules/shared/models/notification-state.model';
import { TherapistChatService } from '@backend-client/services/therapist-chat.service';
import { OnDestroyObservable } from '@classes/on-destroy-observable';
import { Notification } from '@layout/main-nav/message-notification-button/notification';
import { TubChatSessionV2Summary } from '@backend-client/models/tub-chat-session-v2summary';

@Injectable({
  providedIn: 'root'
})
export class MessageNotificationButtonService extends OnDestroyObservable {
  public notifications = new BehaviorSubject<Notification[]>([]);

  public totalChatNotifications = new BehaviorSubject<number>(0);
  public isCleaningCache = true;

  public totalPatientsWithUnreadMessages = new BehaviorSubject<number>(0);

  private _activeChatSession = new BehaviorSubject<string>(null);
  private patientDetailsCache: {[key: string]: TubChatSessionV2Summary['patient']} = {};

  set activeChatSessionId(id: string) {
    this._activeChatSession.next(id);
  }

  constructor(
    private activeChatSessionsService: ActiveChatSessionsService,
    private audioService: AudioService,
    private notificationStateService: NotificationStateService,
    private chatNotificationCacheService: ChatNotificationCacheService,
    private therapistChatService: TherapistChatService,
    private angularFireAuth: AngularFireAuth
  ) {
    super();
    this.configureTidyNotificationCache();
    this.configureNotifications();
  }

  /**
   * Configures chat notifications based on the chat notification cache, adding any additional data from loaded active chat sessions.
   * Updates the list of notifications and unread count, before playing a notification sound if configured.
   * @private
   */
  private configureNotifications(): void {
    combineLatest([
      this.chatNotificationCacheService.getChatNotificationCache$().pipe(distinctUntilChanged()),
      this.activeChatSessionsService.chatSessions$.pipe(distinctUntilChanged())
    ]).subscribe(async ([ notificationCache, activeChatSessions ]) => {

      const notifications: Notification[] = [];

      for (const notificationCacheChatSessionId in notificationCache) {
        if (notificationCache[notificationCacheChatSessionId].messages.unread > 0) {

          const chatSession = activeChatSessions.find(chatSession => chatSession.id === notificationCacheChatSessionId);

          const notification: Notification = {
            patient: {
              email: chatSession?.patient?.email,
              name: chatSession?.patient?.name
            },
            chatSessionId: notificationCacheChatSessionId,
            messages: {
              last: notificationCache[notificationCacheChatSessionId].messages.last,
              unread: notificationCache[notificationCacheChatSessionId].messages.unread
            }
          };

          notifications.push(notification);
        }

      }
      this.notifications.next(notifications);
      this.updateTotalMessageCount(notifications);

      // notifyUserUnreadMessages() could be refactored to take type Notification[] instead, but it's a bigger refactor, so this conversion
      // has been implemented to keep the scope of the change more confined.
      const notificationCacheUser: RealtimeDatabaseChatNotificationCacheUser = this.convertNotifications(notifications);

      // Play notification sound if configured
      if (localStorage.getItem('soundNotificationsEnabled') !== 'false') {
        this.notifyUserUnreadMessages(notificationCacheUser);
      }
    });
  }

  /**
   * Retrieves the patient details associated with the given chat session ID.
   * If the patient details are already present in the cache, returns them directly.
   * Otherwise, fetches the patient details from the server, saves them in the cache,
   * and returns them.
   *
   * @param chatSessionId The ID of the chat session to retrieve patient details for.
   */
  public async getPatientDetails(chatSessionId: string): Promise<TubChatSessionV2Summary['patient']> {
    // Check if the patient details are already present in the cache
    if (this.patientDetailsCache[chatSessionId]) {
      return this.patientDetailsCache[chatSessionId];
    }
    try {
      const chatSummary: TubChatSessionV2Summary = await firstValueFrom(this.therapistChatService.TherapistChatsControllerV2GetChatSummary(chatSessionId));
      const patientDetails = chatSummary.patient;

      // Save the patient details in the cache for future use
      this.patientDetailsCache[chatSessionId] = patientDetails;
      return patientDetails;
    } catch (e) {
      console.error('Cannot fetch chat session summary from the server', e);
    }
  }

  /**
   * Convert Notification[] to RealtimeDatabaseChatNotificationCacheUser
   * @param notifications The notifications to convert
   * @private
   */
  private convertNotifications(notifications: Notification[]): RealtimeDatabaseChatNotificationCacheUser {
    return notifications.reduce((cacheUser, notification) => {
      cacheUser[notification.chatSessionId] = { messages: { last: notification.messages.last, unread: notification.messages.unread } };
      return cacheUser;
    }, {});
  }

  /**
   * Updates the total message count by summing all unread counts from active chat sessions
   */
  private updateTotalMessageCount(notifications: Notification[]): void {
    const totalUnreadMessageCount = notifications.reduce(
      (total, notification) => total + (notification.messages.unread || 0),
      0
    );

    this.totalChatNotifications.next(totalUnreadMessageCount);
    this.totalPatientsWithUnreadMessages.next(notifications.length);
  }

  private notifyUserUnreadMessages(
    notifications: RealtimeDatabaseChatNotificationCacheUser
  ): void {
    combineLatest([
      this.chatNotificationCacheService.chatHistory,
      this.notificationStateService.notifiedUserMessages,
    ])
      .pipe(take(1))
      .subscribe(([ history, alreadyNotified ]) => {
        let messageArray = this.unreadMessages(notifications, alreadyNotified);
        const unreadMessages = messageArray.filter((chats) => chats.unread > 0).length > 0;
        const activeChatSession = this._activeChatSession.value;

        // On load notify the user once of outstanding notifications
        if (alreadyNotified.length === 0 && unreadMessages) {
          this.audioService.playAudioMessage();
          messageArray = this.notificationStateService.setAllUserNotified(messageArray);
        } else {
          for (const message of messageArray) {
            const keyHistory = history ? history[message.id] : null;
            const notified = alreadyNotified.find(n => n.id === message.id);

            if (
              message.unread > 0 &&
              message.unread !== keyHistory?.messages?.unread &&
              message.userNotified === false && !notified?.userNotified
            ) {
              // Only notify if the user is viewing an active chat but the browser is not in focus
              // if we the user is not viewing an active chat.
              if (
                message.id === activeChatSession && !document.hasFocus() ||
                message.id !== activeChatSession
              ) {
                this.audioService.playAudioMessage();
                messageArray = this.notificationStateService.setUserNotified(message.id, messageArray);
              }
            }
          }
        }

        this.notificationStateService.notifiedUserMessages.next(messageArray);
        this.chatNotificationCacheService.chatHistory.next(notifications);
      });
  }

  private unreadMessages(
    notifications: RealtimeDatabaseChatNotificationCacheUser,
    alreadyNotified: NotificationState[]
  ): Array<NotificationState> {
    const addToNotified: Array<NotificationState> = [];

    if (notifications !== null) {
      Object.keys(notifications).forEach((key) => {
        const keyHistory = history[key];

        if (notifications[key].messages.unread !== keyHistory?.messages?.unread) {
          const unread = {
            id: key,
            unread: notifications[key].messages.unread,
            userNotified: alreadyNotified.find((c) => c.id === key)?.userNotified || false,
          } as NotificationState;

          addToNotified.push(unread);
        }
      });
    }

    return addToNotified;
  }

  /**
   * Sets up a subscription to tidy the notification cache when a new user logs in.
   * This only happens once for each logged-in user per session.
   * @private
   */
  private configureTidyNotificationCache(): void {
    this.angularFireAuth.user
      .pipe(
        filter(user => !!user?.email), // Type guard to ensure user is not null or undefined and has email property
        distinctUntilChanged((previousUser, currentUser) => previousUser?.email === currentUser?.email),
        switchMap(() => {
          return this.therapistChatService.TherapistChatsControllerV2TidyTherapistNotificationCache().pipe(
            catchError(error => {
              console.error('Error tidying therapist notification cache:', error);
              // If the call fails, return null and carry on - this has little impact on the end user
              return of(null);
            })
          );
        }),
        takeUntil(this.ngOnDestroy$)
      )
      .subscribe(() => {
        this.isCleaningCache = false;
      });
  }
}
