import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { SwPush } from '@angular/service-worker';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { filter, switchMap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import 'firebase/compat/firestore';

import { environment } from '@environments/environment';
import { PushNotificationsService } from '@backend-client/services';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private token: string;
  private readonly firebaseMessaging: firebase.messaging.Messaging;

  constructor(
    private pushNotificationsService: PushNotificationsService,
    private angularFireAuth: AngularFireAuth,
    private angularFirestore: AngularFirestore,
    private swPush: SwPush
  ) {
    this.initialiseNotificationClickListener();
    if (firebase.messaging.isSupported()) {
      this.firebaseMessaging = firebase.messaging();
    } else {
      console.warn('Firebase messaging is not supported in this browser.');
    }
  }

  /**
   * cache for observable listening to just the last 20 notifications for the currently active user, in descending time order.
   */
  private notificationCollection$: Observable<DatabaseNotificationType[]> = null;

  public async getPermissionToReceivePushNotifications() {
    if (this.firebaseMessaging) {
      if (environment.production) {
        await Notification.requestPermission();
        if (Notification.permission === 'granted') {
          try {
            this.token = await this.firebaseMessaging.getToken();
            await this.updateToken(this.token);
          } catch (err) {
            console.error('Failed to update token', err);
          }
        }
      } else {
        console.warn('NotificationsService skipping push notification subscribe - not available outside of production mode.');
      }
    }

  }

  async updateToken(token): Promise<void> {
    const user = await this.angularFireAuth.user.pipe(take(1)).toPromise();
    if (!user) {
      return;
    }
    await this.pushNotificationsService.PushNotificationRegisterDeviceToken(token).toPromise();
  }

  initialiseNotificationClickListener(): void {
    if (this.swPush.isEnabled) {
      navigator.serviceWorker
        .ready
        .then(async (registration) => {
          try {
            await this.firebaseMessaging.getToken({
              serviceWorkerRegistration: registration,
              vapidKey: ''
            });
          } catch (e) {
            console.log('Cannot update Service Worker: ', e);
          }
        });
    }
  }

  public async unsubscribeFromNotifications(): Promise<void> {
    if (this.firebaseMessaging) {
      // Only attempt to unsubscribe if in production and notification permission has been granted
      if (environment.production && Notification.permission === 'granted') {
        try {
          await this.firebaseMessaging.deleteToken();
          await this.pushNotificationsService.PushNotificationUnregisterDeviceToken(this.token).toPromise();
        } catch (err) {
          console.error('Push unsubscription failed', err);
        }
      }
    }
  }

  /**
   * Fetches an observable listening to just the last 20 notifications for the currently active user, in descending time order.
   */
  public getNotificationObservable(): Observable<DatabaseNotificationType[]> {
    if (!this.notificationCollection$) {
      this.notificationCollection$ = this.angularFireAuth.user.pipe(
        filter(user => user != null),
        switchMap(user => {
          return this.angularFirestore.collection('notifications', ref =>
            ref.where('user', '==', user.uid)
              .orderBy('ts', 'desc')
              .limit(20)).valueChanges({ idField: 'id' });
        }),
      );
    }
    return this.notificationCollection$;
  }
}

export interface DatabaseNotificationType {
  id: string;
  message?: string;
  ts?: firebase.firestore.Timestamp;
  read?: firebase.firestore.Timestamp;
  data?: { [key: string]: string, type: string };
}
