import { Injectable } from '@angular/core';
import { EnvironmentService } from '@app/modules/shared/services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class LoginStepperService {

  constructor(
    private environmentService: EnvironmentService
  ){

  }
  // This has to stay in the order defined in the HTML
  private promptHeaders = [ {
      header: this.environmentService.getEnvironment().dashboardTitle ?? 'Thrive Dashboard',
      subHeader: 'Login to continue'
    },
    {
      header: 'One Time Password',
      subHeader: 'Enter the 6 digit password'
    },
    {
      header: 'USB Authentication',
      subHeader: 'Please ensure your USB authenticator is plugged in'
    },
    {
      header: 'Choose a Password',
      subHeader: 'Password must be 12 or more characters'
    },
    {
      header: 'Two Factor Authentication',
      subHeader: 'Add additional security to your account'
    },
    {
      header: 'Two Factor Authentication',
      subHeader: 'Add an authenticator'
    },
    {
      header: 'Install Google Authenticator',
      subHeader: 'Select your mobile device'
    },
    {
      header: 'Scan the QR Code',
      subHeader: 'Then enter the 6 digit confirmation code'
    },
    {
      header: 'Success!',
      subHeader: 'Your account has been created'
    },
    {
      header: 'Account Recovery',
      subHeader: 'Enter your email address to begin...'
    } ];

  public getPromptHeaders(): { header: string, subHeader: string }[] {
    return this.promptHeaders;
  }
}
