export function formatImageLink(imageLink: string): string {
    // Only attempt to format the image if there is a link present
    if (imageLink) {
        if (imageLink.startsWith('https://') || imageLink.startsWith('http://')) {
            return imageLink;
        }
        // Some links don't have a leading slash - check to see if this is the case and add one in if necessary
        if (imageLink[0] !== '/') {
            imageLink = '/' + imageLink;
        }
        return 'https://feelstressfree.com/LFS' + imageLink;
    }
}