import { ResponseCodes } from '@thrivesoft/gocore-web';

/**
 * Decorator that only executes the decorated method if the response code from GoCore is equal to ResponseCodes.UPDATE.
 * As we are only interested in responses with ResponseCodes.UPDATE from GoCore, this decorator can be applied to all GoCore response
 * handlers.
 */
export const ExecuteOnUpdate = () => {
  return (target: any, propertyKey: string, descriptor: TypedPropertyDescriptor<(response: any, ...args: any[]) => any>) => {
    const originalMethod = descriptor.value;

    descriptor.value = function (response: any, ...args: any[]) {
      if (response.code !== ResponseCodes.UPDATE) {
        return;
      }
      return originalMethod?.apply(this, [response, ...args]);
    };
    return descriptor;
  };
};
