// go-chat-duplication
import { Directive, Host, Input, OnInit } from '@angular/core';

import { GoPrimaryNotificationComponent } from '../go-primary-notification/go-primary-notification.component';
import { GoDatabaseNotificationType } from '@shared/services/go-notifications';
import { GoDashboardNotification } from './go-dashboard-notification';

@Directive({
  selector: '[appGoLeadStatusChangeNotification]'
})
export class GoLeadStatusChangeNotificationDirective implements OnInit {

  @Input() notificationData: GoDatabaseNotificationType;

  constructor(@Host() private primaryNotificationComponent: GoPrimaryNotificationComponent) { }

  ngOnInit() {
    const { patientId, isLead } = this.notificationData.data;
    const timestamp = this.notificationData.ts;

    const icon = 'next_week';
    const link = `/therapist/assigned-patients/patient-profile/${patientId}`;
    const title = 'Lead Therapist Change';
    const message = `You ${isLead === 'true' ? 'have been made' : 'are no longer'} lead therapist for a patient.`;
    const readAtTime = this.notificationData.read;
    const id = this.notificationData.id;

    this.primaryNotificationComponent.notification = new GoDashboardNotification(icon, link, title, message, timestamp, readAtTime, id);
  }
}
