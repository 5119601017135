/**
 * Decorator that only executes the decorated method if GoCore Chat is initialised.
 */
export const ExecuteIfInitialised = () => {
  return (target: any, propertyKey: string, descriptor: TypedPropertyDescriptor<(response: any, ...args: any[]) => any>) => {
    const originalMethod = descriptor.value;

    descriptor.value = function (response: any, ...args: any[]) {
      if (!this.initialised$.value) {
        return;
      }
      return originalMethod?.apply(this, [response, ...args]);
    };
    return descriptor;
  };
};
