// Google Docs - https://developers.google.com/drive/picker/guides/sample

import { Injectable } from '@angular/core';

import * as jose from 'jose';
import { firstValueFrom } from 'rxjs';

import { TherapistService } from '@backend-client/services';
import { TubTherapistGoogleDriveAccount } from '@backend-client/models';
import { EnvironmentService } from '../environment.service';
import { MessageService } from '../message.service';
import { GoogleDriveUploadService } from './google-drive-upload.service';
import { GoogleDriveDownloadService } from './google-drive-download.service';
import { GoogleDrivePreviewService } from './google-drive-preview.service';
import { GoogleDrivePermissionLevel } from './google-drive-permissions.enum';
import { GoogleDriveActions } from './google-drive-actions.enum';

@Injectable({
  providedIn: 'root',
})
export class GoogleDriveService {
  public tokenClient: google.accounts.oauth2.TokenClient;
  public accessToken: string = undefined;
  public googleUserEmail: string;

  public get apiKey(): string {
    return this.environmentService.getEnvironment().google.apiKey;
  }

  private response: google.accounts.oauth2.TokenResponse;
  private googleUserId: string;

  constructor(
    private environmentService: EnvironmentService,
    private messageService: MessageService,
    private therapistService: TherapistService,
    private googleDriveUploadService: GoogleDriveUploadService,
    private googleDrivePreviewService: GoogleDrivePreviewService,
    private googleDriveDownloadService: GoogleDriveDownloadService,
  ) {}

  /**
   * upload a file
   * @param patientId
   */
  public async uploadFile(patientId: string) {
    // Get the token
    this.tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: this.environmentService.getEnvironment().google.clientId,
      scope: this.googleDriveUploadService.UPLOAD_SCOPES,
      prompt: '',
      callback: (response: google.accounts.oauth2.TokenResponse) => {
        this.handleAuthClick(response, GoogleDriveActions.Upload);
      },
    });

    await this.requestPermissionInit();
    this.googleDriveUploadService.patientId = patientId;
    this.gapiLoaded();
  }

  /* Fetch Therapist Google Account details */
  public async getTherapistGoogleAccount(): Promise<TubTherapistGoogleDriveAccount> {
    return await firstValueFrom(this.therapistService.TherapistGetGoogleDriveAccount());
  }

  public async requestPermissionInit(): Promise<void> {
    try {
      await this.getTherapistGoogleAccount().then(async account => {
        this.initAccount(GoogleDriveActions.Upload, account?.googleDriveEmail);
        this.promptRequestToken();

        if (account?.googleDriveEmail) {
          await this.requestDrivePermissions(GoogleDrivePermissionLevel.Contributor);
        }
      });
    } catch (error) {
      console.error(error);
      this.messageService.showMessage(error.message);
    }
  }

  public gapiLoaded(): void {
    gapi.load('client:picker', this.initializeGapi.bind(this));
  }

  private initializeGapi(): void {
    this.initGoogleDriveApi(GoogleDriveActions.Upload);
  }

  // TODO: Deprecate this
  /**
   * @deprecated
   * @param action
   */
  public gisLibraryLoaded(action: GoogleDriveActions): void {
    switch (action) {
      case GoogleDriveActions.Download:
        this.tokenClient = google.accounts.oauth2.initTokenClient({
          client_id: this.environmentService.getEnvironment().google.clientId,
          scope: this.googleDriveDownloadService.DOWNLOAD_SCOPE,
          prompt: '',
          callback: (response: google.accounts.oauth2.TokenResponse) => {
            this.handleAuthClick(response, action);
          },
        });
        break;
      case GoogleDriveActions.Upload:
        this.tokenClient = google.accounts.oauth2.initTokenClient({
          client_id: this.environmentService.getEnvironment().google.clientId,
          scope: this.googleDriveUploadService.UPLOAD_SCOPES,
          prompt: '',
          callback: (response: google.accounts.oauth2.TokenResponse) => {
            if (!response?.error) {
              this.promptLogin();
            }

            this.response = response;
          },
        });
        break;
      case GoogleDriveActions.Preview:
      default:
        this.tokenClient = google.accounts.oauth2.initTokenClient({
          client_id: this.environmentService.getEnvironment().google.clientId,
          scope: this.googleDrivePreviewService.PREVIEW_SCOPES,
          prompt: '',
          callback: (response: google.accounts.oauth2.TokenResponse) => {
            if (!response?.error) {
              this.promptLogin();
            }
            this.response = response;
          },
        });
        break;
    }
  }

  /* Initialise the Google Accounts ID API - this returns us the Google Account information */
  public initAccount(action: GoogleDriveActions, googleAccount?: string): void {
    google.accounts.id.initialize({
      client_id: this.environmentService.getEnvironment().google.clientId,
      auto_select: true,
      login_hint: googleAccount || '',
      use_fedcm_for_prompt: true,
      callback: async (response: google.accounts.id.CredentialResponse) => {
        await this.handleCredentialResponse(response, action);
        this.handleAuthClick(this.response, action);
      },
    });

    this.promptRequestToken();
  }

  /* Callback after the API client is loaded. Loads the discovery doc to initialize the API for download functionality. */
  public async initGoogleDriveApi(action: GoogleDriveActions): Promise<void> {
    await gapi.client.init({
      apiKey: this.apiKey,
      discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
      scope:
        action === GoogleDriveActions.Download
          ? this.googleDriveDownloadService.DOWNLOAD_SCOPE
          : this.googleDriveUploadService.UPLOAD_SCOPES,
    });
  }

  /* Sign in the user. */
  public handleAuthClick(response: google.accounts.oauth2.TokenResponse, action: GoogleDriveActions): void {
    if (response?.error !== undefined) {
      throw response;
    }

    this.accessToken = response?.access_token;

    switch (action) {
      case GoogleDriveActions.Download:
        /* Download file */
        this.googleDriveDownloadService.checkScopeThenDownloadFile();
        break;
      case GoogleDriveActions.Upload:
        /* Upload file */
        if (this.googleDriveUploadService.patientId) {
          this.googleDriveUploadService.createPicker(this.accessToken);
        }
        break;
      case GoogleDriveActions.Preview:
        /* Preview file */
        if (this.googleDrivePreviewService.googleFileMoment) {
          this.googleDrivePreviewService.openPreviewDialog();
        }
        break;
      default:
        break;
    }
  }

  //  Triggers consent to get access token
  public promptRequestToken(): void {
    if (this.accessToken === undefined && this.googleUserId === undefined) {
      // Display of account chooser and/ or consent dialog when establishing a new session.
      this.tokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      this.tokenClient.requestAccessToken({ prompt: '' });
    }
  }

  // Triggers login to Google Account ID
  public promptLogin(): void {
    google.accounts.id.prompt();
  }

  // Revoke access token for Google Login
  public revokeAccessTokenConsent(): void {
    google.accounts.id.disableAutoSelect();

    if (this.accessToken) {
      google.accounts.oauth2.revoke(this.accessToken, () => {
        this.accessToken = null;
      });
    }
  }

  // Request permission to access Shared Gogole Drive folder
  public async requestDrivePermissions(role: GoogleDrivePermissionLevel): Promise<void> {
    return await firstValueFrom(this.therapistService.TherapistEnsureGoogleDriveAccountHasPermission(role));
  }

  //  Assign details returned about Google Account to variables
  private async handleCredentialResponse(
    response: google.accounts.id.CredentialResponse,
    action: GoogleDriveActions,
  ): Promise<void> {
    try {
      const responsePayload = jose.decodeJwt(response.credential);

      this.googleUserId = responsePayload.sub;
      this.googleUserEmail = responsePayload?.email as string;

      if (responsePayload?.email) {
        await this.saveTherapistGoogleAccountDetails(
          action === GoogleDriveActions.Upload
            ? GoogleDrivePermissionLevel.Contributor
            : GoogleDrivePermissionLevel.Viewer,
        );
      }
    } catch (error) {
      this.messageService.showMessageAndClose('Unable to parse response - login to Google Account Failed');
      console.error('Unable to parse response - login to Google Account Failed. ', error);
    }
  }

  //  Add Google Account to Therapist account.
  private async saveTherapistGoogleAccountDetails(role: GoogleDrivePermissionLevel): Promise<void> {
    return await firstValueFrom(
      this.therapistService.TherapistAddGoogleDriveAccount({ googleDriveEmail: this.googleUserEmail }),
    )
      .then(async () => {
        if (this.googleUserEmail) {
          await this.requestPermission(role);
        }
      })
      .catch(error => {
        this.messageService.showMessageAndClose('Unable to save Google Account details to your Therapist account.');
        console.error('Unable to save Google Account details to your Therapist account.', error);
      });
  }

  //  Request Permissions to the shared drive as a reader (viewer) or writer (contributor)
  private async requestPermission(role: GoogleDrivePermissionLevel): Promise<void> {
    return await firstValueFrom(this.therapistService.TherapistEnsureGoogleDriveAccountHasPermission(role)).catch(
      error => {
        this.messageService.showMessageAndClose(
          'Failed to provide permission to Google Drive Folder. Please retry logging into your Google Account.',
        );
        console.error('Failed to provide permission to Google Drive Folder. ', error);
      },
    );
  }
}
