import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleNavLink } from './nav-link';

@Pipe({
  name: 'containsActiveLink'
})
export class ContainsActiveLinkPipe implements PipeTransform {

  constructor(private router: Router) {}

  transform(links: SimpleNavLink[]): boolean {
    return links.some(childLink => this.router.isActive(childLink.link, false));
  }
}
