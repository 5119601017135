import { Injectable } from '@angular/core';

import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { TherapistChatService } from '@backend-client/services/therapist-chat.service';
import { TubChatSessionV2 } from '@backend-client/models/tub-chat-session-v2';
import { MessageService } from '@shared/services/message.service';

@Injectable({
  providedIn: 'root'
})
export class ActiveChatSessionsService {

  private readonly SERVER_REQUEST_LIMIT = 5;
  private readonly MINIMUM_RESULTS_TO_DISPLAY = 10;
  private skipCount = 0;
  public isFetchingChats = false;
  public hasFinishedFetchingChats = false;

  public chatSessions$: BehaviorSubject<TubChatSessionV2[]> = new BehaviorSubject([]);

  constructor(private therapistChatService: TherapistChatService,
              private messageService: MessageService) { }

  /**
   * Retrieves the next page of active chat sessions, and emits all currently loaded chats via an observable
   * @param reset If true, resets the paging marker and clears pre-existing chats
   */
  public async getActiveChatSessions(reset?: boolean): Promise<void> {
    if (this.isFetchingChats) {
      return;
    }

    if (reset) {
      this.resetChatSessions();
    }

    if (!this.hasFinishedFetchingChats) {
      this.updateNextChatSessionPage();
    }
  }

  /**
   * Continuously fetches groups of chatSessions from the server, updating the observable as it goes, until it reaches the defined
   * maximum results to display.
   * @private
   */
  private async updateNextChatSessionPage(): Promise<void> {

    let currentPageLength = 0;

    do {
      const chatPage = await this.fetchChatPage();

      // If the chatPage is empty, then we have reached the end of the list
      if (chatPage.length === 0) {
        this.hasFinishedFetchingChats = true;
        break;
      }

      // When paging is requested for chat lists, TUB will disable any filtering of null entries in order to
      // maintain the count (the length of the chat list array will still be equal to the 'limit')
      // So we need to ensure we strip this out before passing chat sessions out to the consumer
      const nonNullChats = chatPage.filter((chatSession: TubChatSessionV2) => chatSession !== null);

      currentPageLength += nonNullChats.length;

      this.chatSessions$.next([ ...this.chatSessions$.getValue(), ...nonNullChats ]);

      this.skipCount += this.SERVER_REQUEST_LIMIT;
    } while (currentPageLength < this.MINIMUM_RESULTS_TO_DISPLAY);

  }

  private async fetchChatPage() {
    try {
      this.isFetchingChats = true;
      return await firstValueFrom(
        this.therapistChatService.TherapistChatsControllerV2ListChats({
          skip: this.skipCount,
          limit: this.SERVER_REQUEST_LIMIT,
        })
      );
    } catch (e) {
      this.messageService.showMessage('Error: Some chats could not be loaded');
      console.error(e);
    } finally {
      this.isFetchingChats = false;
    }
  }

  private resetChatSessions(): void {
    this.skipCount = 0;
    this.hasFinishedFetchingChats = false;
    this.chatSessions$.next([]);
  }
}

