import { Injectable } from '@angular/core';

import { EnvironmentService } from '../environment.service';
import { GoogleDriveFileMoment } from '@app/modules/therapist/assigned-patients/patient-profile/patient-timeline/moment/google-drive-file-moment/google-drive-file-moment';

@Injectable({
  providedIn: 'root'
})

export class GoogleDrivePreviewService {
  public PREVIEW_SCOPES = this.environmentService.getEnvironment().google.previewScope;
  public googleFileMoment: GoogleDriveFileMoment;

  constructor(
    private environmentService: EnvironmentService
  ) {}

  public previewFile(): void {
    window.open(`https://drive.google.com/file/d/${this.googleFileMoment.googleDriveFileId}/view`);
    this.googleFileMoment = undefined;
  }
}
