import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-setup-password',
  templateUrl: './setup-password.component.html',
  styleUrls: [ './setup-password.component.scss' ]
})
export class SetupPasswordComponent implements OnInit {

    @ViewChild('password') passwordField;
  @Output() password: EventEmitter<string> = new EventEmitter();

  public passwordFormGroup: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.passwordFormGroup = this.formBuilder.group({
      passwordFormControl: [ '', [ Validators.required, Validators.minLength(12) ] ],
      passwordConfirmFormControl: [ '', [ Validators.required ] ]
    }, { validator: this.checkIfPasswordsMatch('passwordFormControl', 'passwordConfirmFormControl') });
  }

  private checkIfPasswordsMatch(passwordKey: string, passwordConfirmationKey: string) {
    return (group: UntypedFormGroup) => {
      const passwordInput = group.controls[passwordKey];
      const passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }

  public submitPassword(): void {
    this.password.emit(this.passwordField.nativeElement.value);
  }

}
