<form [formGroup]="oneTimePasswordFormGroup">
  <mat-form-field appearance="outline" class="full-width-input">
    <mat-label>
      {{emailSendStatus === EmailSendStatus.Unsent ? "Authenticator app password" : "Emailed password"}}
    </mat-label>
    <input type="text"
           autocomplete="off"
           #authCode
           matInput
           placeholder="Password"
           formControlName="oneTimePasswordFormControl">
    <mat-hint>
      <span *ngIf="emailSendStatus === EmailSendStatus.Unsent">
        Enter the 6 digit password from your authenticator app
      </span>
      <span *ngIf="emailSendStatus === EmailSendStatus.Successful || emailSendStatus === EmailSendStatus.Sending">
        Enter the 6 digit password that has been sent to your email
      </span>
    </mat-hint>
    <mat-error *ngIf="oneTimePasswordFormGroup.controls['oneTimePasswordFormControl'].hasError('required')">
      One Time Password is <strong>required</strong>
    </mat-error>
    <mat-error *ngIf="oneTimePasswordFormGroup.controls['oneTimePasswordFormControl'].hasError('minlength') ||
                oneTimePasswordFormGroup.controls['oneTimePasswordFormControl'].hasError('maxlength')">
      Your password must be 6 characters long
    </mat-error>
  </mat-form-field>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <button mat-button type="button" (click)="backButtonClicked()">Back</button>
    <button mat-raised-button
            (click)="submitAuthCode(authCode.value)"
            color="primary"
            [disabled]="!oneTimePasswordFormGroup.valid">
      Submit
    </button>
  </div>
</form>
<ng-container *ngIf="shouldDisplayEmail">
  <mat-divider></mat-divider>
  <div id="email-code" fxLayout="column">

    <app-async-button [action]="emailCode.bind(this)">
      <ng-container *ngIf="emailSendStatus === EmailSendStatus.Unsent; else retryEmailContent">
        Email me instead
      </ng-container>

      <ng-template #retryEmailContent>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px">
          <mat-icon>refresh</mat-icon>
          <span>Resend</span>
        </div>
      </ng-template>
    </app-async-button>

    <div id="check-email" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="3px">
      <ng-container *ngIf="emailSendStatus === EmailSendStatus.Sending">
        Sending...
      </ng-container>
      <ng-container *ngIf="emailSendStatus === EmailSendStatus.Successful">
        <mat-icon id="success-icon">done</mat-icon>
        <span>Please check your email for your one time password</span>
      </ng-container>
      <ng-container *ngIf="emailSendStatus === EmailSendStatus.Failed">
        <mat-icon id="fail-icon">close</mat-icon>
        <span>Email not sent, please try again</span>
      </ng-container>
    </div>
  </div>
</ng-container>
