import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { GoogleDriveFileMoment } from '../moment/google-drive-file-moment/google-drive-file-moment';

@Component({
  selector: 'app-preview-file-dialog',
  templateUrl: './preview-file-dialog.component.html',
  styleUrls: [ './preview-file-dialog.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class PreviewFileDialogComponent implements OnInit {

  @ViewChild('dialog', { static: true }) dialog: DialogComponent;
  @ViewChild('content', { static: true }) content: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GoogleDriveFileMoment,
    private dialogRef: MatDialogRef<PreviewFileDialogComponent>
  ) { }

  public ngOnInit() {
    this.previewFile();
  }

  public previewFile() {
    const iframe = document.createElement('iframe');
    iframe.width = '100%';
    iframe.id = 'preview';
    // Disables the Google popout button
    iframe.sandbox.add('allow-scripts');
    iframe.sandbox.add('allow-same-origin');
    // End of disable
    iframe.setAttribute('src', `https://drive.google.com/file/d/${this.data.googleDriveFileId}/preview`);
    this.content.nativeElement.appendChild(iframe);
  }

  public cancelButtonClicked(): void {
    this.dialogRef.close();
  }
}
