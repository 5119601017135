import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  constructor() {}

  public async playAudioMessage(): Promise<void> {
    await this.play('../../../../assets/audio/alert.mp3');
  }

  public async playAudioChatRequest(): Promise<void> {
    await this.play('../../../../assets/audio/two-tone.mp3');
  }

  private play(url): Promise<any> {
    return new Promise(function(resolve, reject) {
      const audio = new Audio();
      audio.autoplay = true;
      audio.onerror = reject;
      audio.onended = resolve;
      audio.src = url;
    });
  }
}
