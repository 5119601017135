import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { LoginStep } from '../login-stepper/login-step.enum';

@Component({
  selector: 'app-totp-stepper-modal',
  templateUrl: './totp-stepper-modal.component.html',
  styleUrls: [ './totp-stepper-modal.component.scss' ]
})
export class TotpStepperModalComponent {

  public LoginStep = LoginStep;

  constructor(private dialogRef: MatDialogRef<TotpStepperModalComponent>) { }

  public closeDialog() {
    // Passing out "true" signifies that the data needs to be refreshed
    this.dialogRef.close(true);
  }

}
