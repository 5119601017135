/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubTherapistDetails } from '../models/tub-therapist-details';
import { TubTherapistBiography } from '../models/tub-therapist-biography';
import { TubTherapistTimezone } from '../models/tub-therapist-timezone';
import { TubTherapistName } from '../models/tub-therapist-name';
import { TherapistAvailability } from '../models/therapist-availability';
import { TubTherapistAvailability } from '../models/tub-therapist-availability';
import { TubTherapistAvailabilities } from '../models/tub-therapist-availabilities';
import { TubTherapistGoogleDriveAccount } from '../models/tub-therapist-google-drive-account';
import { TubTherapistGoogleDriveAccountEmail } from '../models/tub-therapist-google-drive-account-email';
@Injectable({
  providedIn: 'root',
})
class TherapistService extends __BaseService {
  static readonly TherapistGetTherapistDetailsPath = '/therapist/details/';
  static readonly TherapistIsLeadPath = '/therapist/is-lead/{patientId}';
  static readonly TherapistUpdateBiographyPath = '/therapist/biography';
  static readonly TherapistGetTimezonePath = '/therapist/timezone';
  static readonly TherapistUpdateTimezonePath = '/therapist/timezone';
  static readonly TherapistUpdateNamePath = '/therapist/name';
  static readonly TherapistGetAvailabilityPath = '/therapist/availability';
  static readonly TherapistAddAvailabilityPath = '/therapist/availability';
  static readonly TherapistSetAvailabilityPath = '/therapist/availability';
  static readonly TherapistDeleteAvailabilityPath = '/therapist/availability';
  static readonly TherapistGetGoogleDriveAccountPath = '/therapist/google-drive-account';
  static readonly TherapistAddGoogleDriveAccountPath = '/therapist/google-drive-account';
  static readonly TherapistDeleteGoogleDriveAccountPath = '/therapist/google-drive-account';
  static readonly TherapistEnsureGoogleDriveAccountHasPermissionPath = '/therapist/google-drive-account/action/ensure-permissions/{role}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get the details for a specific therapist.
   * @return Ok
   */
  TherapistGetTherapistDetailsResponse(): __Observable<__StrictHttpResponse<TubTherapistDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/therapist/details/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubTherapistDetails>;
      })
    );
  }
  /**
   * Get the details for a specific therapist.
   * @return Ok
   */
  TherapistGetTherapistDetails(): __Observable<TubTherapistDetails> {
    return this.TherapistGetTherapistDetailsResponse().pipe(
      __map(_r => _r.body as TubTherapistDetails)
    );
  }

  /**
   * Returns whether the therapist is the lead for the user requested.
   * @param patientId The patient we want to check
   * @return Ok
   */
  TherapistIsLeadResponse(patientId: string): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/therapist/is-lead/${encodeURIComponent(String(patientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * Returns whether the therapist is the lead for the user requested.
   * @param patientId The patient we want to check
   * @return Ok
   */
  TherapistIsLead(patientId: string): __Observable<boolean> {
    return this.TherapistIsLeadResponse(patientId).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * Replaces the current therapists biography with the passed string
   * @param biography The json object containing the biography to attach to this therapist
   */
  TherapistUpdateBiographyResponse(biography: TubTherapistBiography): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = biography;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/therapist/biography`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Replaces the current therapists biography with the passed string
   * @param biography The json object containing the biography to attach to this therapist
   */
  TherapistUpdateBiography(biography: TubTherapistBiography): __Observable<null> {
    return this.TherapistUpdateBiographyResponse(biography).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Gets timezone for the current therapist
   * @return Ok
   */
  TherapistGetTimezoneResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/therapist/timezone`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Gets timezone for the current therapist
   * @return Ok
   */
  TherapistGetTimezone(): __Observable<string> {
    return this.TherapistGetTimezoneResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Replaces the current therapists timezone with the passed string. Timezone is validated against list of acceptable timezones
   * @param timezone The json object containing the timezone to attach to this therapist
   */
  TherapistUpdateTimezoneResponse(timezone: TubTherapistTimezone): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = timezone;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/therapist/timezone`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Replaces the current therapists timezone with the passed string. Timezone is validated against list of acceptable timezones
   * @param timezone The json object containing the timezone to attach to this therapist
   */
  TherapistUpdateTimezone(timezone: TubTherapistTimezone): __Observable<null> {
    return this.TherapistUpdateTimezoneResponse(timezone).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Replaces the current therapists name with the passed string
   * @param name The json object containing the name to attach to this therapist
   */
  TherapistUpdateNameResponse(name: TubTherapistName): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = name;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/therapist/name`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Replaces the current therapists name with the passed string
   * @param name The json object containing the name to attach to this therapist
   */
  TherapistUpdateName(name: TubTherapistName): __Observable<null> {
    return this.TherapistUpdateNameResponse(name).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Gets availability for the current therapist
   * @return Ok
   */
  TherapistGetAvailabilityResponse(): __Observable<__StrictHttpResponse<Array<TherapistAvailability>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/therapist/availability`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TherapistAvailability>>;
      })
    );
  }
  /**
   * Gets availability for the current therapist
   * @return Ok
   */
  TherapistGetAvailability(): __Observable<Array<TherapistAvailability>> {
    return this.TherapistGetAvailabilityResponse().pipe(
      __map(_r => _r.body as Array<TherapistAvailability>)
    );
  }

  /**
   * Adds new availability values to the therapist object
   * @param availability The json object containing the availability to append to this therapist
   */
  TherapistAddAvailabilityResponse(availability: TubTherapistAvailability): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = availability;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/therapist/availability`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Adds new availability values to the therapist object
   * @param availability The json object containing the availability to append to this therapist
   */
  TherapistAddAvailability(availability: TubTherapistAvailability): __Observable<null> {
    return this.TherapistAddAvailabilityResponse(availability).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Sets the therapist availability to whatever is passed in
   * @param availability The json object containing the availability to assign to this therapist
   */
  TherapistSetAvailabilityResponse(availability: Array<TubTherapistAvailabilities>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = availability;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/therapist/availability`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Sets the therapist availability to whatever is passed in
   * @param availability The json object containing the availability to assign to this therapist
   */
  TherapistSetAvailability(availability: Array<TubTherapistAvailabilities>): __Observable<null> {
    return this.TherapistSetAvailabilityResponse(availability).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Adds new availability values to the therapist object
   * @param availability The json object containing the availability to append to this therapist
   */
  TherapistDeleteAvailabilityResponse(availability: TubTherapistAvailability): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = availability;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/therapist/availability`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Adds new availability values to the therapist object
   * @param availability The json object containing the availability to append to this therapist
   */
  TherapistDeleteAvailability(availability: TubTherapistAvailability): __Observable<null> {
    return this.TherapistDeleteAvailabilityResponse(availability).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Fetches Google Drive Account details for therapist
   * @return Ok
   */
  TherapistGetGoogleDriveAccountResponse(): __Observable<__StrictHttpResponse<TubTherapistGoogleDriveAccount>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/therapist/google-drive-account`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubTherapistGoogleDriveAccount>;
      })
    );
  }
  /**
   * Fetches Google Drive Account details for therapist
   * @return Ok
   */
  TherapistGetGoogleDriveAccount(): __Observable<TubTherapistGoogleDriveAccount> {
    return this.TherapistGetGoogleDriveAccountResponse().pipe(
      __map(_r => _r.body as TubTherapistGoogleDriveAccount)
    );
  }

  /**
   * Adds new Google Drive Account details to the therapist object
   * @param googleDriveAccount The json object containing the Google Drive Account details to append to this therapist
   */
  TherapistAddGoogleDriveAccountResponse(googleDriveAccount: TubTherapistGoogleDriveAccountEmail): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = googleDriveAccount;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/therapist/google-drive-account`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Adds new Google Drive Account details to the therapist object
   * @param googleDriveAccount The json object containing the Google Drive Account details to append to this therapist
   */
  TherapistAddGoogleDriveAccount(googleDriveAccount: TubTherapistGoogleDriveAccountEmail): __Observable<null> {
    return this.TherapistAddGoogleDriveAccountResponse(googleDriveAccount).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Removes Google Drive Account details from the therapist object
   */
  TherapistDeleteGoogleDriveAccountResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/therapist/google-drive-account`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Removes Google Drive Account details from the therapist object
   */
  TherapistDeleteGoogleDriveAccount(): __Observable<null> {
    return this.TherapistDeleteGoogleDriveAccountResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Adds new Google Drive Account details to the therapist object
   * @param role
   */
  TherapistEnsureGoogleDriveAccountHasPermissionResponse(role: 'reader' | 'writer'): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/therapist/google-drive-account/action/ensure-permissions/${encodeURIComponent(String(role))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Adds new Google Drive Account details to the therapist object
   * @param role
   */
  TherapistEnsureGoogleDriveAccountHasPermission(role: 'reader' | 'writer'): __Observable<null> {
    return this.TherapistEnsureGoogleDriveAccountHasPermissionResponse(role).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module TherapistService {
}

export { TherapistService }
