import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: [ './success.component.scss' ]
})
export class SuccessComponent implements OnInit {

  @Input() continueUrl: string;

  public relativeUrl: string;

  ngOnInit() {
    const origin = window.location.origin;
    this.relativeUrl = this.continueUrl.replace(origin, '');
  }

}
