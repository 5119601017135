/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubAccessCode } from '../models/tub-access-code';
import { TubAccessCodeParams } from '../models/tub-access-code-params';
import { TubGetAccessCodesResponse } from '../models/tub-get-access-codes-response';
import { TubSubscriptionValid } from '../models/tub-subscription-valid';
@Injectable({
  providedIn: 'root',
})
class AccessCodesService extends __BaseService {
  static readonly AccessCodeCreatePath = '/access-code';
  static readonly AccessCodeApplyPath = '/access-code/{accessCode}/apply';
  static readonly AccessCodeEnablePath = '/access-code/{accessCode}/enable';
  static readonly AccessCodeDisablePath = '/access-code/{accessCode}/disable';
  static readonly AccessCodeGetPath = '/access-code/{accessCode}';
  static readonly AccessCodeDeletePath = '/access-code/{accessCode}';
  static readonly AccessCodeGetForOrganisationPath = '/access-code/list/{organisationID}';
  static readonly AccessCodeIsValidPath = '/access-code/{accessCode}/valid';
  static readonly AccessCodeUpdatePath = '/access-code/{accessCode}/update';
  static readonly AccessCodeSearchPath = '/access-code/action/search';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Create a new access code.
   * @param tubAccessCodeParams
   * @return Ok
   */
  AccessCodeCreateResponse(tubAccessCodeParams: TubAccessCodeParams): __Observable<__StrictHttpResponse<TubAccessCode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = tubAccessCodeParams;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/access-code`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubAccessCode>;
      })
    );
  }
  /**
   * Create a new access code.
   * @param tubAccessCodeParams
   * @return Ok
   */
  AccessCodeCreate(tubAccessCodeParams: TubAccessCodeParams): __Observable<TubAccessCode> {
    return this.AccessCodeCreateResponse(tubAccessCodeParams).pipe(
      __map(_r => _r.body as TubAccessCode)
    );
  }

  /**
   * @param params The `AccessCodesService.AccessCodeApplyParams` containing the following parameters:
   *
   * - `accessCode`:
   *
   * - `multiRegion`:
   */
  AccessCodeApplyResponse(params: AccessCodesService.AccessCodeApplyParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.multiRegion != null) __params = __params.set('multiRegion', params.multiRegion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/access-code/${encodeURIComponent(String(params.accessCode))}/apply`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AccessCodesService.AccessCodeApplyParams` containing the following parameters:
   *
   * - `accessCode`:
   *
   * - `multiRegion`:
   */
  AccessCodeApply(params: AccessCodesService.AccessCodeApplyParams): __Observable<null> {
    return this.AccessCodeApplyResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `AccessCodesService.AccessCodeEnableParams` containing the following parameters:
   *
   * - `accessCode`:
   *
   * - `ETag`:
   */
  AccessCodeEnableResponse(params: AccessCodesService.AccessCodeEnableParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/access-code/${encodeURIComponent(String(params.accessCode))}/enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AccessCodesService.AccessCodeEnableParams` containing the following parameters:
   *
   * - `accessCode`:
   *
   * - `ETag`:
   */
  AccessCodeEnable(params: AccessCodesService.AccessCodeEnableParams): __Observable<null> {
    return this.AccessCodeEnableResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `AccessCodesService.AccessCodeDisableParams` containing the following parameters:
   *
   * - `accessCode`:
   *
   * - `ETag`:
   */
  AccessCodeDisableResponse(params: AccessCodesService.AccessCodeDisableParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/access-code/${encodeURIComponent(String(params.accessCode))}/disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AccessCodesService.AccessCodeDisableParams` containing the following parameters:
   *
   * - `accessCode`:
   *
   * - `ETag`:
   */
  AccessCodeDisable(params: AccessCodesService.AccessCodeDisableParams): __Observable<null> {
    return this.AccessCodeDisableResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get a specific access code.
   * @param accessCode Access code firestore ID.
   * @return Ok
   */
  AccessCodeGetResponse(accessCode: string): __Observable<__StrictHttpResponse<TubAccessCode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/access-code/${encodeURIComponent(String(accessCode))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubAccessCode>;
      })
    );
  }
  /**
   * Get a specific access code.
   * @param accessCode Access code firestore ID.
   * @return Ok
   */
  AccessCodeGet(accessCode: string): __Observable<TubAccessCode> {
    return this.AccessCodeGetResponse(accessCode).pipe(
      __map(_r => _r.body as TubAccessCode)
    );
  }

  /**
   * Delete an access code.
   * @param params The `AccessCodesService.AccessCodeDeleteParams` containing the following parameters:
   *
   * - `accessCode`: Access code firestore ID.
   *
   * - `ETag`: arbitrary string received alongside the data when performing a get, used to ensure integrity
   */
  AccessCodeDeleteResponse(params: AccessCodesService.AccessCodeDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/access-code/${encodeURIComponent(String(params.accessCode))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete an access code.
   * @param params The `AccessCodesService.AccessCodeDeleteParams` containing the following parameters:
   *
   * - `accessCode`: Access code firestore ID.
   *
   * - `ETag`: arbitrary string received alongside the data when performing a get, used to ensure integrity
   */
  AccessCodeDelete(params: AccessCodesService.AccessCodeDeleteParams): __Observable<null> {
    return this.AccessCodeDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get all access codes for an organisation.
   * @param params The `AccessCodesService.AccessCodeGetForOrganisationParams` containing the following parameters:
   *
   * - `pageSize`: The maximum amount of results to return
   *
   * - `organisationID`: The firestore ID of the organisation
   *
   * - `lastPage`: The document path of the last id for paging
   *
   * @return Ok
   */
  AccessCodeGetForOrganisationResponse(params: AccessCodesService.AccessCodeGetForOrganisationParams): __Observable<__StrictHttpResponse<TubGetAccessCodesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());

    if (params.lastPage != null) __params = __params.set('lastPage', params.lastPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/access-code/list/${encodeURIComponent(String(params.organisationID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubGetAccessCodesResponse>;
      })
    );
  }
  /**
   * Get all access codes for an organisation.
   * @param params The `AccessCodesService.AccessCodeGetForOrganisationParams` containing the following parameters:
   *
   * - `pageSize`: The maximum amount of results to return
   *
   * - `organisationID`: The firestore ID of the organisation
   *
   * - `lastPage`: The document path of the last id for paging
   *
   * @return Ok
   */
  AccessCodeGetForOrganisation(params: AccessCodesService.AccessCodeGetForOrganisationParams): __Observable<TubGetAccessCodesResponse> {
    return this.AccessCodeGetForOrganisationResponse(params).pipe(
      __map(_r => _r.body as TubGetAccessCodesResponse)
    );
  }

  /**
   * check the validity of an access code
   *
   * Determines whether an access code is valid.  Additionally, if the access code if valid,
   * whether it has any remaining redemptions available.
   * @param params The `AccessCodesService.AccessCodeIsValidParams` containing the following parameters:
   *
   * - `accessCode`: the access code to check
   *
   * - `multiRegion`: whether to check the access code for multi-region validity (optional, defaults to false)
   *
   * @return Ok
   */
  AccessCodeIsValidResponse(params: AccessCodesService.AccessCodeIsValidParams): __Observable<__StrictHttpResponse<TubSubscriptionValid>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.multiRegion != null) __params = __params.set('multiRegion', params.multiRegion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/access-code/${encodeURIComponent(String(params.accessCode))}/valid`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubSubscriptionValid>;
      })
    );
  }
  /**
   * check the validity of an access code
   *
   * Determines whether an access code is valid.  Additionally, if the access code if valid,
   * whether it has any remaining redemptions available.
   * @param params The `AccessCodesService.AccessCodeIsValidParams` containing the following parameters:
   *
   * - `accessCode`: the access code to check
   *
   * - `multiRegion`: whether to check the access code for multi-region validity (optional, defaults to false)
   *
   * @return Ok
   */
  AccessCodeIsValid(params: AccessCodesService.AccessCodeIsValidParams): __Observable<TubSubscriptionValid> {
    return this.AccessCodeIsValidResponse(params).pipe(
      __map(_r => _r.body as TubSubscriptionValid)
    );
  }

  /**
   * @param params The `AccessCodesService.AccessCodeUpdateParams` containing the following parameters:
   *
   * - `accessCode`:
   *
   * - `ETag`:
   *
   * - `max-users`:
   */
  AccessCodeUpdateResponse(params: AccessCodesService.AccessCodeUpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    if (params.maxUsers != null) __params = __params.set('max-users', params.maxUsers.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/access-code/${encodeURIComponent(String(params.accessCode))}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AccessCodesService.AccessCodeUpdateParams` containing the following parameters:
   *
   * - `accessCode`:
   *
   * - `ETag`:
   *
   * - `max-users`:
   */
  AccessCodeUpdate(params: AccessCodesService.AccessCodeUpdateParams): __Observable<null> {
    return this.AccessCodeUpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Finds all access codes containing the search term.
   * made unavailable except to admins as will be replaced/heavily refactored in the future
   * @param term The search term
   * @return Ok
   */
  AccessCodeSearchResponse(term: string): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (term != null) __params = __params.set('term', term.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/access-code/action/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * Finds all access codes containing the search term.
   * made unavailable except to admins as will be replaced/heavily refactored in the future
   * @param term The search term
   * @return Ok
   */
  AccessCodeSearch(term: string): __Observable<Array<string>> {
    return this.AccessCodeSearchResponse(term).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }
}

module AccessCodesService {

  /**
   * Parameters for AccessCodeApply
   */
  export interface AccessCodeApplyParams {
    accessCode: string;
    multiRegion?: boolean;
  }

  /**
   * Parameters for AccessCodeEnable
   */
  export interface AccessCodeEnableParams {
    accessCode: string;
    ETag: string;
  }

  /**
   * Parameters for AccessCodeDisable
   */
  export interface AccessCodeDisableParams {
    accessCode: string;
    ETag: string;
  }

  /**
   * Parameters for AccessCodeDelete
   */
  export interface AccessCodeDeleteParams {

    /**
     * Access code firestore ID.
     */
    accessCode: string;

    /**
     * arbitrary string received alongside the data when performing a get, used to ensure integrity
     */
    ETag: string;
  }

  /**
   * Parameters for AccessCodeGetForOrganisation
   */
  export interface AccessCodeGetForOrganisationParams {

    /**
     * The maximum amount of results to return
     */
    pageSize: number;

    /**
     * The firestore ID of the organisation
     */
    organisationID: string;

    /**
     * The document path of the last id for paging
     */
    lastPage?: string;
  }

  /**
   * Parameters for AccessCodeIsValid
   */
  export interface AccessCodeIsValidParams {

    /**
     * the access code to check
     */
    accessCode: string;

    /**
     * whether to check the access code for multi-region validity (optional, defaults to false)
     */
    multiRegion?: boolean;
  }

  /**
   * Parameters for AccessCodeUpdate
   */
  export interface AccessCodeUpdateParams {
    accessCode: string;
    ETag: string;
    maxUsers?: number;
  }
}

export { AccessCodesService }
