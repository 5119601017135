<form [formGroup]="passwordFormGroup">
  <mat-form-field appearance="outline" class="full-width-input">
    <mat-label>Password</mat-label>
    <mat-pass-toggle-visibility #togglePassword matSuffix></mat-pass-toggle-visibility>
    <input #password matInput type="password" placeholder="Password" formControlName="passwordFormControl"
           [type]="togglePassword.type" autofocus>
    <mat-hint align="end" aria-live="polite">
      {{password.value.length}} / 12
    </mat-hint>
    <mat-error *ngIf="passwordFormGroup.controls['passwordFormControl'].hasError('minlength')">
      Password is only {{password.value.length}} / 12 characters long
    </mat-error>
    <mat-error *ngIf="passwordFormGroup.controls['passwordFormControl'].hasError('required')">
      Password is <strong>required</strong>
    </mat-error>
  </mat-form-field>
  <mat-password-strength [password]="password.value" min="12"></mat-password-strength>
  <br>
  <mat-form-field appearance="outline" class="full-width-input">
    <mat-label>Confirm Password</mat-label>
    <mat-pass-toggle-visibility #togglePasswordConfirm matSuffix></mat-pass-toggle-visibility>
    <input matInput type="password" placeholder="Confirm Password" formControlName="passwordConfirmFormControl"
           [type]="togglePasswordConfirm.type">
    <mat-error *ngIf="passwordFormGroup.controls['passwordConfirmFormControl'].hasError('notEquivalent')">
      Passwords do not match
    </mat-error>
    <mat-error *ngIf="passwordFormGroup.controls['passwordConfirmFormControl'].hasError('minlength')">
      Your password should be at least 12 characters long
    </mat-error>
  </mat-form-field>
  <br>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div></div>
    <button type="submit" color="primary" mat-raised-button [disabled]="!passwordFormGroup.valid" (click)="submitPassword()">
      Next
    </button>
  </div>
</form>
