import { Injectable } from '@angular/core';

import { EnvironmentService } from '../shared/services/environment.service';
import { TimeBasedOneTimePasswordsMultiFactorAuthenticationService } from '@backend-client/services/time-based-one-time-passwords-multi-factor-authentication.service';
import { TubTotpMfaSecret } from '@backend-client/models/tub-totp-mfa-secret';

@Injectable({
  providedIn: 'root'
})
export class TotpService {

  constructor(
    private timeBasedOneTimePasswordService: TimeBasedOneTimePasswordsMultiFactorAuthenticationService,
    private environmentService: EnvironmentService
  ) { }

  /**
   * Returns all Totps currently assigned to the currently logged in account
   */
  public async getTotpMethodsForAccount(): Promise<TubTotpMfaSecret[]> {
    return await this.timeBasedOneTimePasswordService.TotpMfaGetRegisteredSecrets().toPromise();
  }

  public async removeTotpMethod(id: string) {
    return await this.timeBasedOneTimePasswordService.TotpMfaDeleteRegisteredSecret(id).toPromise();
  }

  public async generateUrl(email: string, sharedSecret: string): Promise<string> {
    const provider = `Thrive ${this.environmentService.isAustralia() ? 'AUS' : 'UK'}: `;
    return `otpauth://totp/${encodeURIComponent(provider + email)}?secret=` + sharedSecret;
  }

  public async relabelTotpMethod(id: string, label: string): Promise<void> {
    return await this.timeBasedOneTimePasswordService.TotpMfaRelabelRegisteredSecret({ id, body: { label } }).toPromise();
  }
}
