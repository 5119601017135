export enum BootState {
    initCore,
    initRepos,
    initConfig,
    initKeys,
    initAuth,
    requireAuth,
    awaitUserProfile,
    initChat,
    complete,
    error,
}