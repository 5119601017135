import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';

import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, publishReplay, refCount, switchMap } from 'rxjs/operators';

import { RealtimeDatabaseChatNotificationCacheUser } from './classes/realtime-database-chat-notification-cache';

@Injectable({
  providedIn: 'root'
})
export class ChatNotificationCacheService {

  constructor(
    private angularFireAuth: AngularFireAuth,
    private angularFireDatabase: AngularFireDatabase
  ) { }

  public chatHistory: BehaviorSubject<RealtimeDatabaseChatNotificationCacheUser> = new BehaviorSubject<RealtimeDatabaseChatNotificationCacheUser>({});
  private chatNotificationCacheUser$: Observable<RealtimeDatabaseChatNotificationCacheUser>;

  /**
   * The full cache for all chat sessions the active user is in, in a single document.
   */
  public getChatNotificationCache$(): Observable<RealtimeDatabaseChatNotificationCacheUser> {
    if (!this.chatNotificationCacheUser$) {
      this.chatNotificationCacheUser$ = this.angularFireAuth.user.pipe(
        filter(user => user != null),
        switchMap(user => {
          return this.angularFireDatabase
            .object<RealtimeDatabaseChatNotificationCacheUser>(`/chatNotificationCache/${user.uid}`)
            .valueChanges();
        }),
        publishReplay(1),
        refCount(),
        catchError(err => {
          if (err.code === 'PERMISSION_DENIED') {
            return EMPTY;
          } else {
            return throwError(err);
          }
        })
      );
    }
    return this.chatNotificationCacheUser$;
  }

}
