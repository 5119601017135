import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private dialog: MatDialog,
    public overlay: Overlay
  ) { }

  public openDialog(
    component,
    data: any = {},
    settings: {
      large?: boolean,
      disableClose?: boolean,
      panelClass?: string,
      ignoreWidth?: boolean,
    } = {}
  ): MatDialogRef<any> {
    return this.dialog.open(component, {
       panelClass: settings?.panelClass || '',
       autoFocus: true,
       disableClose: settings.disableClose !== undefined ? settings.disableClose : true,
       width: settings?.ignoreWidth ? 'auto' : '100%',
       maxWidth: settings?.ignoreWidth ? 'auto' : settings?.large ? '800px' : '500px',
       maxHeight: '90vh',
       data,
       scrollStrategy: this.overlay.scrollStrategies.noop()
     });
  }

  public closeAllDialogs(): void {
    this.dialog.closeAll();
  }
}
