import { Component, Input } from '@angular/core';
import { NavLink } from './nav-link';

@Component({
  selector: 'app-nav-link',
  templateUrl: './nav-link.component.html',
  styleUrls: [ './nav-link.component.scss' ]
})
export class NavLinkComponent {

  @Input() linkData: NavLink;
}
