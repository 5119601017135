// go-chat-duplication
import { Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-go-unrecoverable-error-dialog',
  templateUrl: './go-unrecoverable-error-dialog.component.html',
  styleUrls: ['./go-unrecoverable-error-dialog.component.scss']
})
export class GoUnrecoverableErrorDialogComponent {

  @ViewChild('dialog') dialog;

  constructor(
    private dialogRef: MatDialogRef<GoUnrecoverableErrorDialogComponent>) { }

  public async confirmButtonClicked(): Promise<void> {
    await this.dialog.showAsyncProgressForFunction(async () => {});
    // Nothing for us to do but reload the application when gocore has exited.
    window.location.reload();
  }

}
