import { PubNubActiveChannel } from '@thrivesoft/gocore-web';
import { ChatUserModel } from './chat-user-model';

export class ChatActiveChannelModel {
    constructor(
        public channelName: string,
        public occupancy: number,
        public users: Array<ChatUserModel>
    ) { }

    static fromProto(proto: PubNubActiveChannel) {
        return new ChatActiveChannelModel(
            proto.ChannelName,
            proto.Occupancy,
            ChatUserModel.list(proto.Users)
        );
    }

    static toProto(model: ChatActiveChannelModel): PubNubActiveChannel {
        return new PubNubActiveChannel({
            ChannelName: model.channelName,
            Occupancy: model.occupancy,
            Users: model.users.map((e) => ChatUserModel.toProto(e))
        });
    }

    static list(list: Array<PubNubActiveChannel>) {
        return list.map((c) => ChatActiveChannelModel.fromProto(c));
    }
}