import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-has-got-authenticator',
  templateUrl: './has-got-authenticator.component.html',
  styleUrls: [ './has-got-authenticator.component.scss' ]
})
export class HasGotAuthenticatorComponent {

  @Output() hasGotAuthenticator: EventEmitter<boolean> = new EventEmitter();

}
