import { Component } from '@angular/core';

@Component({
  selector: 'app-firebase-recover-email',
  templateUrl: './firebase-recover-email.component.html',
  styleUrls: ['./firebase-recover-email.component.scss']
})
export class FirebaseRecoverEmailComponent {

  constructor() { }

}
