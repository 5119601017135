import { PubNubTherapySession } from '@thrivesoft/gocore-web';

export class ChatSessionModel {
    constructor(
        public start: string,
        public end: string,
        public therapist: string
    ) { }

    static fromProto(proto: PubNubTherapySession) {
        return new ChatSessionModel(
            proto.Start,
            proto.End,
            proto.Therapist
        );
    }

    static toProto(model: ChatSessionModel): PubNubTherapySession {
        return new PubNubTherapySession({
            Start: model.start,
            End: model.end,
            Therapist: model.therapist
        });
    }

    static list(sessions: Array<PubNubTherapySession>): Array<ChatSessionModel> {
        return sessions.map((c) => ChatSessionModel.fromProto(c));
    }
}