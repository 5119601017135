import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../environments/environment';
import { AuthenticationCookieService } from './authentication-cookie.service';
import { catchError, switchMap } from 'rxjs/operators';
import { MessageService } from '../app/modules/shared/services/message.service';
import { TubErrorReportingService } from '../app/modules/shared/services/tub-error-reporting.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../app/modules/authentication/authentication.service';
import { ApiHttpErrorResponse } from './api-http-error-response';

/**
 * ApiInterceptor is responsible for inserting the Authorization Bearer token into all requests bound for TUB
 */
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private authenticationCookieService: AuthenticationCookieService,
              private authenticationService: AuthenticationService,
              private messageService: MessageService,
              private tubErrorReportingService: TubErrorReportingService,
              private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // ensure that setHeaders with the Authorization Bearer token is enabled for all HttpRequests going to the thrive unified backend
    if (req.url.startsWith(environment.thriveUnifiedBackend.rootUrl)) {

      // define an observable to actually return - in the event of a successful request, this will pass the output of the underlying
      // next.handle directly, otherwise it passes the output of a new replacement HttpRequest which we perform after successful login
      const outputObservable = new Observable<HttpEvent<any>>();

      // populate the request with the session cookie
      req = this.getAuthenticatedRequest(req);

      // perform the request as modified to include the session cookie
      return next.handle(req)
        .pipe(
          catchError((err: ApiHttpErrorResponse) => {
            if (err instanceof HttpErrorResponse) {
              console.error('ApiInterceptor HttpErrorResponse', err.status, err.error, err);

              // extract the first line of the error response - this is the usable error string to distinguish between, for example,
              // different flavours of 400 errors
              if (typeof err.error === 'string') {
                err.tubErrorToken = err.error.split('\n').map(error => error.trim())[0];
              }

              switch (err.status) {
                case 400:
                    return throwError(err);
                case 401:
                  if (err.tubErrorToken !== 'MfaRequiredError') {
                    return this.authenticationService.openAuthenticationDialog().pipe(
                      switchMap(() => {
                        // populate the original request with the latest session cookie
                        req = this.getAuthenticatedRequest(req);
                        return this.intercept(req, next);
                      }));
                  } else {
                    return throwError(err);
                  }
                case 409: // ConflictError (should be communicated to the user in context)
                  return throwError(err);
                case 404:
                  this.messageService.showMessage('Error: Resource not found');
                  return throwError(err);
                case 403: // ForbiddenError (should be communicated to the user in context)
                  return throwError(err);
                default:
                  this.tubErrorReportingService.send('Unexpected error code received from tub', err, {req});
                  this.messageService.showMessage('Error: An unknown error has occurred.  If the problem persists, then please contact support');
                  return throwError(err);
              }
            } else {
              return throwError(err);
            }
          })
        );
    } else {
      return next.handle(req);
    }
  }

  private getAuthenticatedRequest(req: HttpRequest<any>): HttpRequest<any> {
    const sessionCookieToken = this.authenticationCookieService.getSessionCookie();
    if (sessionCookieToken) {
      req = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + sessionCookieToken
        }
      });
    }
    return req;
  }
}
