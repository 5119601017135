import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { convertDateToTime, convertMillisecondsToString, convertStringToMilliseconds } from './utils/date-time';
import { userFriendlyList } from './utils/userFriendlyList';
import { getEmailFromQueryString } from './utils/email';
import { mapObjectPropertiesToFormInputs, resetForm } from './utils/angular-forms';
import { formatImageLink } from './utils/image';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router) { }

  /**
   * Image links from the server only contain a partial URL, this function converts them to a full URL that can be used to retrieve the
   * image.
   * @param imageLink link to convert
   */
  public static formatImageLink(imageLink: string): string {
    return formatImageLink(imageLink);
  }

  public getEmailFromQueryString(): string {
    return getEmailFromQueryString(this.activatedRoute);
  }

  public urlbase64decode(str: string): string {
    return decodeURIComponent(atob(str));
  }

  public async delay(ms) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  }

  // TODO: Assess whether this is needed in the future
  // Clears a form and resets the validators
  // Currently a bug in FormGroup reset: https://github.com/angular/material2/issues/4190
  // Workaround implemented: https://stackoverflow.com/questions/48216330/angular-5-formgroup-reset-doesnt-reset-validators
  public resetForm(form): void {
    resetForm(form);
  }

  // Checks if email is valid using Angular validation
  public isEmailAddress(value: string): boolean {
    const emailControl = new FormControl(value, [Validators.email]);
    return !emailControl.hasError('email');
  }

  public convertDateToTime(date: Date, is24Hours?: boolean) {
    return convertDateToTime(date, is24Hours);
  }

  public mapObjectPropertiesToFormInputs(object: any, form: UntypedFormGroup): void {
    return mapObjectPropertiesToFormInputs(object, form);
  }

  /**
   * Takes an array of strings and outputs them in a user friendly format:
   * ['foo', 'bar', 'cat] => 'foo, bar and cat'
   * @param array An array of strings to concatenate
   */
  public userFriendlyList(list: string[]): string {
    return userFriendlyList(list);
  }

  /**
   * Converts milliseconds into a time in the format 'HH:MM'.
   *
   * @param milliseconds The time from midnight in milliseconds
   */
  public convertMillisecondsToString(milliseconds: number): string {
    return convertMillisecondsToString(milliseconds);
  }

  /**
   * Calculate the number of milliseconds since midnight for a string in the format 'HH:MM:'
   *
   * @param stringTime The time to parse
   */
  public convertStringToMilliseconds(stringTime: string): number {
    return convertStringToMilliseconds(stringTime);
  }

  /**
   * Reloads the route to refresh any data in the resolvers.
   * This effectively "reloads" the page without having to re-download all the chunks
   *
   * @param targetRoute Optional url route to target, if none is specified, the current page will be reloaded
   */
  public reloadRoute(targetRoute?: string): void {
    const currentUrl = targetRoute || this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
}
