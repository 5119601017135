import { Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { MessageService } from '@shared/services/message.service';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { FeedbackFormService } from './feedback-form.service';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: [ './feedback-form.component.scss' ]
})
export class FeedbackFormComponent {

  @ViewChild('dialog') dialog: DialogComponent;

  constructor(private dialogRef: MatDialogRef<FeedbackFormComponent>,
              private messageService: MessageService,
              private feedbackFormService: FeedbackFormService) { }

  public async confirmButtonClicked(feedback: string): Promise<void> {
    try {
      await this.dialog.showAsyncProgressForFunction(async () => await this.feedbackFormService.postFeedback(feedback));
      this.messageService.showMessage('Your feedback has been sent, thank you!');
    } catch (err) {
      this.messageService.showMessage(err);
    }
    this.dialogRef.close();
  }

  public cancelButtonClicked(): void {
    this.dialogRef.close();
  }

}
