import { Moment } from '../moment';
import { MomentType } from '../moment-type';

export class GoogleDriveFileMoment extends Moment {
  readonly typeName = MomentType.GoogleFile;
  public googleDriveFileId: string;
  public googleDriveFolderId: string;
  public fileName: string;
  public contentType: string;
  public description: string;

  constructor(options: {
    id: string;
    timestamp: Date;
    createdByUserId: number;
    author: string;
    googleDriveFileId: string;
    googleDriveFolderId: string;
    fileName: string;
    contentType: string;
    description: string;
  }) {
    super(options.id, options.timestamp, options.author);
    this.googleDriveFileId = options.googleDriveFileId;
    this.googleDriveFolderId = options.googleDriveFolderId;
    this.fileName = options.fileName;
    this.contentType = options.contentType;
    this.description = options?.description;
  }
}
