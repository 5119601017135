<app-authentication-container #prompt>
  <mat-horizontal-stepper #stepper (selectionChange)="stepChange($event)">
    <mat-step>
      <app-setup-password (password)="resetPassword($event)"></app-setup-password>
    </mat-step>
    <mat-step>
      <app-firebase-recover-email></app-firebase-recover-email>
    </mat-step>
    <mat-step>
      <app-firebase-verify-email></app-firebase-verify-email>
    </mat-step>
    <mat-step>
      <app-success [continueUrl]="continueUrl"></app-success>
    </mat-step>
  </mat-horizontal-stepper>
</app-authentication-container>
