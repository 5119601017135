/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubChatWriteResponse } from '../models/tub-chat-write-response';
import { TubChatNewMessageParameters } from '../models/tub-chat-new-message-parameters';
import { TubChatEditMessageParameters } from '../models/tub-chat-edit-message-parameters';
import { TubChatUserPresenceInfo } from '../models/tub-chat-user-presence-info';
@Injectable({
  providedIn: 'root',
})
class ChatsV2Service extends __BaseService {
  static readonly ChatsControllerV2SendChatMessagePath = '/v2/chats/{chatRoomId}/messages';
  static readonly ChatsControllerV2EditChatMessagePath = '/v2/chats/{chatRoomId}/messages/{messageId}';
  static readonly ChatsControllerV2DeleteChatMessagePath = '/v2/chats/{chatRoomId}/messages/{messageId}';
  static readonly ChatsControllerV2GetChatParticipantsPath = '/v2/chats/{chatRoomId}/participants';
  static readonly ChatsControllerV2MarkAsReadPath = '/v2/chats/{chatRoomId}/action/mark-as-read';
  static readonly ChatsControllerV2MarkAsTypingPath = '/v2/chats/{chatRoomId}/action/mark-as-typing';
  static readonly ChatsControllerV2MarkAsOnlinePath = '/v2/chats/action/mark-as-online';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * send message into chat room
   *
   * Send the given message into the chat session specified, as the authenticated user.
   * @param params The `ChatsV2Service.ChatsControllerV2SendChatMessageParams` containing the following parameters:
   *
   * - `messageParameters`: request data, including text body of message to send, and reply parameters
   *
   * - `chatRoomId`: id of chat session for which to send message, or "patient-chat" for the patient's own active chat session
   *
   * @return Ok
   */
  ChatsControllerV2SendChatMessageResponse(params: ChatsV2Service.ChatsControllerV2SendChatMessageParams): __Observable<__StrictHttpResponse<TubChatWriteResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.messageParameters;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/v2/chats/${encodeURIComponent(String(params.chatRoomId))}/messages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubChatWriteResponse>;
      })
    );
  }
  /**
   * send message into chat room
   *
   * Send the given message into the chat session specified, as the authenticated user.
   * @param params The `ChatsV2Service.ChatsControllerV2SendChatMessageParams` containing the following parameters:
   *
   * - `messageParameters`: request data, including text body of message to send, and reply parameters
   *
   * - `chatRoomId`: id of chat session for which to send message, or "patient-chat" for the patient's own active chat session
   *
   * @return Ok
   */
  ChatsControllerV2SendChatMessage(params: ChatsV2Service.ChatsControllerV2SendChatMessageParams): __Observable<TubChatWriteResponse> {
    return this.ChatsControllerV2SendChatMessageResponse(params).pipe(
      __map(_r => _r.body as TubChatWriteResponse)
    );
  }

  /**
   * Edit the message with the given messageId, in the chat session specified, with the author of the edit as the authenticated user.
   * @param params The `ChatsV2Service.ChatsControllerV2EditChatMessageParams` containing the following parameters:
   *
   * - `messageParameters`: the edit payload, which contains a string to replace the existing message string
   *
   * - `messageId`: id of individual message event to edit
   *
   * - `chatRoomId`: id of chat session for which to edit message, or "patient-chat" for the patient's own active chat session
   *
   * @return Ok
   */
  ChatsControllerV2EditChatMessageResponse(params: ChatsV2Service.ChatsControllerV2EditChatMessageParams): __Observable<__StrictHttpResponse<TubChatWriteResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.messageParameters;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/v2/chats/${encodeURIComponent(String(params.chatRoomId))}/messages/${encodeURIComponent(String(params.messageId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubChatWriteResponse>;
      })
    );
  }
  /**
   * Edit the message with the given messageId, in the chat session specified, with the author of the edit as the authenticated user.
   * @param params The `ChatsV2Service.ChatsControllerV2EditChatMessageParams` containing the following parameters:
   *
   * - `messageParameters`: the edit payload, which contains a string to replace the existing message string
   *
   * - `messageId`: id of individual message event to edit
   *
   * - `chatRoomId`: id of chat session for which to edit message, or "patient-chat" for the patient's own active chat session
   *
   * @return Ok
   */
  ChatsControllerV2EditChatMessage(params: ChatsV2Service.ChatsControllerV2EditChatMessageParams): __Observable<TubChatWriteResponse> {
    return this.ChatsControllerV2EditChatMessageResponse(params).pipe(
      __map(_r => _r.body as TubChatWriteResponse)
    );
  }

  /**
   * Delete the message with the given messageId, in the chat session specified, with the author of the delete as the authenticated user.
   * @param params The `ChatsV2Service.ChatsControllerV2DeleteChatMessageParams` containing the following parameters:
   *
   * - `messageId`: id of individual message event to delete
   *
   * - `clientTs`: the client's reported time of the delete event
   *
   * - `chatRoomId`: id of chat session for which to delete message, or "patient-chat" for the patient's own active chat session
   *
   * @return Ok
   */
  ChatsControllerV2DeleteChatMessageResponse(params: ChatsV2Service.ChatsControllerV2DeleteChatMessageParams): __Observable<__StrictHttpResponse<TubChatWriteResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.clientTs != null) __params = __params.set('clientTs', params.clientTs.toString());

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/v2/chats/${encodeURIComponent(String(params.chatRoomId))}/messages/${encodeURIComponent(String(params.messageId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubChatWriteResponse>;
      })
    );
  }
  /**
   * Delete the message with the given messageId, in the chat session specified, with the author of the delete as the authenticated user.
   * @param params The `ChatsV2Service.ChatsControllerV2DeleteChatMessageParams` containing the following parameters:
   *
   * - `messageId`: id of individual message event to delete
   *
   * - `clientTs`: the client's reported time of the delete event
   *
   * - `chatRoomId`: id of chat session for which to delete message, or "patient-chat" for the patient's own active chat session
   *
   * @return Ok
   */
  ChatsControllerV2DeleteChatMessage(params: ChatsV2Service.ChatsControllerV2DeleteChatMessageParams): __Observable<TubChatWriteResponse> {
    return this.ChatsControllerV2DeleteChatMessageResponse(params).pipe(
      __map(_r => _r.body as TubChatWriteResponse)
    );
  }

  /**
   * get chat participants
   *
   * Retrieve information about all participants in a chat session, ensuring that the authenticated user
   * is present in the chat. Suitable for use on behalf of both therapists and patients, provided they
   * are themselves present in the specified chat room.
   * @param chatRoomId id of chat session for which to retrieve participants, or "patient-chat" for the patient's own active chat session
   * @return Ok
   */
  ChatsControllerV2GetChatParticipantsResponse(chatRoomId: string): __Observable<__StrictHttpResponse<Array<TubChatUserPresenceInfo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/v2/chats/${encodeURIComponent(String(chatRoomId))}/participants`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubChatUserPresenceInfo>>;
      })
    );
  }
  /**
   * get chat participants
   *
   * Retrieve information about all participants in a chat session, ensuring that the authenticated user
   * is present in the chat. Suitable for use on behalf of both therapists and patients, provided they
   * are themselves present in the specified chat room.
   * @param chatRoomId id of chat session for which to retrieve participants, or "patient-chat" for the patient's own active chat session
   * @return Ok
   */
  ChatsControllerV2GetChatParticipants(chatRoomId: string): __Observable<Array<TubChatUserPresenceInfo>> {
    return this.ChatsControllerV2GetChatParticipantsResponse(chatRoomId).pipe(
      __map(_r => _r.body as Array<TubChatUserPresenceInfo>)
    );
  }

  /**
   * mark messages as read
   *
   * Mark all messages as read on the given chat session as the authenticated user
   * @param chatRoomId id of chat session for which to mark all messages as read, or "patient-chat" for the patient's own active chat session
   */
  ChatsControllerV2MarkAsReadResponse(chatRoomId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/v2/chats/${encodeURIComponent(String(chatRoomId))}/action/mark-as-read`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * mark messages as read
   *
   * Mark all messages as read on the given chat session as the authenticated user
   * @param chatRoomId id of chat session for which to mark all messages as read, or "patient-chat" for the patient's own active chat session
   */
  ChatsControllerV2MarkAsRead(chatRoomId: string): __Observable<null> {
    return this.ChatsControllerV2MarkAsReadResponse(chatRoomId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Mark the authenticated user as typing or not typing in the given chat session.
   * @param params The `ChatsV2Service.ChatsControllerV2MarkAsTypingParams` containing the following parameters:
   *
   * - `isTyping`: true if the user is typing, false if the user is not typing
   *
   * - `chatRoomId`: id of chat session for which to mark the user as typing or not typing, or "patient-chat" for the patient's own active chat session
   */
  ChatsControllerV2MarkAsTypingResponse(params: ChatsV2Service.ChatsControllerV2MarkAsTypingParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.isTyping != null) __params = __params.set('isTyping', params.isTyping.toString());

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/v2/chats/${encodeURIComponent(String(params.chatRoomId))}/action/mark-as-typing`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Mark the authenticated user as typing or not typing in the given chat session.
   * @param params The `ChatsV2Service.ChatsControllerV2MarkAsTypingParams` containing the following parameters:
   *
   * - `isTyping`: true if the user is typing, false if the user is not typing
   *
   * - `chatRoomId`: id of chat session for which to mark the user as typing or not typing, or "patient-chat" for the patient's own active chat session
   */
  ChatsControllerV2MarkAsTyping(params: ChatsV2Service.ChatsControllerV2MarkAsTypingParams): __Observable<null> {
    return this.ChatsControllerV2MarkAsTypingResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Mark the authenticated user as online, or offline, accordingly.  Presence status is global across all chat sessions.
   * @param isOnline true if the user is online, false if the user is offline
   */
  ChatsControllerV2MarkAsOnlineResponse(isOnline: boolean): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (isOnline != null) __params = __params.set('isOnline', isOnline.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/v2/chats/action/mark-as-online`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Mark the authenticated user as online, or offline, accordingly.  Presence status is global across all chat sessions.
   * @param isOnline true if the user is online, false if the user is offline
   */
  ChatsControllerV2MarkAsOnline(isOnline: boolean): __Observable<null> {
    return this.ChatsControllerV2MarkAsOnlineResponse(isOnline).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ChatsV2Service {

  /**
   * Parameters for ChatsControllerV2SendChatMessage
   */
  export interface ChatsControllerV2SendChatMessageParams {

    /**
     * request data, including text body of message to send, and reply parameters
     */
    messageParameters: TubChatNewMessageParameters;

    /**
     * id of chat session for which to send message, or "patient-chat" for the patient's own active chat session
     */
    chatRoomId: string;
  }

  /**
   * Parameters for ChatsControllerV2EditChatMessage
   */
  export interface ChatsControllerV2EditChatMessageParams {

    /**
     * the edit payload, which contains a string to replace the existing message string
     */
    messageParameters: TubChatEditMessageParameters;

    /**
     * id of individual message event to edit
     */
    messageId: string;

    /**
     * id of chat session for which to edit message, or "patient-chat" for the patient's own active chat session
     */
    chatRoomId: string;
  }

  /**
   * Parameters for ChatsControllerV2DeleteChatMessage
   */
  export interface ChatsControllerV2DeleteChatMessageParams {

    /**
     * id of individual message event to delete
     */
    messageId: string;

    /**
     * the client's reported time of the delete event
     */
    clientTs: number;

    /**
     * id of chat session for which to delete message, or "patient-chat" for the patient's own active chat session
     */
    chatRoomId: string;
  }

  /**
   * Parameters for ChatsControllerV2MarkAsTyping
   */
  export interface ChatsControllerV2MarkAsTypingParams {

    /**
     * true if the user is typing, false if the user is not typing
     */
    isTyping: boolean;

    /**
     * id of chat session for which to mark the user as typing or not typing, or "patient-chat" for the patient's own active chat session
     */
    chatRoomId: string;
  }
}

export { ChatsV2Service }
