import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { InjectableEnvironmentService } from './injectable-environment-service';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService extends InjectableEnvironmentService {

  constructor(http: HttpClient) {
    super(http);
  }

  getEnvironment(): any {
    return environment;
  }

  /**
   * Attempts to determine if the user is in Australia via the environment file. The projectId is used to determine this as it seemed
   * the most reliable parameter to test.
   */
  public isAustralia(): boolean {
    return environment.firebase.projectId === 'thrive-unified-backend-aus';
  }
}
