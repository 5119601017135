
export class ChatLastReadModel {
  channel: string;
  Time: BigInt;
  userID: string;
}

import { TubChatUserPresenceInfo } from '@backend-client/models/tub-chat-user-presence-info';
import { ChatMessageModel, ChatChannelModel, ChatActiveChannelModel, ChatSessionModel, ChatUserModel } from '.';

export class ChatClient {

    public channelToActivate: string;
    public loading: boolean = false;
    public channels: Array<ChatChannelModel> = [];
    public messages: Array<ChatMessageModel> = [];
    public activeUsers: Array<ChatActiveChannelModel> = [];
    public channelUsers: Array<TubChatUserPresenceInfo> = [];
    public activeChannel: string;
    public sessions: Array<ChatSessionModel> = [];
    public opponentsActivity: Map<string, Array<ChatLastReadModel>> = new Map<string, Array<ChatLastReadModel>>();
    public typingNow: Array<ChatUserModel> = [];
}
