import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationContainerComponent } from '../authentication-container/authentication-container.component';
import { AuthenticationService } from '../authentication.service';
import { Location } from '@angular/common';
import { combineLatest, timer } from 'rxjs';

/**
 * Component that immediately executes log out logic, then redirects the user to the log in page.
 */
@Component({
  selector: 'app-log-out',
  templateUrl: './log-out.component.html',
  styleUrls: [ './log-out.component.scss' ]
})
export class LogOutComponent implements OnInit {

  public errored = false;
  public isLoading = false;

  @ViewChild(AuthenticationContainerComponent, { static: true }) container: AuthenticationContainerComponent;

  constructor(private readonly authenticationService: AuthenticationService, private readonly location: Location) {}

  ngOnInit(): void {
    this.logOut();
  }

  public goBack(): void {
    this.isLoading = true;
    this.location.back();
  }

  public async logOut(): Promise<void> {
    try {
      this.errored = false;
      this.isLoading = this.container.isLoading = true;

      this.container.updateHeader({
        header: 'Logging Out',
        subHeader: 'Please wait while you are securely signed out. You will be automatically redirected to the login page'
      });

      // Minimum delay of two seconds.
      await combineLatest(
        timer(2000),
        this.authenticationService.logOut()
      ).toPromise();

      location.href = '/home';
    } catch (err) {
      this.errored = true;

      this.container.updateHeader({
        header: 'Logging Out',
        subHeader: 'We failed to securely sign you out. You can try again or go back.'
      });
    } finally {
      this.isLoading = this.container.isLoading = false;
    }
  }
}
