// go-chat-duplication
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-go-avatar',
  templateUrl: './go-avatar.component.html',
  styleUrls: ['./go-avatar.component.scss']
})
export class GoAvatarComponent  {

  @Input() patientId: string;
  @Input() diameter: string;
}
