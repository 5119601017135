import { PropertyLocalConfig } from '@thrivesoft/gocore-web';

export class PropertyConfigModel {
    constructor(
        public config: string,
    ) { }

    static fromProto(proto: PropertyLocalConfig) {
        return new PropertyConfigModel(
            proto.localConfig
        );
    }

    static toProto(): PropertyLocalConfig {
        return new PropertyLocalConfig(
            // this.config
        );
    }
}