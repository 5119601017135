// go-chat-duplication
import { Directive, Host, Input, OnInit } from '@angular/core';

import { GoPrimaryNotificationComponent } from '../go-primary-notification/go-primary-notification.component';
import { GoDatabaseNotificationType } from '@shared/services/go-notifications';
import { GoDashboardNotification } from './go-dashboard-notification';

@Directive({
  selector: '[appGoChatUpgradeNotification]'
})
export class GoChatUpgradeNotificationDirective implements OnInit {

  @Input() notificationData: GoDatabaseNotificationType;

  constructor(@Host() private primaryNotificationComponent: GoPrimaryNotificationComponent) { }

  ngOnInit() {
    const { firstName, lastName, email, chatRoomId } = this.notificationData.data;
    const patientName = `${firstName !== undefined ? firstName : ''} ${lastName !== undefined ? lastName : ''}`;

    let icon: string;
    const link = `/therapist/go-chat/${chatRoomId}`;
    let title: string;
    let message: string;
    const timestamp = this.notificationData.ts;
    const readAtTime = this.notificationData.read;
    const id = this.notificationData.id;

    switch (this.notificationData.data.requestType) {
      case 'chat-v1-upgraded': {
        icon ='mark_unread_chat_alt';
        title = 'Chat has been upgraded';
        message = `Chat for ${firstName && lastName !== undefined ? patientName + ' (' + email + ')' : email} has been upgraded to V2.`;
      }
    }

    this.primaryNotificationComponent.notification = new GoDashboardNotification(icon, link, title, message, timestamp, readAtTime, id);
  }
}
