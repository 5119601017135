import { Component, Input, ViewEncapsulation } from '@angular/core';

import { DashboardNotification } from '../notifications/dashboard-notification';
import { NotificationStateService } from '@shared/services/notifications';

@Component({
  selector: 'app-primary-notification',
  templateUrl: './primary-notification.component.html',
  styleUrls: [ './primary-notification.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class PrimaryNotificationComponent {

  @Input() notification: DashboardNotification;

  constructor(private notificationStateService: NotificationStateService) {}

  public async notificationClicked(): Promise<void> {
    if (this.notification.readAtTime == null) {
      await this.notificationStateService.markChatRequestNotificationsAsRead(this.notification.id);
    }
  }

}
