import { PropertyAuthorisation } from '@thrivesoft/gocore-web';

export class AuthLoginActionModel {

  static actionLogin: string = 'ACTION_LOGIN';

  static toProto(sessionCookie: string): PropertyAuthorisation {
    return new PropertyAuthorisation({
      message: AuthLoginActionModel.actionLogin,
      sessionCookie
    });
  }
}