/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubSendChatMessageResponse } from '../models/tub-send-chat-message-response';
import { PubNubSendChatMessageRequest } from '../models/pub-nub-send-chat-message-request';
import { TubChatUserPresenceInfo } from '../models/tub-chat-user-presence-info';
@Injectable({
  providedIn: 'root',
})
class ChatsV2Service extends __BaseService {
  static readonly ChatsControllerV2SendChatMessagePath = '/v2/chats/{chatSessionId}/messages';
  static readonly ChatsControllerV2GetChatParticipantsPath = '/v2/chats/{chatSessionId}/participants';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * send message into chat room
   *
   * Send the given message into the chat session specified.
   * @param params The `ChatsV2Service.ChatsControllerV2SendChatMessageParams` containing the following parameters:
   *
   * - `messageParameters`: request data, including text body of message to send, and reply parameters
   *
   * - `chatSessionId`: id of chat session into which to send message, or "patient-chat" for the patient's own chat
   *
   * @return Ok
   */
  ChatsControllerV2SendChatMessageResponse(params: ChatsV2Service.ChatsControllerV2SendChatMessageParams): __Observable<__StrictHttpResponse<TubSendChatMessageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.messageParameters;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/v2/chats/${encodeURIComponent(String(params.chatSessionId))}/messages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubSendChatMessageResponse>;
      })
    );
  }
  /**
   * send message into chat room
   *
   * Send the given message into the chat session specified.
   * @param params The `ChatsV2Service.ChatsControllerV2SendChatMessageParams` containing the following parameters:
   *
   * - `messageParameters`: request data, including text body of message to send, and reply parameters
   *
   * - `chatSessionId`: id of chat session into which to send message, or "patient-chat" for the patient's own chat
   *
   * @return Ok
   */
  ChatsControllerV2SendChatMessage(params: ChatsV2Service.ChatsControllerV2SendChatMessageParams): __Observable<TubSendChatMessageResponse> {
    return this.ChatsControllerV2SendChatMessageResponse(params).pipe(
      __map(_r => _r.body as TubSendChatMessageResponse)
    );
  }

  /**
   * get chat participants
   *
   * Retrieve information about all participants in a chat session, ensuring that the authenticated user
   * is present in the chat. Suitable for use on behalf of both therapists and patients, provided they
   * are themselves present in the specified chat room.
   * @param chatSessionId id of chat session into which to send message, or "patient-chat" for the patient's own chat
   * @return Ok
   */
  ChatsControllerV2GetChatParticipantsResponse(chatSessionId: string): __Observable<__StrictHttpResponse<Array<TubChatUserPresenceInfo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/v2/chats/${encodeURIComponent(String(chatSessionId))}/participants`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubChatUserPresenceInfo>>;
      })
    );
  }
  /**
   * get chat participants
   *
   * Retrieve information about all participants in a chat session, ensuring that the authenticated user
   * is present in the chat. Suitable for use on behalf of both therapists and patients, provided they
   * are themselves present in the specified chat room.
   * @param chatSessionId id of chat session into which to send message, or "patient-chat" for the patient's own chat
   * @return Ok
   */
  ChatsControllerV2GetChatParticipants(chatSessionId: string): __Observable<Array<TubChatUserPresenceInfo>> {
    return this.ChatsControllerV2GetChatParticipantsResponse(chatSessionId).pipe(
      __map(_r => _r.body as Array<TubChatUserPresenceInfo>)
    );
  }
}

module ChatsV2Service {

  /**
   * Parameters for ChatsControllerV2SendChatMessage
   */
  export interface ChatsControllerV2SendChatMessageParams {

    /**
     * request data, including text body of message to send, and reply parameters
     */
    messageParameters: PubNubSendChatMessageRequest;

    /**
     * id of chat session into which to send message, or "patient-chat" for the patient's own chat
     */
    chatSessionId: string;
  }
}

export { ChatsV2Service }
