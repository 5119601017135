import { Injectable } from '@angular/core';

import { BehaviorSubject, take } from 'rxjs';

import { AuthenticationService } from '@app/modules/authentication/authentication.service';
import { GoogleDriveFileMoment } from '@app/modules/therapist/assigned-patients/patient-profile/patient-timeline/moment/google-drive-file-moment/google-drive-file-moment';
import { SwaggerSecurityScopes } from '@backend-client/swagger-security-scopes';
import { MessageService } from '../message.service';
import { EnvironmentService } from '../environment.service';
import { GoogleDriveApiError } from '../../models/google-drive-api-error.model';

@Injectable({
  providedIn: 'root'
})

export class GoogleDriveDownloadService {

  public DOWNLOAD_SCOPE = this.environmentService.getEnvironment().google.downloadScope;
  public showDownloadButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _driveFileMoment: GoogleDriveFileMoment;

  public set driveFileMoment(moment: GoogleDriveFileMoment) {
    this._driveFileMoment = moment;
  }

  constructor(
    private environmentService: EnvironmentService,
    private authenticationService: AuthenticationService,
    private messageService: MessageService
  ) {
    this.authenticationService.isScopePresent$('patient:download-moments' as SwaggerSecurityScopes)
      .pipe(take(1))
      .subscribe(scopeIsPresent => {
        this.showDownloadButton.next(scopeIsPresent);
      });
  }

  public checkScopeThenDownloadFile(): void {
    this.authenticationService.isScopePresent$('patient:download-moments' as SwaggerSecurityScopes)
      .pipe(take(1))
      .subscribe(scopeIsPresent => {
        if (scopeIsPresent) {
          this.downloadFile(this._driveFileMoment.googleDriveFileId);
        } else {
          this.messageService.showMessage('Error: Unauthorised to download file');
        }
      });
  }

  private async downloadFile(fileId: string): Promise<void> {
    await gapi.client.drive.files.get({
      fileId: fileId,
      fields: 'webContentLink',
      supportsAllDrives: true,
      supportsTeamDrives: true,
    }).then((file: gapi.client.Response<gapi.client.drive.File>) => {
        window.open(file.result.webContentLink);
    }).catch((error: GoogleDriveApiError) => {
      // 404 Errors thrown can also be due to lack of permission on the Google Drive
      this.messageService.showMessage(`${error.result.error.code}: ${error.result.error.message}`);
      console.error(error);
    });
  }
}
