import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FeedbackFormService {

  async postFeedback(feedback: string): Promise<void> {
    // TODO: End point - start
    console.log(feedback);
    // TODO: End point - end
  }
}
