import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { CustomErrorStateMatcher } from '@app/modules/shared/utils/custom-error-state-matcher';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  @Output() forgotPassword = new EventEmitter<string>();
  @Output() backToStart = new EventEmitter();
  public forgotPasswordFormGroup: UntypedFormGroup;
  public errorMatcher = new CustomErrorStateMatcher();

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.forgotPasswordFormGroup = this.formBuilder.group({
      forgotPasswordFormControl: [ '', [ Validators.required, Validators.email ] ]
    });
  }

  public back(): void {
    this.forgotPasswordFormGroup.reset();
    this.backToStart.emit();
  }
}
