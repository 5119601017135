<div *ngIf="messageNotificationButtonService.isCleaningCache" id="spinner-container" fxLayout="column" fxLayoutAlign="center">
  <mat-spinner diameter="20">

  </mat-spinner>
</div>

<ng-container *ngIf="!messageNotificationButtonService.isCleaningCache">
  <button mat-icon-button [matMenuTriggerFor]="messageNotificationMenu">
    <mat-icon
      [matBadgeHidden]="(messageNotificationButtonService.totalChatNotifications | async) < 1"
      [matBadge]="(messageNotificationButtonService.totalChatNotifications | async).toString()"
      matBadgeColor="warn">
      email
    </mat-icon>
  </button>
  <mat-menu #messageNotificationMenu="matMenu">
    <a *ngIf="(messageNotificationButtonService.totalChatNotifications | async) < 1" disabled mat-menu-item>
      You have no new messages
    </a>
    <ng-container *ngFor="let notification of (notifications)">
      <a *ngIf="notification.messages.unread > 0" routerLink="/therapist/chat/{{notification.chatSessionId}}"
         mat-menu-item>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-spinner *ngIf="!notification.patient.email"  diameter="25"></mat-spinner>
          <app-avatar *ngIf="notification.patient.email"
            [matBadge]="notification.messages.unread.toString()"
            matBadgeColor="warn"
            matBadgePosition="before"
            diameter="25px">
          </app-avatar>
          <div id="notification-text" [ngClass]="{'message-notification-not-loaded': !notification.patient.email}">
          {{notification.patient.name || notification.patient.email || 'Patient ' + notification.chatSessionId}}
          </div>
        </div>
      </a>
    </ng-container>
  </mat-menu>
</ng-container>

