/**
 * Takes an array of strings and outputs them in a user friendly format:
 * ['foo', 'bar', 'cat] => 'foo, bar and cat'
 * @param array An array of strings to concatenate
 */
 export function userFriendlyList(list: string[]): string {
    if (list.length > 1) {
        return list.slice(0, -1).join(', ') + ' and ' + list.slice(-1);
    } else if (list.length === 1) {
        return list[0];
    } else {
        return '';
    }
}