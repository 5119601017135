import { Subject } from 'rxjs';
import { OnDestroy, Injectable } from '@angular/core';

/**
 * Exposes an ngOnDestroy$ observable which will emit when ngOnDestroy is called, for
 * convenient use alongside takeUntil for automatic observable cleanup.
 */
// TODO: Add Angular decorator.
@Injectable()
export abstract class OnDestroyObservable implements OnDestroy {
  protected ngOnDestroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }
}
