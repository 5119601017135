/**
   * Extract the hours from a 24 hour string.
   *
   * @param time Hours to parse
   */
export function parseHour(time: string): number {
    return parseInt(time.split(':')[0], 10);
}

/**
 * Extract the minutes from a 24 hour string.
 *
 * @param time Minutes to parse
 */
export function parseMinute(time: string): number {
    return parseInt(time.split(':')[1], 10);
}

export function convertStringToMilliseconds(stringTime: string): number {
    const hour = parseHour(stringTime);
    const minute = parseMinute(stringTime);
    return (hour * 60 * 60 * 1000) + (minute * 60 * 1000);
}

/**
 * Converts milliseconds into a time in the format 'HH:MM'.
 *
 * @param milliseconds The time from midnight in milliseconds
 */
export function convertMillisecondsToString(milliseconds: number): string {
    const start = milliseconds / 1000 / 60;
    let startHour: number | string = Math.floor(start / 60);
    let startMinute: number | string = Math.floor(start % 60);
    // Add '0' to the hour and minute if either value is a single digit.
    if (startHour < 10) {
        startHour = '0' + startHour;
    }
    if (startMinute < 10) {
        startMinute = '0' + startMinute;
    }
    return startHour + ':' + startMinute;
}

export function convertDateToTime(date: Date, is24Hours?: boolean) {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const minutesString = minutes < 10 ? '0' + minutes : minutes;
    if (is24Hours) {
        return `${hours}:${minutesString}`;
    } else {
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;  // If hours are zero, set to 12
        return `${hours}:${minutesString} ${ampm}`;
    }
}