import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-add-authenticator-prompt',
  templateUrl: './add-authenticator-prompt.component.html',
  styleUrls: ['./add-authenticator-prompt.component.scss']
})
export class AddAuthenticatorPromptComponent {

  @Output() shouldAddAuthenticator = new EventEmitter<boolean>();

}
